import React from 'react';

import { DataGrid, Sorting, Column, Pager, Paging, FilterRow, Lookup, Editing, SearchPanel, HeaderFilter} from 'devextreme-react/data-grid';
import Form, { SimpleItem, Label, GroupItem, RequiredRule } from 'devextreme-react/form';
import Box, { Item } from 'devextreme-react/box';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { Link, useParams } from 'react-router-dom';
import { Button } from 'devextreme-react/button';
import notify from "devextreme/ui/notify";
import { confirm } from 'devextreme/ui/dialog';
import { LoadPanel } from 'devextreme-react/load-panel';

import ArchivedTransformationsDataGridRow from './archived-transformations-grid-row.js';
import EmployerDetails from '../../components/assindatcolf/employer-details'
import EmployeeDetails from '../../components/assindatcolf/employee-details'
import EmployeeHours from '../../components/assindatcolf/employee-hours'

import * as DateConversion from '../../helper/dataConversion'
import * as Notifier from '../../helper/notifier'
import * as API from '../../api/api'
import * as Global from '../../global'

import './transformation.scss';

export class Transformation extends React.Component {
  constructor(props) {
    super(props);
    if (!localStorage.getItem(Global.LocalStorageKey.Year)) {
      localStorage.setItem(Global.LocalStorageKey.Year, JSON.stringify(new Date().getFullYear()));
    }

    this.state = {
      year: JSON.parse(localStorage.getItem(Global.LocalStorageKey.Year)),
      loggedUser: Global.GetLoggedUser(),
      urlParamEmployeeId: null,
      urlParamTransformationVersionId: null,
      buildBreadcrumbEmployeeLink: true,
      emploeeyTransformationData: null,
      transformation: null,
      loadManager: this.createLoadManager(),
      archivedTransformationsDataSource: null,
      isLoadPanelVisible: false,
      readOnly: false,
    };

    this.archivedTransformationsDataGrid = null;

    this.onSaveButtonClick = this.onSaveButtonClick.bind(this);
    this.onDeleteTransformationButtonClick = this.onDeleteTransformationButtonClick.bind(this);
    this.onCloseTransformationButtonClick = this.onCloseTransformationButtonClick.bind(this);
    this.onExportTransformationToPDFButtonClick = this.onExportTransformationToPDFButtonClick.bind(this);

    this.hideLoadPanel = this.hideLoadPanel.bind(this);
    this.showLoadPanel = this.showLoadPanel.bind(this);
  }

  componentDidMount() {    
    return;

    console.log(this.props);

    if(this.props.match.params.employeeId)
      this.setState({urlParamEmployeeId: this.props.match.params.employeeId});
    else
      this.props.history.push({pathname: '/transformations'});

    if(this.props.match.params.transformationVersionId)
      this.setState({urlParamTransformationVersionId: this.props.match.params.transformationVersionId});
    else
      this.setState({urlParamTransformationVersionId: null});

    this.setState({urlParamEmployeeId: this.props.match.params.employeeId}, () => {
      this.getEmployee();
      this.getLastTransformation();
    }); 
  }

  componentWillReceiveProps(nextProps) {
    //console.log('componentWillReceiveProps', nextProps);
    //if(nextProps.match.params.employeeId)
    //console.log('componentWillReceiveProps 2', nextProps.match.params.employeeId);
    //this.setState(nextProps);

    if(nextProps.match.params.employeeId) {
      this.setState({
        loadManager: this.createLoadManager()}, () => {
          if(nextProps.match.params.transformationVersionId) {
            //impiegato e trasformazione archiviata, carico la trasformazione archiviata
            //console.log("vediamo:", this.props);
            //console.log("vediamo 2:", this.props.location.state);
            //console.log("vediamo 2:", this.props.location.state.buildBreadcrumbEmployeeLink);
            var buildBreadcrumbEmployeeLinkProp = false;
            if(this.props && this.props.location && this.props.location.state && this.props.location.state.buildBreadcrumbEmployeeLink)
              buildBreadcrumbEmployeeLinkProp = this.props.location.state.buildBreadcrumbEmployeeLink;

            this.setState({
              urlParamEmployeeId: nextProps.match.params.employeeId}, () => {
                this.setState({
                  buildBreadcrumbEmployeeLink: buildBreadcrumbEmployeeLinkProp,
                  urlParamTransformationVersionId: nextProps.match.params.transformationVersionId}, () => {
                    this.getEmployee();       
                    this.getTransformation(false);
                  });
              });
          } else {
            //solo impiegato, carico l'ultima trasformazione aperta
            this.setState({
              urlParamEmployeeId: nextProps.match.params.employeeId}, () => {
                this.getEmployee();
                this.getTransformation(true);
              });  
          }          
        });
    }
    else
      this.props.history.push({pathname: '/transformations'});
  }

  createLoadManager() {    
    var l = {
      isEmployeeLoaded: false,
      isTransformationLoaded: false,
      isLoadingComplete : function() { 
        return (this.isEmployeeLoaded && this.isTransformationLoaded);
      }
    };

    return l;
  }

  createArchivedTransformationsDataSource(transformationId) {   
    var store = createStore({
      key: 'transformationVersionId',
      loadUrl: Global.WebApiRootUrl + '/transformations/archived/' + transformationId,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = { withCredentials: true };
        ajaxOptions.headers = { Authorization: `Bearer ${this.state.loggedUser.token}`}
      },
      onAjaxError: ({ xhr, error}) => {
        Global.CheckResponseAuth(xhr);
      }
    });

    return store;
  }

  getEmployee() {
    let emploeeyPromise = API.getEmployeeWithEmployer(this.state.loggedUser.token, this.state.urlParamEmployeeId);
    let emploeey = emploeeyPromise.then(result => {
      //console.log(result);
      //console.log(this.state.loaderManager);

      let lm = this.state.loadManager;
      lm.isEmployeeLoaded = true;

      this.setState({
        emploeeyTransformationData: result,
        loadManager: lm
      });

      //console.log(this.state.loaderManager);
      
      return result; 
    })
    .catch(error => {
      console.log("Error: ", error)
      return null;
    });
  }

  getTransformation(getLastOpenTransformation) {
    let promise = null;
    
    if (getLastOpenTransformation)
      promise = API.getEmployeeLastTransformation(this.state.loggedUser.token, this.state.urlParamEmployeeId);
    else
      promise = API.getEmployeeArchivedTransformation(this.state.loggedUser.token, this.state.urlParamEmployeeId, this.state.urlParamTransformationVersionId);
      
    let emploeey = promise.then(result => {
      //console.log("Last transformation", result);

      if(result && result.data && result.isNewRecord && result.data.month)
        result.data.month = null; //toca pelotas...
      
      var lm = this.state.loadManager;
      lm.isTransformationLoaded = true;

      this.setState({
        transformation: result,
        loadManager: lm,
        archivedTransformationsDataSource: this.createArchivedTransformationsDataSource(result.base.transformationId),
        readOnly: (this.state.urlParamTransformationVersionId != null && result.data.archived == true && result.isLastTransformationVersion == false), //readonly nei casi in cui è archiviata e non l'ultima versione, poichè si può modificare sia l'ultima aperta che l'ultima archiviata
      });
      
      return result; 
    })
    .catch(error => {
      console.log("Error: ", error)
      return null;
    });
  }

  onExportTransformationToPDFButtonClick() {
    this.showLoadPanel();

    const url =  Global.WebApiRootUrl + "/transformations/exportTransformationToPDF";

    var t = this.state.transformation;
    t.userId = this.state.loggedUser.userId;
    t.year = this.state.year;    

    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(t),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.state.loggedUser.token}`
      }
    };

    fetch(url, requestOptions)
      .then(response => {       
        if(response.ok) return response.blob();        
        Global.CheckResponseAuth(response);
      })
      .then(blob => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;

        var slug = this.state.emploeeyTransformationData.employer.lastName + '-' + this.state.emploeeyTransformationData.employer.firstName + '-' + this.state.emploeeyTransformationData.employer.fiscalCode;
        slug = slug.replace(/\s/g,'').toLowerCase();

        a.download = slug + ".pdf";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();    
        a.remove();  //afterwards we remove the element again 
        
        this.hideLoadPanel();
      })
      .catch(error => { this.hideLoadPanel(); notify(error, 'error', 5000); });
  }

  onDeleteTransformationButtonClick() {
    let result = confirm("Confermi l'eliminazione della trasformazione (e di tutte le relative trasformazioni archiviate)?", "Confirma eliminazione");
    result.then((dialogResult) => {
      if (dialogResult) {
        this.showLoadPanel();

        var url =  Global.WebApiRootUrl + "/transformations/delete";

        var t = this.state.transformation;
        t.userId = this.state.loggedUser.userId;

        const requestOptions = {
          method: 'DELETE',
          body: JSON.stringify(t),
          headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${this.state.loggedUser.token}`
          }
        };
    
        return fetch(url, requestOptions)
          .then(response => {
            if(response.ok) return response.json();
            Global.CheckResponseAuth(response);
            return null;
          })
          .then(data => {
            this.hideLoadPanel();           
            Notifier.SetGlobalToastMessage(data.message, (data.success ? 'success' : 'error'));              
            this.props.history.push({pathname: '/transformations'});
          })
          .catch(error => { this.hideLoadPanel(); notify(error, 'error', 5000); }); 
        }  
    });
    return null;
  }

  onCloseTransformationButtonClick() {
    let result = confirm("Confermi la chiusura della trasformazione?", "Confirma chiusura");
    result.then((dialogResult) => {
      if (dialogResult) {
        this.showLoadPanel();

        var url =  Global.WebApiRootUrl + "/transformations/close";

        var t = this.state.transformation;
        t.userId = this.state.loggedUser.userId;

        const requestOptions = {
          method: 'POST',
          body: JSON.stringify(t),
          headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${this.state.loggedUser.token}`
          }
        };
    
        return fetch(url, requestOptions)
          .then(response => {
            if(response.ok) return response.json();
            Global.CheckResponseAuth(response);
            return null;
          })
          .then(data => {        
            this.hideLoadPanel();      
            Notifier.SetGlobalToastMessage(data.message, (data.success ? 'success' : 'error'));              
            this.props.history.push({pathname: '/transformations'});
          })
          .catch(error => { this.hideLoadPanel(); notify(error, 'error', 5000); }); 
        }  
    });
    return null;
  }

  validate() {
    if(!this.state.transformation.data.takingChargeDate || !this.state.transformation.data.month)
      return {
        isValid: false,
        message: 'Data di presa in carico e Mese trasformazione sono obbligatori',
        notifyType: 'error'
      }
      
    return {
      isValid: true,
      message: 'OK',
      notifyType: 'success'
    }     
  }

  onSaveButtonClick(e) {
    var valid = this.validate();
    if(valid.isValid) {
      this.showLoadPanel();

      var url =  Global.WebApiRootUrl + "/transformations/createOrUpdate";

      var t = this.state.transformation;
      t.userId = this.state.loggedUser.userId;

      const requestOptions = {
        method: 'POST',
        body: JSON.stringify(t),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.state.loggedUser.token}`
        }
      };
  
      return fetch(url, requestOptions)
        .then(response => {
            if(response.ok) return response.json();
            Global.CheckResponseAuth(response);
            return null;
        })
        .then(data => {
            //console.log(data); 
            this.hideLoadPanel();

            notify(data.message, data.success ? 'success' : 'error', 5000);
      
            if(data.success) {
              if(data.data.data.archived) {
                //salvataggio di una trasformazione già chiusa e archiviata, devo navigare nella nuova trasformazione arcihivata
                this.props.history.push({pathname: '/transformations/' + data.data.base.employeeId.toLowerCase() + /archived/ + data.data.data.transformationVersionId.toLowerCase()});
              } else {
                //salvataggio di una trasformazione aperta, rimango nella stessa vista ricaricando la trasformazione con quella passata
                this.setState({
                  transformation: data.data,
                  archivedTransformationsDataSource: this.createArchivedTransformationsDataSource(data.data.base.transformationId)
                });
              }
            } 
        })
        .catch(error => { this.hideLoadPanel(); notify(error, 'error', 5000); });
    } else {      
      notify(valid.message, valid.notifyType, 5000);
    }

    return null;
  }

  showLoadPanel() {
    this.setState({
      isLoadPanelVisible: true
    });
  }

  hideLoadPanel() {
    this.setState({
      isLoadPanelVisible: false
    });
  }

  onFormSubmit(e) {
    if(e && e.onSaveButtonClick)
      e.onSaveButtonClick();
    else
      alert("Si è verificato un errore, non è possibile procedere.")    
  }

  renderView() {
    if(!this.state.loadManager.isLoadingComplete())
      return this.renderLoader();
    else
      return this.renderContent();
  }

  renderLoader() {
    return (
      <React.Fragment>
        <div className={'content-block ac-page-title'}><Link to="/transformations">Trasformazioni</Link> &gt; ... </div>
        <div className={'content-block'}>
          <div className={'dx-card responsive-paddings'}>
            <div className={'ac-centered'}><LoadIndicator id="large-indicator" height={60} width={60} /></div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderTransformationState(state) {    
    if (state == 0) { 
      //nuova trasformazione
      return (<div className={'ac-container-new-item'}>Nuova</div>);
    } else if(state == 1) { 
      //trasformazione archiviata, chiusa o aperta
      return (<div className={'ac-container-archived-item'}>Archiviata</div>);
    } else if (state == 9) {
      //trasformazione aperta
      return (<div className={'ac-container-opened-item'}>Aperta</div>);
    } else if (state == 7) {
      //trasformazione sospesa
      return (<div className={'ac-container-suspended-item'}>Sospesa</div>);
    } else if (state == 5) {
      //trasformazione chiusa ma trasformazione a tempo indeterminato che può essere uno stato chiuso per le altre entità
      return (<div className={'ac-container-closedIndefinitely-item'}>Chiusa</div>);
    } else if (state == 3) {
      //trasformazione chiusa
      return (<div className={'ac-container-closed-item'}>Chiusa</div>);
    }
    
    //se arrivo qui c'è qualche problema con la valutazione dello stato della trasformazione 
    return (<div className={'ac-container-new-item'}>indefinita</div>);
  }

  renderBreadcrumb() {
    if(!this.state.urlParamTransformationVersionId) {
      //path if: current transformation - else: archived transformation
      return (
        <div>
          <div>
            <Link to="/transformations">Trasformazioni</Link> &gt; {this.state.emploeeyTransformationData.lastName} {this.state.emploeeyTransformationData.firstName} ({this.state.emploeeyTransformationData.employer.lastName} {this.state.emploeeyTransformationData.employer.firstName})
          </div>
          <div className={'ac-margin-top-05'}>
            <div className={'ac-float-letf'}>{this.renderTransformationState(this.state.transformation.state)}</div>
            <div className={'ac-float-right'}>              
              {
                this.state.transformation.isNewRecord == false 
                  ?                 
                    <span> 
                      <Button width={120} text="Chiudi" type="default" stylingMode="outlined" onClick={this.onCloseTransformationButtonClick} />&nbsp;
                      <Button width={120} text="Elimina" type="default" stylingMode="outlined" onClick={this.onDeleteTransformationButtonClick} />&nbsp;
                      <Button width={130} text="Genera PDF" type="default" stylingMode="outlined" onClick={this.onExportTransformationToPDFButtonClick} />&nbsp;
                    </span> 
                  :
                    <span></span>
              }
              <Button className="ac-button-default" width={120} text="Salva" type="default" stylingMode="contained" useSubmitBehavior={true} onClick={this.onSaveButtonClick} />
            </div>
            <div className={'ac-clear-both'}></div>
          </div>
        </div>);
    } else {
      var returnPathName = '/transformations/' + this.state.emploeeyTransformationData.employeeId.toLowerCase();
      if(this.state.transformation.base.closedAt)
        returnPathName = '/transformations/' + this.state.emploeeyTransformationData.employeeId.toLowerCase() + '/archived/' + this.state.transformation.lastClosedTransformationVersionId.toLowerCase()

      return (
        <div>
          <div>
            <span><Link to="/transformations">Trasformazioni</Link> &gt; </span>
            <Link to={{ pathname: returnPathName, state: { archivedReload: false } }} >
              <span> {this.state.emploeeyTransformationData.lastName} {this.state.emploeeyTransformationData.firstName}</span>
              <span> ({this.state.emploeeyTransformationData.employer.lastName} {this.state.emploeeyTransformationData.employer.firstName})</span>
            </Link>
            <span> &gt; {DateConversion.GetDateFromISOString(this.state.transformation.data.archivedAt, false)}</span>
            <span> ({this.state.transformation.archivedByByUserFullName})</span>
          </div>
          <div>
            <div className={'ac-float-letf'}>{this.renderTransformationState(this.state.transformation.state)}</div>
            <div className={'ac-float-right'}>              
              <Button width={120} text="Elimina" type="default" stylingMode="outlined" onClick={this.onDeleteTransformationButtonClick} />&nbsp;
              <Button width={130} text="Genera PDF" type="default" stylingMode="outlined" onClick={this.onExportTransformationToPDFButtonClick} />&nbsp;
              {this.state.transformation.isLastTransformationVersion == true 
                ?
                  <span><Button className="ac-button-default" width={120} text="Salva" type="default" stylingMode="contained" useSubmitBehavior={true} onClick={this.onSaveButtonClick} /></span>
                : 
                  <span><Button className="ac-button-default" width={120} text="Salva" type="default" stylingMode="contained" useSubmitBehavior={true} disabled={true} /></span>
              }
            </div>
            <div className={'ac-clear-both'}></div>
          </div>
        </div>);
    }
  }

  renderContent() {
    return (
      <React.Fragment>
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          visible={this.state.isLoadPanelVisible}
          showIndicator={true}
          shading={true}
          showPane={true}
          closeOnOutsideClick={false}
        />

        <div className={'content-block ac-page-title'}>
          {this.renderBreadcrumb()}
        </div>
     
        <div className={'ac-margin-top-10 content-block'}>
            <Box
              direction="row"
              width="100%">
              <Item ratio={3}>
                <div className={'dx-card responsive-paddings'}>
                  <div className="ac-card-title">Anagrafiche datore/dipendente</div>
                  <div className={'ac-margin-top-05'}> 
                    <EmployerDetails title="Datore di lavoro" employerId={this.state.emploeeyTransformationData.employerId} lightView={true} />
                  </div>
                  <div className={'ac-margin-top-20'}> 
                    <EmployeeDetails title="Dipendente" employeeId={this.state.emploeeyTransformationData.employeeId} lightView={true} />
                  </div>
                </div>
              </Item>
              <Item ratio={2}>
                <div className={'dx-card responsive-paddings ac-margin-left-10'}>
                <div className="ac-card-title">Storico trasformazioni</div>
                  <div id="tramsformation-archived-grid">
                    <DataGrid 
                      id="gridContainer"
                      ref={(ref) => this.archivedTransformationsDataGrid = ref}
                      className={'dx-card wide-card'} 
                      dataSource={this.state.archivedTransformationsDataSource} 
                      remoteOperations={true}
                      showBorders={true} 
                      focusedRowEnabled={false} 
                      defaultFocusedRowIndex={0} 
                      columnAutoWidth={true} 
                      columnHidingEnabled={true}    
                      height={239}       
                      rowRender={ArchivedTransformationsDataGridRow}
                    >
                      <Paging defaultPageSize={4} />
                      <Pager showPageSizeSelector={false} showInfo={true} />            
                      <FilterRow visible={false} />
                      <HeaderFilter visible={false} />
                      <Editing mode="popup" allowUpdating={false} allowDeleting={false} allowAdding={false} />
                      <Sorting mode="multiple" />                                
                      
                      <Column dataField={'employeeId'} hidingPriority={1} visible={false} />
                      <Column dataField={'transformationId'} hidingPriority={1} visible={false} />
                      <Column dataField={'transformationVersionId'} hidingPriority={1} visible={false} />
                      
                      <Column dataField={'archivedAt'} caption={'Data archiviazione'} dataType={'date'} format={'dd/MM/yyyy hh:mm:ss'} hidingPriority={4} defaultSortOrder="desc" />
                      <Column dataField={'archivedByUserFullName'} caption={'Archiviato da'} hidingPriority={3} />
                    </DataGrid>
                  </div> 
                </div>
              </Item>
            </Box>
          <div className={'dx-card responsive-paddings ac-margin-top-10'}>
            <EmployeeHours title={'Vecchio orario di lavoro (situazione aggiornata)'} loggedUser={this.state.loggedUser} extraTitleText={""} extraTitleNotes={""} employeeId={this.state.emploeeyTransformationData.employeeId} year={this.state.year} original="0" />
          </div>

          <div className={'tramsformation-form-container dx-card responsive-paddings'}>
            <form action="#" className={'report-filter-form'} onSubmit={ e => {e.preventDefault(); this.onFormSubmit(this);}}>
              <Form colCount={3} id="form2" formData={this.state.transformation.data} >
                
                <GroupItem colSpan={3} colCount={1} >
                  <SimpleItem dataField="newWorkingTime" editorType="dxTextArea" editorOptions={{maxLength: 256, readOnly: this.state.readOnly}} >
                      <Label text="Nuovo orario di lavoro" />
                    </SimpleItem>
                </GroupItem>
                
                <GroupItem colSpan={1} colCount={1} >
                  <SimpleItem dataField="takingChargeDate" editorType="dxDateBox" editorOptions={{maxLength: 25, readOnly: this.state.readOnly}} >
                    <Label text="Data presa in carico" />
                    <RequiredRule message="Data presa in carico è obbligatoria" />
                  </SimpleItem>
                </GroupItem>
                <GroupItem colSpan={1} colCount={1} >
                  <SimpleItem dataField="month" editorType="dxDateBox" editorOptions={{maxLength: 25, displayFormat: 'monthAndYear', format: "date",  maxZoomLevel: 'year', minZoomLevel: 'century', readOnly: this.state.readOnly}} >
                    <Label text="Mese trasformazione" />
                    <RequiredRule message="Mese trasformazione è obbligatorio" />
                  </SimpleItem>
                </GroupItem>
                <GroupItem colSpan={1} colCount={1} >
                  <SimpleItem dataField="suspended" editorType="dxCheckBox" editorOptions={{readOnly: this.state.readOnly}} >
                    <Label text="Sospesa" />
                  </SimpleItem>
                </GroupItem>

                <GroupItem colSpan={1} colCount={1} >
                  <SimpleItem dataField="breakTime" editorType="dxTextBox" editorOptions={{maxLength: 25, readOnly: this.state.readOnly}} >
                      <Label text="Pasto/pausa" />
                    </SimpleItem>
                </GroupItem>
                <GroupItem colSpan={1} colCount={1} >
                  <SimpleItem dataField="level" editorType="dxTextBox" editorOptions={{maxLength: 25, readOnly: this.state.readOnly}} >
                      <Label text="Livello" />
                    </SimpleItem>
                </GroupItem>
                <GroupItem colSpan={1} colCount={1} >
                  <SimpleItem dataField="partTime" editorType="dxTextBox" editorOptions={{maxLength: 25, readOnly: this.state.readOnly}} >
                      <Label text="Se convivente parttime matt o pom" />
                    </SimpleItem>
                </GroupItem>

                <GroupItem colSpan={3} colCount={1} >
                  <SimpleItem dataField="inCharge" editorType="dxTextBox" editorOptions={{maxLength: 50, readOnly: this.state.readOnly}} >
                      <Label text="Commissariato" />
                    </SimpleItem>
                </GroupItem>

                <GroupItem colSpan={3} colCount={2} >
                  <GroupItem colSpan={3} colCount={3} caption="Dati fabbricato" >                  
                    <SimpleItem dataField="building.floor" editorType="dxTextBox" editorOptions={{maxLength: 25, readOnly: this.state.readOnly}} >
                      <Label text="Piano" />
                    </SimpleItem>
                    <SimpleItem dataField="building.unit" editorType="dxTextBox" editorOptions={{maxLength: 25, readOnly: this.state.readOnly}} >
                      <Label text="Scala" />
                    </SimpleItem>
                    <SimpleItem dataField="building.room" editorType="dxTextBox" editorOptions={{maxLength: 25, readOnly: this.state.readOnly}} >
                      <Label text="Interno" />
                    </SimpleItem>
                    <SimpleItem dataField="building.compartments" editorType="dxTextBox" editorOptions={{maxLength: 25, readOnly: this.state.readOnly}} >
                      <Label text="Vani" />
                    </SimpleItem>
                    <SimpleItem dataField="building.accessories" editorType="dxTextBox" editorOptions={{maxLength: 25, readOnly: this.state.readOnly}} >
                      <Label text="Accessori" />
                    </SimpleItem>
                    <SimpleItem dataField="building.entrances" editorType="dxTextBox" editorOptions={{maxLength: 25, readOnly: this.state.readOnly}} >
                      <Label text="Ingressi" />
                    </SimpleItem>
                  </GroupItem>

                  <GroupItem colSpan={3} colCount={3} caption="Retribuzione" >                  
                    <SimpleItem dataField="salary.total" editorType="dxTextBox" editorOptions={{maxLength: 25, readOnly: this.state.readOnly}} >
                      <Label text="Totale" />
                    </SimpleItem>
                    <SimpleItem dataField="salary.minimun" editorType="dxTextBox" editorOptions={{maxLength: 25, readOnly: this.state.readOnly}} >
                      <Label text="Minimo" />
                    </SimpleItem>
                    <SimpleItem dataField="salary.steps" editorType="dxTextBox" editorOptions={{maxLength: 25, readOnly: this.state.readOnly}} >
                      <Label text="Scatti" />
                    </SimpleItem>
                    <SimpleItem dataField="salary.superMinAssorb" editorType="dxTextBox" editorOptions={{maxLength: 25, readOnly: this.state.readOnly}} >
                      <Label text="Supermin assorb" />
                    </SimpleItem>
                    <SimpleItem dataField="salary.superMinNotAssorb" editorType="dxTextBox" editorOptions={{maxLength: 25, readOnly: this.state.readOnly}} >
                      <Label text="Supermin non assorb" />
                    </SimpleItem>
                    <SimpleItem dataField="salary.allowance" editorType="dxTextBox" editorOptions={{maxLength: 25, readOnly: this.state.readOnly}} >
                      <Label text="Indennità" />
                    </SimpleItem>
                  </GroupItem>
                </GroupItem>

                <GroupItem colSpan={3} colCount={2} >
                  <SimpleItem dataField="childAgeLeveBs" editorType="dxTextBox" editorOptions={{maxLength: 25, readOnly: this.state.readOnly}} >
                    <Label text="Età bambino livello BS" />
                  </SimpleItem>
                  <SimpleItem dataField="assisNumberLevelCdds" editorType="dxTextBox" editorOptions={{maxLength: 25, readOnly: this.state.readOnly}} >
                    <Label text="Numero assis livello CS/DS" />
                  </SimpleItem>
                </GroupItem>

                <GroupItem colSpan={3} colCount={5} >
                  <SimpleItem dataField="inpsDelegation" editorType="dxCheckBox" editorOptions={{readOnly: this.state.readOnly}} >
                    <Label text="Delega inps" />
                  </SimpleItem>
                  <SimpleItem dataField="inpsCommunication" editorType="dxCheckBox" editorOptions={{readOnly: this.state.readOnly}} >
                    <Label text="Comunicaz. inps" />
                  </SimpleItem>
                  <SimpleItem dataField="transformationLetter" editorType="dxCheckBox" editorOptions={{readOnly: this.state.readOnly}} >
                    <Label text="Lettera trasform." />
                  </SimpleItem>
                  <SimpleItem dataField="onlineVariation" editorType="dxCheckBox" editorOptions={{readOnly: this.state.readOnly}} >
                    <Label text="Variazione online" />
                  </SimpleItem>
                  <SimpleItem dataField="sent" editorType="dxCheckBox" editorOptions={{readOnly: this.state.readOnly}} >
                    <Label text="Inviato" />
                  </SimpleItem>
                </GroupItem>
              </Form>

              <div className={'report-filter-buttons'}>
                <div className={'report-filter-buttons-left'}>
                  
                </div>
              </div>
            </form>      
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {    
    return this.renderView();    
  }
};

export default Transformation;