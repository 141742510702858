import React from 'react';

import { createStore } from 'devextreme-aspnet-data-nojquery';
import { DataGrid, Sorting, Column, Pager, Paging, FilterRow, Lookup, Editing, SearchPanel, HeaderFilter, MasterDetail, Selection} from 'devextreme-react/data-grid';
import Form, { Item, SimpleItem, Label, GroupItem, RequiredRule } from 'devextreme-react/form';
import { Template } from "devextreme-react/core/template";
import { SelectBox } from 'devextreme-react/select-box';
import notify from "devextreme/ui/notify";

import * as Global from '../../global'
import * as API from '../../api/api'

import EmployersWithSameMemberCodeGrid from './employers-with-same-member-code-grid';

import './member-code-with-multiple-employers-grid.scss';
 
export class MemberCodeWithMultipleEmployersGrid extends React.Component {
  constructor(props) {
    super(props);

    if (!this.props.authProvider)
      Global.Logout();

    this.state = {      
      authProvider: this.props.authProvider,
      duplicatedEmployersDataSource: this.createDuplicatedEmployersDataSource(),      
    };

    this.onMergeSuccess = this.onMergeSuccess.bind(this);
    
    this.dataGrid = null;
  }

  componentDidMount() {
    if (!this.props.authProvider)
      Global.Logout();
  }

  createDuplicatedEmployersDataSource() {   
    var store = createStore({
      key: 'id',
      loadUrl: Global.WebApiRootUrl + '/CheckData/GetMemberCodeWithMultipleEmployersGrid',
      onBeforeSend: (method, ajaxOptions) => {        
        ajaxOptions.xhrFields = { withCredentials: true };
        ajaxOptions.headers = { Authorization: `Bearer ${this.state.authProvider.user.token}`}
      },
      onAjaxError: ({ xhr, error}) => {
        this.props.authProvider.checkAuth(xhr);
      }
    });

    return store;
  }

  onMergeSuccess() {
    //alert("arrivato successo");
    this.dataGrid.instance.refresh();
  } 
 
  render() {
    return (
      <div>
        <div className={'ac-margin-top-10 content-block'}>
          <div className={'dx-card responsive-paddings'}>
            <div class="ac-card-title">Verifica datori di lavoro duplicati</div>

            <div id="duplicated-employers-grid">
              <DataGrid 
                id="duplicated-employers-grid-container"
                keyExpr="id"
                ref={(ref) => this.dataGrid = ref}
                className={'dx-card wide-card'} 
                dataSource={this.state.duplicatedEmployersDataSource} 
                remoteOperations={true}
                showBorders={true} 
                focusedRowEnabled={true} 
                defaultFocusedRowIndex={0} 
                columnAutoWidth={true}             
                allowColumnResizing={true} 
                columnHidingEnabled={false}
                onSelectionChanged={this.onTransformationsTableSelectionChanged}
                onRowPrepared={this.onTransformationsTableRowPrepared} 
              >
                <Selection mode="single" />
                <Paging defaultPageSize={15} />
                <Pager showPageSizeSelector={true} showInfo={true} />            
                <FilterRow visible={false} />
                <HeaderFilter visible={false} />
                <Editing mode="popup" allowUpdating={false} allowDeleting={false} allowAdding={false} />
                <Sorting mode="multiple" />
                          
                <Column dataField={'id'} visible={false} />
                <Column dataField={'memberCode'} caption={'Codice Socio'}  defaultSortIndex={1} defaultSortOrder="asc" alignment="left" />
                <Column dataField={'employersCount'} caption={'Numero Datori'} alignment="left" />

                <MasterDetail
                  enabled={true} 
                  autoExpandAll={true}               
                  render={props => {
                    return (                      
                        <EmployersWithSameMemberCodeGrid data={props.data} onMergeSuccess={this.onMergeSuccess}/>                      
                    );
                  }}
                />
              </DataGrid>
            </div>            
          </div>
        </div>        
      </div>
    );
  }
}

export default MemberCodeWithMultipleEmployersGrid;