import React from 'react';

import { useAuth } from '../../contexts/auth'; 
import Header from '../../components/assindatcolf/header'
import OpenedTransformationsGrid from '../../components/assindatcolf/opened-transformations-grid'
import OpenExpirationsGrid from '../../components/assindatcolf/opened-expirations-grid'

import './home.scss';

export default () => {  
  const authProvider = useAuth(); 

  var userName = "";
  var cityId = "00000000-0000-0000-0000-000000000000";
  var year = new Date().getFullYear();

  year = JSON.parse(localStorage.getItem('ac-year'));

  if (authProvider && authProvider.user) {     
    if(authProvider.user.firstName) {
      userName = authProvider.user.firstName;
    }
    if(authProvider.user.cityId) {
      cityId = authProvider.user.cityId;
    }     
  } else  { 
  }

  return(
    <React.Fragment>
      <Header sectionTitle="Home" authProvider={authProvider} showYearSelector={true}></Header>
      
      <OpenExpirationsGrid userCityId={cityId} authProvider={authProvider} year={year} ></OpenExpirationsGrid>
      <OpenedTransformationsGrid userCityId={cityId} authProvider={authProvider} year={year} ></OpenedTransformationsGrid>      
      
    </React.Fragment>
  );
}