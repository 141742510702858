import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { getUser, signIn as sendSignInRequest } from '../api/auth';

import * as Global from '../global'

function AuthProvider(props) {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async function () {
      const result = await getUser();
      if (result.isOk) {
        setUser(result.data);
      }

      setLoading(false);
    })();
  }, []);

  const signIn = useCallback(async (email, password, rememberMe) => {
    const result = await sendSignInRequest(email, password, rememberMe);
    if (result.isOk) {
      setUser(result.data);
    }

    return result;
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('ac-user');
    localStorage.removeItem('ac-stakeholders-filter');
    localStorage.removeItem('ac-report-filter');
    localStorage.removeItem('ac-year');
    localStorage.removeItem(Global.LocalStorageKey.FilterTransformations);
    setUser();
  }, []);

  const checkAuth = useCallback((xhr) => {
    if(xhr && xhr.status && [401, 403].indexOf(xhr.status) !== -1) {
      signOut();
    }          
  }, []);

  return (
    <AuthContext.Provider value={{ user, signIn, signOut, loading, checkAuth }} {...props} />
  );
}

const AuthContext = createContext({});
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth }
