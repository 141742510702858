import React from 'react';

import { useAuth } from '../../contexts/auth'; 
import ReportGrid from './report-grid';
import Header from '../../components/assindatcolf/header';

import * as Global from '../../global';

import './report.scss';

export default () => {
  const authProvider = useAuth(); 

  var cityId = "00000000-0000-0000-0000-000000000000";
  var cityName = "CittaNonDefinita";

  if (!localStorage.getItem('ac-year')) {
    localStorage.setItem('ac-year', JSON.stringify(new Date().getFullYear()));
  }
  var year = JSON.parse(localStorage.getItem('ac-year'));
  
  const user = useAuth();
  if (user) {
    if(user.user.cityId) {
      cityId = user.user.cityId;
    }
    if(user.user.cityName) {
      cityName = user.user.cityName;
    } 
  }

  var onYearChange = {
    raise() {
      window.location.reload(); //fallback classico
    }
  }
  
  return (
    <React.Fragment>
      <Header sectionTitle="Report" authProvider={authProvider} showYearSelector={true} parentYear={year} onYearChange={onYearChange} userCityId={cityId} userCityName={cityName} showPdfReportButtons={true}></Header>
      <ReportGrid userCityId={cityId} userCityName={cityName} year={year} authProvider={authProvider}></ReportGrid>
    </React.Fragment>
  );
};