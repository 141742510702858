import React from 'react';
import { DataGrid, Sorting, Column, Pager, Paging, FilterRow, Lookup, Editing, SearchPanel, HeaderFilter} from 'devextreme-react/data-grid';
import Form, { Item, SimpleItem, Label, GroupItem } from 'devextreme-react/form';
import { Button } from 'devextreme-react/button';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import StakeholdersDataGridRow from './stakeholders-grid-row.js';
import * as Global from '../../global'
import './stakeholders-grid.scss';

export class StakeholdersGrid extends React.Component {
  constructor(props) {
    super(props);

    if (!this.props.authProvider)
      Global.Logout();

    var storedGridFilter = this.initGridFilter();

    this.year = new Date().getFullYear();;
    if (this.props.year)
      this.year = this.props.year;

    this.userCityId = "00000000-0000-0000-0000-000000000000";
    if (this.props.userCityId)
      this.userCityId = this.props.userCityId;

    this.state = {
      stakeholdersDataSource: this.createStakeholdersDataSource(),
      lookupStakeholderType: this.getStakeholderType(),
      gridFilter: storedGridFilter,      
      monthItems: this.getFilterMonths(this.year),
      authProvider: this.props.authProvider,
    };

    this.onSearchButtonClick = this.onSearchButtonClick.bind(this);  
    this.onResetButtonClick = this.onResetButtonClick.bind(this); 
    this.onFormSubmit = this.onFormSubmit.bind(this);
    
    this.dataGrid = null;
  }

  componentDidMount() {
    if (!this.props.authProvider)
      Global.Logout();
  }

  refreshDataGrid() {
    this.dataGrid.instance.refresh();
  }

  initGridFilter() {    
    var filter = this.createGridFilter();
    
    if (JSON.parse(localStorage.getItem('ac-stakeholders-filter'))) {
      filter = JSON.parse(localStorage.getItem('ac-stakeholders-filter'));
    }    

    return filter;
  }

  createGridFilter() {    
    var filter = {
      stakeholderName: '',      
      stakeholderVariationsMonth: 0,      
      stakeholderType: 'Datore',
      stakeholderExcludeDateEnd: 1,
    };

    return filter;
  }

  createFilterPayLoad(cityId, year, gridFilter) {
    const payload = { 
      cityId: cityId,
      year: year,
      stakeholderName: gridFilter.stakeholderName,
      stakeholderVariationsMonth: gridFilter.stakeholderVariationsMonth,
      stakeholderType: gridFilter.stakeholderType,
      stakeholderExcludeDateEnd: gridFilter.stakeholderExcludeDateEnd,      
    };

    return payload;
  }

  getFilterMonths(year) {
    var months = Array.from({length: 13}, (e, i) => {
      if (i == 0)
        return {value: i, text: '-'};
      else
        return {value: i, text: this.capitalizeFirstLetter(new Date(null, i, null).toLocaleDateString("it", {month: "long"})) + ' ' + year};
    });

    return months;
  }

  getStakeholderType() {
    return [{value: '', text: '-'},{value: 'Datore', text: 'Datore'},{value: 'Dipendente', text: 'Dipendente'}]    
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  createStakeholdersDataSource() {   
    var store = createStore({
      key: 'id',
      loadUrl: Global.WebApiRootUrl + '/Stakeholders/Find',
      onBeforeSend: (method, ajaxOptions) => {
        if (method == "load" ) {
            //ajaxOptions.method = "POST";
            //console.log(ajaxOptions.data);

            var payload = ajaxOptions.data;
            payload.values = JSON.stringify(this.createFilterPayLoad(this.userCityId, this.year, this.state.gridFilter));

            ajaxOptions.data = payload;
            //console.log(ajaxOptions.data);
        }        
        ajaxOptions.xhrFields = { withCredentials: true };
        ajaxOptions.headers = { Authorization: `Bearer ${this.state.authProvider.user.token}`}
      },
      onAjaxError: ({ xhr, error}) => {
        this.props.authProvider.checkAuth(xhr);
      }
    });

    return store;
  }

  onSearchButtonClick(e) {    
    this.setState({
      stakeholdersDataSource: this.createStakeholdersDataSource(),
    });

    localStorage.setItem('ac-stakeholders-filter', JSON.stringify(this.state.gridFilter));

    this.refreshDataGrid();    
  }

  onResetButtonClick(e) {    
    var filter = this.createGridFilter();

    this.filterForm.instance.resetValues();

    this.setState({
      stakeholdersDataSource: this.createStakeholdersDataSource(),
      gridFilter: filter
    });

    localStorage.removeItem('ac-stakeholders-filter');

    this.refreshDataGrid();
  }

  onFormSubmit(e) {
    if(e && e.onSearchButtonClick)
      e.onSearchButtonClick();
    else
      alert("Si è verificato un errore, non è possibile procedere.")    
  }

  render() {    
    return (
      <div className={'content-block'}>

        <div className={'stakeholders-filter-container dx-card wide-card'}>
          <div className="stakeholders-filter-title">Ricerca anagrafica per:</div>
          <div className="stakeholders-filter-form-container">
            <form action="#" className={'stakeholders-filter-form'} onSubmit={ e => {e.preventDefault(); this.onFormSubmit(this);}}>
              <Form colCount={3} id="form2" formData={this.state.gridFilter} ref={(ref) => this.filterForm = ref} >                  
                  <GroupItem colSpan={1} colCount={1} >
                    <SimpleItem dataField="stakeholderName" editorType="dxTextBox" editorOptions={{maxLength: 25}}  >
                      <Label text="Cognome" />
                    </SimpleItem>
                  </GroupItem>
                  <GroupItem  colSpan={1} colCount={2}>
                    <SimpleItem dataField="stakeholderVariationsMonth" editorType="dxSelectBox" editorOptions={{ items: this.state.monthItems, displayExpr: 'text', valueExpr: 'value' }} >
                      <Label text="Mese variazioni" />
                    </SimpleItem>                    
                    <SimpleItem dataField="stakeholderType" editorType="dxSelectBox" editorOptions={{ items: this.state.lookupStakeholderType, displayExpr: 'text', valueExpr: 'value' }} >
                      <Label text="Tipo" />
                    </SimpleItem>                    
                  </GroupItem>
                  <GroupItem  colSpan={1} colCount={1}>
                    <SimpleItem dataField="stakeholderExcludeDateEnd" editorType="dxCheckBox" >
                      <Label text="Escludi cessati anni precedenti" />
                    </SimpleItem>
                  </GroupItem>                  
              </Form>

              <div className={'stakeholders-filter-buttons'}>
                <div className={'stakeholders-filter-buttons-left'}>
                  <Button className="ac-button-default" width={120} text="Cerca" type="default" stylingMode="contained" useSubmitBehavior={true} />
                  <span> </span>
                  <Button width={155} text="Cancella filtri" type="default" stylingMode="outlined" onClick={this.onResetButtonClick} />
                </div>                
              </div>
            </form>
          </div>
        </div>

        <div id="stakeholders-grid">
          <DataGrid 
            id="gridContainer"
            ref={(ref) => this.dataGrid = ref}
            className={'dx-card wide-card'} 
            dataSource={this.state.stakeholdersDataSource} 
            remoteOperations={true}
            showBorders={true} 
            focusedRowEnabled={true} 
            defaultFocusedRowIndex={0} 
            columnAutoWidth={true} 
            columnHidingEnabled={true}
            rowRender={StakeholdersDataGridRow}            
          >

            <Paging defaultPageSize={15} />
            <Pager showPageSizeSelector={true} showInfo={true} />            
            <FilterRow visible={false} />
            <HeaderFilter visible={false} />
            <Editing mode="popup" allowUpdating={false} allowDeleting={false} allowAdding={false} />
            <Sorting mode="multiple" />
                      
            <Column dataField={'id'} hidingPriority={1} visible={false} allowSearch={false} />
            <Column dataField={'employerId'} hidingPriority={1} visible={false} allowSearch={false} />
            <Column dataField={'employeeId'} hidingPriority={1} visible={false} allowSearch={false} />
            <Column dataField={'lastName'} caption={'Cognome'} hidingPriority={4} defaultSortIndex={0} defaultSortOrder='asc' />
            <Column dataField={'firstName'} caption={'Nome'} hidingPriority={3} allowSearch={false} defaultSortIndex={1} defaultSortOrder='asc' />
            <Column dataField={'employeeRegistryNotes'} caption={'Codice socio'} hidingPriority={3} allowSearch={false} alignment='left' />
            <Column dataField={'fiscalCode'} caption={'Codice fiscale'} hidingPriority={2}  allowSearch={false} />
            <Column dataField={'employeeInpsCode'} caption={'Codice INPS'} hidingPriority={2}  allowSearch={false} />
            <Column dataField={'employeeRecruitmentDate'} caption={'Data ass.'} hidingPriority={2}  allowSearch={false} />
            <Column dataField={'employeeEndDate'} caption={'Data cess.'} hidingPriority={2}  allowSearch={false} />
            <Column dataField={'type'} caption={'Tipo'} hidingPriority={1} allowSearch={false} >
              <Lookup dataSource={this.state.lookupStakeholderType} />
            </Column>
            <Column dataField={'entity'} caption={'Entità'} hidingPriority={1} visible={false} allowSearch={false} />
            <Column dataField={'path'} caption={'Path'} hidingPriority={1} visible={false} allowSearch={false} />
            <Column dataField={'employeeContractCategory'} caption={'employeeContractCategory'} hidingPriority={1} visible={false} allowSearch={false} />
            <Column dataField={'employeeContractType'} caption={'employeeContractType'} hidingPriority={1} visible={false} allowSearch={false} />
            <Column dataField={'employeeHalfDayOff'} caption={'employeeHalfDayOff'} hidingPriority={1} visible={false} allowSearch={false} />
            <Column dataField={'employeeLevel'} caption={'employeeLevel'} hidingPriority={1} visible={false} allowSearch={false} />
            <Column dataField={'employeeTask'} caption={'employeeTask'} hidingPriority={1} visible={false} allowSearch={false} />
            <Column dataField={'employeeNotes'} caption={'employeeNotes'} hidingPriority={1} visible={false} allowSearch={false} />
            <Column dataField={'employeeUserNotes'} caption={'employeeUserNotes'} hidingPriority={1} visible={false} allowSearch={false} />            
          </DataGrid>
        </div> 
      </div>
    );
  }
}

export default StakeholdersGrid;