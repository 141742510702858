import React from 'react';
import { useAuth } from '../../contexts/auth'; 
import { ImportGrid } from './import-grid.js';

import './import.scss';

export default () => {
  const authProvider = useAuth();

  return (
    <React.Fragment>        
      <div className={'content-block ac-page-title'}>Importazione anagrafica</div>
      <div className={'content-block'}>
        <ImportGrid authProvider={authProvider} ></ImportGrid>
      </div>
    </React.Fragment>
  );
};