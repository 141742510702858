import React from 'react';

import { Link } from 'react-router-dom';

import './dataRow.scss';

export default function DataRow(rowInfo) {

  var slug = rowInfo.data.lastName + '-' + rowInfo.data.firstName + '-' + rowInfo.data.fiscalCode;
  slug = slug.replace(/\s/g,'').toLowerCase();

  rowInfo.data.employer = {};
  rowInfo.data.employer.firstName = rowInfo.data.employerFirstName;
  rowInfo.data.employer.lastName = rowInfo.data.employerLastName;
  rowInfo.data.employer.fiscalCode = rowInfo.data.employerFiscalCode;
  rowInfo.data.employer.employerId = rowInfo.data.employerId;

  return (
    <tr className="custom-row dx-row dx-data-row dx-row-lines">
        <td><Link to={{ pathname: 'employee', search: slug, state: { stakeholder: rowInfo.data, from: 'stakeholders' } }} >{rowInfo.data.lastName}</Link></td>
        <td><Link to={{ pathname: 'employee', search: slug, state: { stakeholder: rowInfo.data, from: 'stakeholders' } }} >{rowInfo.data.firstName}</Link></td> 
        <td><Link to={{ pathname: 'employee', search: slug, state: { stakeholder: rowInfo.data, from: 'stakeholders' } }} >{rowInfo.data.employeeRegistryNotes}</Link></td>        
        <td><Link to={{ pathname: 'employee', search: slug, state: { stakeholder: rowInfo.data, from: 'stakeholders' } }} >{rowInfo.data.fiscalCode}</Link></td>
        <td><Link to={{ pathname: 'employee', search: slug, state: { stakeholder: rowInfo.data, from: 'stakeholders' } }} >{rowInfo.data.employeeInpsCode}</Link></td>
        <td><Link to={{ pathname: 'employee', search: slug, state: { stakeholder: rowInfo.data, from: 'stakeholders' } }} >{rowInfo.data.employeeRecruitmentDate}</Link></td>
        <td><Link to={{ pathname: 'employee', search: slug, state: { stakeholder: rowInfo.data, from: 'stakeholders' } }} >{rowInfo.data.employeeEndDate}</Link></td>        
    </tr>      
  );
}
