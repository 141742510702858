import React from 'react';
import { useAuth } from '../../contexts/auth'; 
import UsersGrid from './users-grid'

import './users.scss';

export default () => {
  const authProvider = useAuth(); 

  return (
    <React.Fragment>    
      <div className={'content-block ac-page-title'}>Gestione utenti</div>
      <div className={'content-block'}>
        <UsersGrid authProvider={authProvider} ></UsersGrid>
      </div>    
    </React.Fragment>
  );
};