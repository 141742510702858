import React from 'react';

import Box, { Item } from 'devextreme-react/box';

import * as Global from '../../global'

import './employer-details.scss';
 
export class EmployerDetails extends React.Component {
    constructor(props) {
        super(props);

        this.state = {       
          employer: {},
          loggedUser: Global.GetLoggedUser(),
          lightView: false, /* 23/05/2021 aggiunto per differenziare una vista con tutte le informazioni o senza, il default è tutte le informazioni, ovvero lightView = false */
      };
    }

    componentDidMount() {
      if(this.props.employerId) {
        this.getEmployerData();
      }

      if(this.props.lightView) {
        this.setState({lightView: this.props.lightView}); 
      }       
    }

    getEmployerData() {
      var url =  Global.WebApiRootUrl + "/Employers/" + this.props.employerId;

      const requestOptions = {
        method: 'GET',
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          // "Content-Type": "application/json",
          Authorization: `Bearer ${this.state.loggedUser.token}`,
        }
      };
              
      fetch(url, requestOptions)
        .then(response => {          
          if(response.ok) 
            return response.json();
            
          Global.CheckResponseAuth(response);
          throw new Error('Request failed.');
        })
        .then(data => {          
          this.setState({
            employer: data
          });        
        })
        .catch(error => {          
          console.log(error);
        });
    }

    renderItemData(itemData) {
      return (
        <span>{(itemData && itemData != '') ? (itemData) : ('-')}</span>
      );
    }

    renderView(){
      if(this.state.lightView)
        return this.renderLigthView();
      else
        return this.renderFullView();
    }

    renderLigthView(){    
      return  <div>
                <Box direction="row" width="100%">
                  <Item ratio={1}>
                    <div className="dx-field">
                      <div className="dx-field-label">Cognome e nome:</div>
                      <div className="dx-field-value-static">{this.state.employer.lastName} {this.state.employer.firstName}</div>
                    </div>
                  </Item>              
                  <Item ratio={1}>
                    <div className="dx-field">
                      <div className="dx-field-label">Codice fiscale:</div>
                      <div className="dx-field-value-static">{this.renderItemData(this.state.employer.fiscalCode)}</div>
                    </div>
                  </Item>                 
                </Box> 
                <Box direction="row" width="100%">
                  <Item ratio={1}>                  
                    <div className="dx-field">
                      <div className="dx-field-label">Modalità invio:</div>
                      <div className="dx-field-value-static">{this.renderItemData(this.state.employer.sendingMethod)}</div>
                    </div>
                  </Item>
                  <Item ratio={1}>
                    <div className="dx-field">
                      <div className="dx-field-label">E-mail:</div>
                      <div className="dx-field-value-static">{this.renderItemData(this.state.employer.email)}</div>
                    </div>
                  </Item>
                </Box>
              </div>
    }

    renderFullView(){    
      return  <div>
                <Box direction="row" width="100%">
                  <Item ratio={1}>
                    <div className="dx-field">
                      <div className="dx-field-label">Cognome e nome:</div>
                      <div className="dx-field-value-static">{this.state.employer.lastName} {this.state.employer.firstName}</div>
                    </div>
                  </Item>              
                  <Item ratio={1}>
                    <div className="dx-field">
                      <div className="dx-field-label">Codice fiscale:</div>
                      <div className="dx-field-value-static">{this.renderItemData(this.state.employer.fiscalCode)}</div>
                    </div>
                  </Item>   
                  <Item ratio={1}></Item>
                  <Item ratio={1}></Item>              
                </Box> 
                <Box direction="row" width="100%">
                  <Item ratio={1}>                  
                    <div className="dx-field">
                      <div className="dx-field-label">Modalità invio:</div>
                      <div className="dx-field-value-static">{this.renderItemData(this.state.employer.sendingMethod)}</div>
                    </div>
                  </Item>
                  <Item ratio={1}>
                    <div className="dx-field">
                      <div className="dx-field-label">E-mail:</div>
                      <div className="dx-field-value-static">{this.renderItemData(this.state.employer.email)}</div>
                    </div>
                  </Item>
                  <Item ratio={1}></Item>   
                  <Item ratio={1}></Item>              
                </Box>
                <Box direction="row" width="100%">
                  <Item ratio={1}>                  
                    <div className="dx-field">
                      <div className="dx-field-label">Numero di telefono:</div>
                      <div className="dx-field-value-static">{this.renderItemData(this.state.employer.phoneNumber)}</div>
                    </div>
                  </Item>
                  <Item ratio={1}>
                    <div className="dx-field">
                      <div className="dx-field-label">Cellulare:</div>
                      <div className="dx-field-value-static">{this.renderItemData(this.state.employer.mobileNumber)}</div>
                    </div>
                  </Item>              
                  <Item ratio={1}>
                    <div className="dx-field">
                      <div className="dx-field-label">Fax:</div>
                      <div className="dx-field-value-static">{this.renderItemData(this.state.employer.faxNumber)}</div>
                    </div>
                  </Item>
                  <Item ratio={1}></Item> 
                </Box>
              </div>
    }
 
    render() {      
      return (
        <div className={'employer-details-content-block'}>
          <div className="dx-fieldset employer-group">
            <div className="ac-fieldset-header">{this.props.title}</div>
            {this.renderView()}
          </div>          
        </div>
      );
    }
}

export default EmployerDetails;