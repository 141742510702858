import { withNavigationWatcher } from './contexts/navigation';
import { HomePage, UsersPage, ImportPage, StakeholdersPage, EmployeePage, EmployerPage, ReportPage, TransformationsPage, TransformationPage, ExpirationsPage, ExpirationPage, RecruitmentsPage, RecruitmentPage, RecruitmentsCreateEmployeePage, CheckdataPage } from './pages';
import { RecruitmentsCreateEmployee } from './pages/recruitments/createEmployee.js';

const routes = [  
  {
    path: '/home',
    component: HomePage
  }, 
  {
    path: '/users',
    component: UsersPage
  }, 
  {
    path: '/import',
    component: ImportPage
  }, 
  {
    path: '/stakeholders',
    component: StakeholdersPage
  }, 
  {    
    path: '/employee',
    component: EmployeePage
  }, 
  {
    path: '/employer',
    component: EmployerPage
  }, 
  {
    path: '/report',
    component: ReportPage
  }, 
  {
    path: '/transformations',
    component: TransformationsPage
  },
  {
    path: '/transformations/:employeeId',
    component: TransformationPage
  },
  {
    path: '/transformations/:employeeId/archived/:transformationVersionId',
    component: TransformationPage
  }, 
  {
    path: '/expirations',
    component: ExpirationsPage
  }, 
  {
    path: '/expirations/:employeeId',
    component: ExpirationPage
  }, 
  {
    path: '/expirations/:employeeId/archived/:expirationVersionId',
    component: ExpirationPage
  }, 
  {
    path: '/recruitments',
    component: RecruitmentsPage
  }, 
  {
    path: '/recruitments/new',
    component: RecruitmentsCreateEmployee    
  }, 
  {
    path: '/recruitments/:employeeId',
    component: RecruitmentPage
  }, 
  {
    path: '/recruitments/:employeeId/archived/:recruitmentVersionId',
    component: RecruitmentPage
  }, 
  {
    path: '/checkdata',
    component: CheckdataPage
  }
];

export default routes.map(route => {
  return {
    ...route,
    component: withNavigationWatcher(route.component)
  };
});
