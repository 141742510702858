//import API from './baseApi'
import * as Global from '../global'

export function getEmployeeWithEmployer(token, employeeId) {
    var url =  Global.WebApiRootUrl + "/employees/getEmployeeWithEmployer/" + employeeId;

    const requestOptions = {
        method: 'GET',
        withCredentials: true,
        headers: {
            Accept: "application/json, text/plain, */*",
            Authorization: `Bearer ${token}`,
        }
    };
            
    return fetch(url, requestOptions)
        .then(response => {
            if(response.ok) return response.json();
            Global.CheckResponseAuth(response);
            return null;
        })
        .then(data => {
            return data; 
        })
        .catch(error => {
            return null;
        });       
}

export function getEmployeeLastTransformation(token, employeeId) {
    var url =  Global.WebApiRootUrl + "/transformations/employeeLastransformation/" + employeeId;

    const requestOptions = {
        method: 'GET',
        withCredentials: true,
        headers: {
            Accept: "application/json, text/plain, */*",
            Authorization: `Bearer ${token}`,
        }
    };
            
    return fetch(url, requestOptions)
        .then(response => {
            if(response.ok) return response.json();
            Global.CheckResponseAuth(response);
            return null;
        })
        .then(data => {
            return data; 
        })
        .catch(error => {
            return null;
        });       
}

export function getEmployeeArchivedTransformation(token, employeeId, transformationVersionId) {
    
    var url =  Global.WebApiRootUrl + "/transformations/employeeArchivedTransformation/" + employeeId + "/" + transformationVersionId;
    //console.log("getEmployeeArchivedTransformation", url);

    const requestOptions = {
        method: 'GET',
        withCredentials: true,
        headers: {
            Accept: "application/json, text/plain, */*",
            Authorization: `Bearer ${token}`,
        }
    };
            
    return fetch(url, requestOptions)
        .then(response => {
            if(response.ok) return response.json();
            Global.CheckResponseAuth(response);
            return null;
        })
        .then(data => {
            return data; 
        })
        .catch(error => {
            return null;
        });       
}

export function getEmployeeLastExpiration(token, employeeId) {
    var url =  Global.WebApiRootUrl + "/expirations/employeeLastExpiration/" + employeeId;

    const requestOptions = {
        method: 'GET',
        withCredentials: true,
        headers: {
            Accept: "application/json, text/plain, */*",
            Authorization: `Bearer ${token}`,
        }
    };
            
    return fetch(url, requestOptions)
        .then(response => {
            if(response.ok) return response.json();
            Global.CheckResponseAuth(response);
            return null;
        })
        .then(data => {
            return data; 
        })
        .catch(error => {
            return null;
        });       
}

export function getEmployeeArchivedExpiration(token, employeeId, expirationVersionId) {    
    var url =  Global.WebApiRootUrl + "/expirations/employeeArchivedExpiration/" + employeeId + "/" + expirationVersionId;
    
    const requestOptions = {
        method: 'GET',
        withCredentials: true,
        headers: {
            Accept: "application/json, text/plain, */*",
            Authorization: `Bearer ${token}`,
        }
    };
            
    return fetch(url, requestOptions)
        .then(response => {
            if(response.ok) return response.json();
            Global.CheckResponseAuth(response);
            return null;
        })
        .then(data => {
            return data; 
        })
        .catch(error => {
            return null;
        });       
}

export function getAllEmployersByCityId(token, cityId, lightList) {
    var url =  Global.WebApiRootUrl + '/employers/getAllEmployersByCityId?cityId=' + cityId + '&lightList=' + lightList;

    const requestOptions = {
        method: 'GET',
        withCredentials: true,
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
        }
    };
            
    return fetch(url, requestOptions)
        .then(response => {
            if(response.ok) return response.json();        
            Global.CheckResponseAuth(response);
            return null;
        })
        .then(data => {       
            if(data && data.data)   
                return data.data
            
            return null;
        })
        .catch(error => {
            return null;
        });
}

export function getAllEmployeesByCityId(token, cityId, lightList) {
    var url =  Global.WebApiRootUrl + '/employees/getAllEmployeesByCityId?cityId=' + cityId + '&lightList=' + lightList;

    const requestOptions = {
        method: 'GET',
        withCredentials: true,
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
        }
    };
            
    return fetch(url, requestOptions)
        .then(response => {
            if(response.ok) return response.json();        
            Global.CheckResponseAuth(response);
            return null;
        })
        .then(data => {       
            if(data && data.data)   
                return data.data
            
            return null;
        })
        .catch(error => {
            return null;
        });
}

export function getEmployeeRecruitment(token, employeeId) {
    var url =  Global.WebApiRootUrl + "/recruitments/employeeRecruitment/" + employeeId;

    const requestOptions = {
        method: 'GET',
        withCredentials: true,
        headers: {
            Accept: "application/json, text/plain, */*",
            Authorization: `Bearer ${token}`,
        }
    };
            
    return fetch(url, requestOptions)
        .then(response => {
            if(response.ok) return response.json();
            Global.CheckResponseAuth(response);
            return null;
        })
        .then(data => {
            return data; 
        })
        .catch(error => {
            return null;
        });       
}

export function getEmployeeArchivedRecruitment(token, employeeId, recruitmentVersionId) {
    
    var url =  Global.WebApiRootUrl + "/recruitments/employeeArchivedRecruitment/" + employeeId + "/" + recruitmentVersionId;

    const requestOptions = {
        method: 'GET',
        withCredentials: true,
        headers: {
            Accept: "application/json, text/plain, */*",
            Authorization: `Bearer ${token}`,
        }
    };
            
    return fetch(url, requestOptions)
        .then(response => {
            if(response.ok) return response.json();
            Global.CheckResponseAuth(response);
            return null;
        })
        .then(data => {
            return data; 
        })
        .catch(error => {
            return null;
        });       
}