import React from 'react';

import { createStore } from 'devextreme-aspnet-data-nojquery';
import { DataGrid, Column, Pager, Paging, FilterRow, Editing } from 'devextreme-react/data-grid';
import Accordion from 'devextreme-react/accordion';

import * as Global from '../../global'

import './employee-hours.scss';
 
export class EmployeeHours extends React.Component {
  constructor(props) {
    super(props);

    if (!this.props.loggedUser)
      Global.Logout();

    this.state = {
      accordionData : [],
      loggedUser: this.props.loggedUser,
    };

    this.renderItem = this.renderItem.bind(this);
  }

  componentDidMount() {
    if (!this.props.loggedUser)
      Global.Logout();

    this.setState({
        accordionData : [{
          title: this.props.title,
          extraTitleText: this.props.extraTitleText,
          extraTitleNotes: this.props.extraTitleNotes,
          employeeId: this.props.employeeId,
          year: this.props.year,
          original: this.props.original,
          element: null,
        }]
    });
  }

  onAccordionItemClick(item) {    
    if(item && item.itemData && item.itemData.element) {      
      //item.itemData.element.style = "display: block;";
    }   
  }

  onAccordionItemRendered(item) {
    if(item && item.component && item.itemData && item.itemData.original && item.itemData.original == 1) {     
      item.component.collapseItem(0);          
    }
  }
 
  render() {
    return (
      <div className={'employee-hours-content-block'}>
        <div class="employee-hours-accordion">
          <Accordion            
            dataSource={this.state.accordionData}
            collapsible={true}
            itemTitleRender={this.renderTitle}
            itemRender={this.renderItem}            
            onItemRendered={this.onAccordionItemRendered}
            onItemClick={this.onAccordionItemClick}
          />
        </div>
      </div>
    );
  }

  renderTitle(data) {
    //console.log("renderTitle", data);
    var title = "Titolo";
    var extraTitleText = "";
    var extraTitleNotes = "";

    var renderExtraData = null;
    

    if (data) {
      if (data.title)
        title = data.title;

      if (data.extraTitleText)
        extraTitleText = data.extraTitleText;

      if (data.extraTitleNotes)
        extraTitleNotes = data.extraTitleNotes;      
    }

    renderExtraData = ()=>{
      if(extraTitleNotes){
        return <span className={'ac-accordion-title-right-small'}><div>{data.extraTitleText}</div><br/><div>{data.extraTitleNotes}</div></span>
      } else{
        return <span className={'ac-accordion-title-right'}>{data.extraTitleText}</span>
      }
    }

    return (
        <div className={'ac-accordion-title'}>
            <span className={'ac-accordion-title-left'}>{data.title}</span>
            {renderExtraData()}
            <span className={'ac-clear-both'}></span>
        </div>
    );
  }

  renderItem(itemData) {
    const urlhoursDataSourceurl2 = Global.WebApiRootUrl + "/Employees/" + itemData.employeeId + "/Hours/" + itemData.year + "/" + itemData.original;

    const hoursDataSource2 = createStore({
      key: 'hourId',
      loadUrl: urlhoursDataSourceurl2,
      onBeforeSend: (method, ajaxOptions) => {        
        ajaxOptions.xhrFields = { withCredentials: true };
        ajaxOptions.headers = { Authorization: `Bearer ${this.state.loggedUser.token}`}
      },
      onAjaxError: ({ xhr, error}) => {
        Global.CheckResponseAuth(xhr);
      }
    });

    return (
      <div 
        className={'employee-hours-grid'} 
        style={{ display: itemData.original == 0 ? "block" : "block" }}
        ref={(ref) => itemData.element = ref}
        >
        <DataGrid className={'dx-card wide-card'} dataSource={hoursDataSource2} showBorders={false} focusedRowEnabled={true} defaultFocusedRowIndex={0} columnAutoWidth={true} columnHidingEnabled={true}>
          <Paging defaultPageSize={10} />
          <Pager showPageSizeSelector={true} showInfo={true} />
          <FilterRow visible={false} />
          <Editing allowUpdating={false} allowDeleting={false} allowAdding={false} />
                    
          <Column dataField={'hourId'} hidingPriority={1} visible={false} />
          <Column dataField={'hourTypeName'} caption={'Tipo'} hidingPriority={8} allowSorting={false} />
          <Column dataField={'monday'} caption={'Lunedi'} hidingPriority={7} allowSorting={false} />
          <Column dataField={'tuesday'} caption={'Martedi'} hidingPriority={6} allowSorting={false} />
          <Column dataField={'wednesday'} caption={'Mercoledi'} hidingPriority={5} allowSorting={false} />
          <Column dataField={'thursday'} caption={'Giovedi'} hidingPriority={4} allowSorting={false} />
          <Column dataField={'friday'} caption={'Venerdi'} hidingPriority={3} allowSorting={false} />
          <Column dataField={'saturday'} caption={'Sabato'} hidingPriority={2} allowSorting={false} />
          <Column dataField={'sunday'} caption={'Domenica'} hidingPriority={1} allowSorting={false} />
          <Column dataField={'total'} caption={'Totale'} hidingPriority={1} allowSorting={false} /> 
          <Column dataField={'year'} caption={'Anno'} hidingPriority={1} visible={false} />
          <Column dataField={'original'} caption={'Originale'} hidingPriority={1} visible={false} />
        </DataGrid>
      </div>
    );
  }
}

export default EmployeeHours;