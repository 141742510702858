import React from 'react';
import { withRouter } from 'react-router-dom'
import { DataGrid, Sorting, Column, Pager, Paging, FilterRow, Lookup, Editing, SearchPanel, HeaderFilter, MasterDetail, Selection} from 'devextreme-react/data-grid';
import Form, { Item, SimpleItem, Label, GroupItem } from 'devextreme-react/form';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { LoadPanel } from 'devextreme-react/load-panel';
import { Button } from 'devextreme-react/button';
import notify from "devextreme/ui/notify";

import * as Global from '../../global'

import ArchivedTransformationsDetailTemplate from './transformations-details-grid.js';

import './transformations-grid.scss';

export class TransformationsGrid extends React.Component {
  constructor(props) {
    super(props);

    if (!this.props.authProvider)
      Global.Logout();

    var storedGridFilter = this.initGridFilter();

    this.year = new Date().getFullYear();;
    if (this.props.year)
      this.year = this.props.year;

    this.userCityId = "00000000-0000-0000-0000-000000000000";
    if (this.props.userCityId)
      this.userCityId = this.props.userCityId;

    this.state = {
      transformationsDataSource: this.createTransformationsDataSource(),
      gridFilter: storedGridFilter,      
      authProvider: this.props.authProvider,
      toastManager: this.createToastManager(),
      isLoadPanelVisible: false,
    };

    this.onTransformationsTableSelectionChanged = this.onTransformationsTableSelectionChanged.bind(this);
    this.onExportTransformationsToPDFButtonClick = this.onExportTransformationsToPDFButtonClick.bind(this); 
    this.onSearchButtonClick = this.onSearchButtonClick.bind(this);  
    this.onResetButtonClick = this.onResetButtonClick.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);    
    this.dataGrid = null;
  }

  componentDidMount() {
    if (!this.props.authProvider)
      Global.Logout();
  }

  refreshDataGrid() {
    this.dataGrid.instance.refresh();
  }

  initGridFilter() {    
    var filter = this.createGridFilter();
    
    if (JSON.parse(localStorage.getItem(Global.LocalStorageKey.FilterTransformations))) {
      filter = JSON.parse(localStorage.getItem(Global.LocalStorageKey.FilterTransformations));
    }    

    return filter;
  }

  createGridFilter() {    
    var filter = {
      employerLastName: '',
      employerFiscalCode: '',
      employeeLastNameFirstLetterFrom: '',
      employeeLastNameFirstLetterTo: '',
      takingChargeDateFrom: '',
      takingChargeDateTo: '',
      monthFrom: '',
      monthTo: '',
      employeeExcludeDateEnd: 1,
      employeeWithTransformations: 0,
      employeeWithOpenedTransformation: 0,
      inpsDelegationOn: 0,
      inpsDelegationOff: 0,
      inpsCommunicationOn: 0,
      inpsCommunicationOff: 0,
      transformationLetterOn: 0,
      transformationLetterOff: 0,
      onlineVariationOn: 0,
      onlineVariationOff: 0,
      sentOn: 0,
      sentOff: 0,
    };

    return filter;
  }

  createFilterPayLoad(cityId, year, gridFilter) {
    const payload = { 
      cityId: cityId,
      year: year,
      employerLastName: gridFilter.employerLastName,
      employerFiscalCode: gridFilter.employerFiscalCode,
      employeeLastNameFirstLetterFrom: gridFilter.employeeLastNameFirstLetterFrom,
      employeeLastNameFirstLetterTo: gridFilter.employeeLastNameFirstLetterTo,
      employeeExcludeDateEnd: gridFilter.employeeExcludeDateEnd, 
      employeeWithTransformations: gridFilter.employeeWithTransformations, 
      employeeWithOpenedTransformation: gridFilter.employeeWithOpenedTransformation,
      takingChargeDateFrom: gridFilter.takingChargeDateFrom,
      takingChargeDateTo: gridFilter.takingChargeDateTo,
      monthFrom: gridFilter.monthFrom,
      monthTo: gridFilter.monthTo,
      inpsDelegationOn: gridFilter.inpsDelegationOn,
      inpsDelegationOff: gridFilter.inpsDelegationOff,
      inpsCommunicationOn: gridFilter.inpsCommunicationOn,
      inpsCommunicationOff: gridFilter.inpsCommunicationOff,
      transformationLetterOn: gridFilter.transformationLetterOn,
      transformationLetterOff: gridFilter.transformationLetterOff,
      onlineVariationOn: gridFilter.onlineVariationOn,
      onlineVariationOff: gridFilter.onlineVariationOff,
      sentOn: gridFilter.sentOn,
      sentOff: gridFilter.sentOff,     
    };

    return payload;
  }

  createToastManager() {    
    var t = {
      isVisible: false,
      type: 'info',
      message: ''
    };

    return t;
  }

  createTransformationsDataSource() {   
    var store = createStore({
      key: 'id',
      loadUrl: Global.WebApiRootUrl + '/Transformations/Find',
      onBeforeSend: (method, ajaxOptions) => {
        if (method == "load" ) {
            //ajaxOptions.method = "POST";
                        
            var payload = ajaxOptions.data;
            payload.values = JSON.stringify(this.createFilterPayLoad(this.userCityId, this.year, this.state.gridFilter));
            ajaxOptions.data = payload;
            
            //console.log(ajaxOptions.data);
        }        
        ajaxOptions.xhrFields = { withCredentials: true };
        ajaxOptions.headers = { Authorization: `Bearer ${this.state.authProvider.user.token}`}
      },
      onAjaxError: ({ xhr, error}) => {
        this.props.authProvider.checkAuth(xhr);
      }
    });

    return store;
  }

  onSearchButtonClick(e) {    
    this.setState({
      transformationsDataSource: this.createTransformationsDataSource(),
    });

    localStorage.setItem(Global.LocalStorageKey.FilterTransformations, JSON.stringify(this.state.gridFilter));

    this.refreshDataGrid();    
  }

  onResetButtonClick(e) {    
    var filter = this.createGridFilter();

    this.filterForm.instance.resetValues();

    this.setState({
      transformationsDataSource: this.createTransformationsDataSource(),
      gridFilter: filter
    });

    localStorage.removeItem(Global.LocalStorageKey.FilterTransformations);

    this.refreshDataGrid();
  }

  onFormSubmit(e) {
    if(e && e.onSearchButtonClick)
      e.onSearchButtonClick();
    else
      alert("Si è verificato un errore, non è possibile procedere.")    
  }

  onTransformationsTableSelectionChanged(e) {
    //console.log("selectionChanged", e.currentSelectedRowKeys[0]);
    this.props.history.push({pathname: 'transformations/' + e.currentSelectedRowKeys[0]});
  }

  onTransformationsTableRowPrepared(e) {
    //console.log("onTransformationsTableRowPrepared", e);
    
    //remove arrow if there is not transformations
    if(e && e.rowType && e.rowType == "data" && e.data && e.data.closedTransformationsCount == 0) {      
      if(e.rowElement && e.rowElement.childNodes[0] && e.rowElement.childNodes[0].childNodes[0] && e.rowElement.childNodes[0].childNodes[0].style)
        e.rowElement.childNodes[0].childNodes[0].style.display = "none";      
    }    
  }

  onExportTransformationsToPDFButtonClick() {
    this.showLoadPanel();

    const url =  Global.WebApiRootUrl + "/transformations/exportTransformationsToPDF";

      var formData = new FormData();
  
      formData.append("values", JSON.stringify(this.createFilterPayLoad(this.userCityId, this.year, this.state.gridFilter)));
          
      // POST request using fetch with error handling
      const requestOptions = {
        method: 'POST',
        body: formData,
        responseType: 'blob',
        withCredentials: true,
        headers: {
          Accept: "application/pdf",
          Authorization: `Bearer ${this.state.authProvider.user.token}`,
        }
      };
      fetch(url, requestOptions)
        .then(response => {          
          if(response && response.status == 404) {
            throw Error("Non sono stati trovati risultati che soddisfano la ricerca");            
          } else {
            if(response && response.ok) 
              return response.blob();
            
            Global.CheckResponseAuth(response);
          }
        })
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;

            var today = new Date(); 
            var filename = "trasformazioni_" + today.getDate() + "-" + (today.getMonth() + 1) + "-" + today.getFullYear() + "_filtri.pdf";

            a.download = filename;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();  //afterwards we remove the element again       
            
            this.hideLoadPanel();
        })
        .catch(error => { this.hideLoadPanel(); notify(error.message, 'error', 5000); });
  }

  transformationsTableStateCellRender(rowInfo) {
    //console.log("transformationsTableStateCellRender", rowInfo)

    var stateClass = "";
    var stateVal = "";

    /*
    if (rowInfo.data.state.toLowerCase() == "aperta")
      stateClass = "ac-grid-cell-opened-item";
    else if (rowInfo.data.state.toLowerCase() == "chiusa") 
      stateClass = "ac-grid-cell-closed-item";
      */

    if (rowInfo.data.state == 9) {      
      stateClass = "ac-grid-cell-opened-item";
      stateVal = "Aperta";
    } else if (rowInfo.data.state == 3) {      
      stateClass = "ac-grid-cell-closed-item";
      stateVal = "Chiusa";
    } else if (rowInfo.data.state == 7) {      
      stateClass = "ac-grid-cell-suspended-item";
      stateVal = "Sospesa";
    } else if (rowInfo.data.state == 5) {      
      stateClass = "ac-grid-cell-closedIndefinitely-item";
      stateVal = "Chiusa";
    } 

    return (
      <span class={stateClass}>{stateVal}</span>
      );
  }

  showLoadPanel() {
    this.setState({
      isLoadPanelVisible: true
    });
  }

  hideLoadPanel() {
    this.setState({
      isLoadPanelVisible: false
    });
  }

  render() {    
    return (
      <React.Fragment>
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          visible={this.state.isLoadPanelVisible}
          showIndicator={true}
          shading={true}
          showPane={true}
          closeOnOutsideClick={false}
        />

        <div className={'content-block'}>
          
          <div className={'stakeholders-filter-container dx-card wide-card'}>
            <div className="stakeholders-filter-form-container">
              <form action="#" className={'stakeholders-filter-form'} onSubmit={ e => {e.preventDefault(); this.onFormSubmit(this);}}>
                <Form colCount={5} id="form2" formData={this.state.gridFilter} ref={(ref) => this.filterForm = ref} >                  
                  <GroupItem colSpan={5} colCount={4} caption="Ricerca anagrafica per:" >
                    <SimpleItem dataField="employerLastName" editorType="dxTextBox" editorOptions={{maxLength: 25}}  >
                      <Label text="Cognome datore" />
                    </SimpleItem>
                    <SimpleItem dataField="employeeLastNameFirstLetterFrom" editorType="dxTextBox" editorOptions={{maxLength: 10}} >
                      <Label text="Iniziale cognome datore dal" />
                    </SimpleItem>
                    <SimpleItem dataField="employeeLastNameFirstLetterTo" editorType="dxTextBox" editorOptions={{maxLength: 10}} >
                      <Label text="Iniziale cognome datore al" />
                    </SimpleItem>
                    <SimpleItem dataField="employerFiscalCode" editorType="dxTextBox" editorOptions={{maxLength: 25}}  >
                      <Label text="Codice fiscale datore" />
                    </SimpleItem>
                  </GroupItem>
                  <GroupItem colSpan={5} colCount={6} >
                    <SimpleItem dataField="takingChargeDateFrom" editorType="dxDateBox" editorOptions={{maxLength: 25}} >
                      <Label text="Data presa in carico dal" />
                    </SimpleItem>
                    <SimpleItem dataField="takingChargeDateTo" editorType="dxDateBox" editorOptions={{maxLength: 25}} >
                      <Label text="Data presa in carico al" />
                    </SimpleItem>     
                    <SimpleItem dataField="monthFrom" editorType="dxDateBox" editorOptions={{maxLength: 25, displayFormat: 'monthAndYear', format: "date",  maxZoomLevel: 'year', minZoomLevel: 'century'}} >
                      <Label text="Mese dal" />
                    </SimpleItem>
                    <SimpleItem dataField="monthTo" editorType="dxDateBox" editorOptions={{maxLength: 25, displayFormat: 'monthAndYear', format: "date",  maxZoomLevel: 'year', minZoomLevel: 'century'}} >
                      <Label text="Mese al" />
                    </SimpleItem>             
                    <SimpleItem dataField="employeeWithTransformations" editorType="dxCheckBox" >
                      <Label text="Solo dip. con trasformazioni" />
                    </SimpleItem>
                    <SimpleItem dataField="employeeExcludeDateEnd" editorType="dxCheckBox" >
                      <Label text="Escludi dip. con data cess. precedente all'anno selezionato" />
                    </SimpleItem>
                  </GroupItem>             
                  
                  <GroupItem colSpan={5} colCount={5} caption="Campi ON:" >                  
                    <SimpleItem dataField="inpsDelegationOn" editorType="dxCheckBox" >
                      <Label text="Delega inps" />
                    </SimpleItem>
                    <SimpleItem dataField="inpsCommunicationOn" editorType="dxCheckBox" >
                      <Label text="Comunicaz. inps" />
                    </SimpleItem>
                    <SimpleItem dataField="transformationLetterOn" editorType="dxCheckBox" >
                      <Label text="Lettera trasform." />
                    </SimpleItem>
                    <SimpleItem dataField="onlineVariationOn" editorType="dxCheckBox" >
                      <Label text="Variazione online" />
                    </SimpleItem>
                    <SimpleItem dataField="sentOn" editorType="dxCheckBox" >
                      <Label text="Inviato" />
                    </SimpleItem>
                  </GroupItem>

                  <GroupItem colSpan={5} colCount={5} caption="Campi OFF:" >                  
                    <SimpleItem dataField="inpsDelegationOff" editorType="dxCheckBox" >
                      <Label text="Delega inps" />
                    </SimpleItem>
                    <SimpleItem dataField="inpsCommunicationOff" editorType="dxCheckBox" >
                      <Label text="Comunicaz. inps" />
                    </SimpleItem>
                    <SimpleItem dataField="transformationLetterOff" editorType="dxCheckBox" >
                      <Label text="Lettera trasform." />
                    </SimpleItem>
                    <SimpleItem dataField="onlineVariationOff" editorType="dxCheckBox" >
                      <Label text="Variazione online" />
                    </SimpleItem>
                    <SimpleItem dataField="sentOff" editorType="dxCheckBox" >
                      <Label text="Inviato" />
                    </SimpleItem>
                  </GroupItem>
                </Form>

                <div>
                  <div className={'ac-float-letf'}> 
                    <Button className="ac-button-default" width={120} text="Cerca" type="default" stylingMode="contained" useSubmitBehavior={true} />
                    <span> </span>
                    <Button width={155} text="Cancella filtri" type="default" stylingMode="outlined" onClick={this.onResetButtonClick} />
                  </div>    
                  <div className={'ac-float-right'}>
                    <Button className="ac-button-default" width={125} text="Genera PDF" type="default" stylingMode="contained" onClick={this.onExportTransformationsToPDFButtonClick} />
                  </div>            
                </div>
              </form>
            </div>
          </div>

          <div id="transformations-grid">
            <DataGrid 
              id="gridContainer"
              keyExpr="Id"
              ref={(ref) => this.dataGrid = ref}
              className={'dx-card wide-card'} 
              dataSource={this.state.transformationsDataSource} 
              remoteOperations={true}
              showBorders={true} 
              focusedRowEnabled={true} 
              defaultFocusedRowIndex={0} 
              columnAutoWidth={true}             
              allowColumnResizing={true} 
              columnHidingEnabled={false}
              onSelectionChanged={this.onTransformationsTableSelectionChanged}
              onRowPrepared={this.onTransformationsTableRowPrepared} 
            >
              <Selection mode="single" />
              <Paging defaultPageSize={15} />
              <Pager showPageSizeSelector={true} showInfo={true} />            
              <FilterRow visible={false} />
              <HeaderFilter visible={false} />
              <Editing mode="popup" allowUpdating={false} allowDeleting={false} allowAdding={false} />
              <Sorting mode="multiple" />
                        
              <Column dataField={'id'}  visible={false} />
              <Column dataField={'transformationId'}  visible={false} />
              <Column dataField={'employerId'}  visible={false} />
              <Column dataField={'employeeId'}  visible={false} />

              <Column dataField={'employerLastName'} caption={'Cognome'}  defaultSortIndex={2} defaultSortOrder="asc" />
              <Column dataField={'employerFirstName'} caption={'Nome'}  defaultSortIndex={3} defaultSortOrder="asc" />

              <Column dataField={'employeeRegistryNotes'} caption={'Codice socio'} width={70} alignment='left' />
              <Column dataField={'employerFiscalCode'} caption={'Codice fiscale'} />
              <Column dataField={'employeeFullName'} caption={'Dipendente'} />
              <Column dataField={'employeeInpsCode'} caption={'Codice inps'} />

              <Column dataField={'state'} caption={'Stato'} cellRender={this.transformationsTableStateCellRender} dataType={'string'} defaultSortIndex={0} defaultSortOrder="desc" width={80} />

              <Column dataField={'takingChargeDate'} caption={'Data presa in carico'} dataType={'date'} format={'dd/MM/yyyy'}  width={125} visible={false} />
              <Column dataField={'month'} caption={'Mese'} dataType={'date'} format={'MM/yyyy'} defaultSortIndex={1} defaultSortOrder="desc" width={75} />

              <Column dataField={'inpsDelegation'} caption={'Delega inps'} width={73} />
              <Column dataField={'inpsCommunication'} caption={'Comunicaz. inps'} width={73} />
              <Column dataField={'transformationLetter'} caption={'Lettera trasform.'} width={73} />
              <Column dataField={'onlineVariation'} caption={'Variazione online'} width={73} />
              <Column dataField={'sent'} caption={'Inviato'} width={73} />

              <MasterDetail
                enabled={true}
                component={ArchivedTransformationsDetailTemplate}
              />
            </DataGrid>
          </div> 
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(TransformationsGrid);