import React from 'react';
import { DataGrid, Column, Pager, Paging, FilterRow, Lookup, Editing,  Popup, Position, Form} from 'devextreme-react/data-grid';
import { Item } from 'devextreme-react/form';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { ImportUploadPopup } from './import-upload-popup.js';

import * as Global from '../../global'
import './import-grid.scss';

export class ImportGrid extends React.Component {
  constructor(props) {
    super(props);

    if (!this.props.authProvider)
      Global.Logout();

    const url = Global.WebApiRootUrl;

    this.state = {
      citiesDataSource: createStore({
        key: 'cityId',
        loadUrl: `${url}/Cities`,
        insertUrl: `${url}/Cities/InsertCity`,
        updateUrl: `${url}/Cities/UpdateCity`,
        deleteUrl: `${url}/Cities/DeleteCity`,
        onBeforeSend: (method, ajaxOptions) => {
          ajaxOptions.xhrFields = { withCredentials: true };
          ajaxOptions.headers = { Authorization: `Bearer ${this.props.authProvider.user.token}`}
        },
        onAjaxError: ({ xhr, error}) => {
          this.props.authProvider.checkAuth(xhr);          
        }
      }),
      validationRules: {
        cityName: [ { type: 'required', message: 'Il nome della città obbligatorio' }, ]
      },
      //loggedUser: this.props.loggedUser,
      authProvider: this.props.authProvider,
    };

    this.importUploadPopup = React.createRef();

    this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
    this.isImportVisible = this.isImportVisible.bind(this);
  }

  componentDidMount() {
    if (!this.props.authProvider)
      Global.Logout();
  }

  onImportClick = (e) => {    
    this.importUploadPopup.showPopup(e.row.data)
  };

  onToolbarPreparing(e) {    
    var toolbarOptions = e.toolbarOptions.items;          
    var addRowButton = toolbarOptions.filter(item => item.name === 'addRowButton')[0];        
    if (addRowButton && addRowButton.options) {
      addRowButton.options.hint = "Aggiungi città";
      addRowButton.options.text = "Aggiungi città";
    }      
  }

  isImportVisible(data) {
    //console.log("isImportVisible");
    //console.log("isImportVisible userCityId", this.state.loggedUser.cityId);
    //console.log("isImportVisible cityIsMain", this.state.loggedUser.cityIsMain);
    //console.log("isImportVisible current cityID", data.row.key);
    
    if(!this.state.authProvider.user)
      return false;

    if(!data || !data.row || !data.row.key)
      return false;

    if(this.state.authProvider.user.cityIsMain)
      return true;
    
    if(data.row.key == this.state.authProvider.user.cityId)
      return true;
  }

  render() {    
    
    return (
      <div id="import-grid">             
        <DataGrid 
          className={'dx-card wide-card'} 
          dataSource={this.state.citiesDataSource} 
          showBorders={false} focusedRowEnabled={true} 
          defaultFocusedRowIndex={0} 
          columnAutoWidth={true} 
          columnHidingEnabled={true}
          onToolbarPreparing={this.onToolbarPreparing}
          >

          <Paging defaultPageSize={10} />
          <Pager showPageSizeSelector={true} showInfo={true} />        
          <Editing mode="popup" allowUpdating={this.state.authProvider.user.cityIsMain} allowDeleting={this.state.authProvider.user.cityIsMain} allowAdding={this.state.authProvider.user.cityIsMain}>
            <Popup title="Inserimento/modifica città" showTitle={true} width={400} height={300}>
              <Position my="center" at="center" of={window} />
            </Popup>
            <Form>
              <Item itemType="group" colCount={1} colSpan={2}>
                <Item dataField="name" editorOptions={{ maxLength: 50 }} validationRules={this.state.validationRules.cityName} />
              </Item>            
            </Form>
          </Editing>
          
          <Column dataField={'cityId'} hidingPriority={1} visible={false} />
          <Column caption={'Importa'} type="buttons" 
            buttons={[ {
              hint: 'Importa',
              icon: 'increaseindent',
              visible: this.isImportVisible,
              onClick: this.onImportClick
            }]} />          
          <Column dataField={'name'} caption={'Città'}  hidingPriority={5} />                
          <Column type="buttons" 
            buttons={[ 'edit', 'delete' ]} />
        </DataGrid>
        <ImportUploadPopup onRef={ref => (this.importUploadPopup = ref)}></ImportUploadPopup>
      </div>      
    );
  }
}

export default ImportGrid;