import React from 'react';
import { withRouter } from 'react-router-dom'

import { DataGrid, Sorting, Column, Pager, Paging, FilterRow, HeaderFilter, Selection} from 'devextreme-react/data-grid';
import { createStore } from 'devextreme-aspnet-data-nojquery';

import * as DateConversion from '../../helper/dataConversion'
import * as Global from '../../global'

class ArchivedExpirationsDetailTemplate extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      closedExpirationsDataSource: this.createClosedExpirationsDataSource(this.props.data.key),
      loggedUser: Global.GetLoggedUser(),
    };

    this.onExpirationsTableSelectionChanged = this.onExpirationsTableSelectionChanged.bind(this);
  }

  createClosedExpirationsDataSource(employeeId) {
    var store = createStore({
      key: 'employeeId',
      loadUrl: Global.WebApiRootUrl + "/expirations/employeeClosedExpirations/" + employeeId,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = { withCredentials: true };
        ajaxOptions.headers = { Authorization: `Bearer ${this.state.loggedUser.token}`}
      },
      onAjaxError: ({ xhr, error}) => {
        Global.CheckResponseAuth(xhr);
      }
    });

    return store;
  }

  onDateTimeTableFormat(cellInfo) {
    //console.log(cellInfo);
    return DateConversion.GetDateFromISOString(cellInfo, false);
  }

  onExpirationsTableSelectionChanged(e) {
    console.log("selectionChanged", e);
    var employerId = e.currentSelectedRowKeys[0];
    var expirationVersionId = e.selectedRowsData[0].lastClosedExpirationVersionId;
    console.log("selectionChanged", employerId, expirationVersionId);
    this.props.history.push({pathname: '/expirations/' + employerId.toLowerCase() + /archived/ + expirationVersionId.toLowerCase(), key: Math.floor(Math.random() * 100001), state: { buildBreadcrumbEmployeeLink: false }});
  }

  expirationsTableStateCellRender(rowInfo) {
    //console.log("expirationsTableStateCellRender", rowInfo)
    var stateClass = "";
    if (rowInfo.data.state.toLowerCase() == "aperta")
      stateClass = "ac-grid-cell-opened-item";
    else if (rowInfo.data.state.toLowerCase() == "chiusa") 
      stateClass = "ac-grid-cell-closed-item";

    return (
      <span class={stateClass}>{rowInfo.data.state}</span>
      );
  }

  render() {
    return (
      <React.Fragment>
        <div className="master-detail-caption">
          Cessazioni chiuse
        </div>
        
        <div id="archived-expirations-grid">
          <DataGrid 
            id="gridContainer"
            keyExpr="Id"
            ref={(ref) => this.dataGrid = ref}
            className={'dx-card wide-card'} 
            dataSource={this.state.closedExpirationsDataSource} 
            remoteOperations={true}
            showBorders={true} 
            focusedRowEnabled={false} 
            defaultFocusedRowIndex={0} 
            columnAutoWidth={true} 
            columnHidingEnabled={true}
            onSelectionChanged={this.onExpirationsTableSelectionChanged}
          >
            <Selection mode="single" />
            <Paging defaultPageSize={15} />
            <Pager showPageSizeSelector={true} showInfo={true} />            
            <FilterRow visible={false} />
            <HeaderFilter visible={false} />
            <Sorting mode="multiple" />
                      
            <Column dataField={'id'} hidingPriority={1} visible={false} />
            <Column dataField={'expirationId'} hidingPriority={1} visible={false} />
            <Column dataField={'employeeId'} hidingPriority={1} visible={false} />
            <Column dataField={'lastClosedExpirationVersionId'} hidingPriority={1} visible={false} />

            <Column dataField={'takingChargeDate'} caption={'Data presa in carico'} dataType={'date'} format={'dd/MM/yyyy'}  hidingPriority={7} visible={false} />
            <Column dataField={'expirationDate'} caption={'Data cesszione'} dataType={'date'} format={'dd/MM/yyyy'} />

            <Column dataField={'inpsDelegation'} caption={'Delega inps'} hidingPriority={7} />
            <Column dataField={'inpsCommunication'} caption={'Comunicaz. inps'} hidingPriority={7} />
            <Column dataField={'letterSent'} caption={'Lettera inviata/ricevuta'} hidingPriority={7} />
            <Column dataField={'pagopa'} caption={'Pagopa'} hidingPriority={7} />
            <Column dataField={'onlineVariation'} caption={'Variazione online'} hidingPriority={7} />
            <Column dataField={'sendingDocuments'} caption={'Inviato'} hidingPriority={7} />

            <Column dataField={'state'} caption={'Stato'} hidingPriority={8} cellRender={this.expirationsTableStateCellRender} />
            
            <Column dataField={'closedAt'} caption={'Data chiusura'} dataType={'date'}  hidingPriority={2} defaultSortIndex={0} defaultSortOrder="desc" format={this.onDateTimeTableFormat} />
            <Column dataField={'closedByUserFullName'} caption={'Chiusa da'} hidingPriority={3} />
          </DataGrid>
        </div>
        
      </React.Fragment>
    );
  }  
}

export default withRouter(ArchivedExpirationsDetailTemplate);