import React from 'react';

import Box, { Item } from 'devextreme-react/box';
import TextArea from 'devextreme-react/text-area';

import * as Global from '../../global'

import './employee-details.scss';
 
export class EmployeeDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {       
      employee: {},
      loggedUser: Global.GetLoggedUser(),
      lightView: false, /* 23/05/2021 aggiunto per differenziare una vista con tutte le informazioni o senza, il default è tutte le informazioni, ovvero lightView = false */
    };

    this.onTextAreaValueChanged = this.onTextAreaValueChanged.bind(this);
    this.onTextAreaKeyDown = this.onTextAreaKeyDown.bind(this);
    this.getEmployeeData = this.getEmployeeData.bind(this);
    this.updateUserNotes = this.updateUserNotes.bind(this);
    
  }

  componentDidMount() {
    if(this.props.employeeId) {
      this.getEmployeeData();
    }

    if(this.props.lightView) {
      this.setState({lightView: this.props.lightView}); 
    }  
  }

  getEmployeeData() {
    var url =  Global.WebApiRootUrl + "/Employees/" + this.props.employeeId;

    const requestOptions = {
      method: 'GET',
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `Bearer ${this.state.loggedUser.token}`,
      }
    };
            
    fetch(url, requestOptions)
      .then(response => {
        if(response.ok) return response.json();
        throw new Error('Request failed.');
      })
      .then(data => {          
        this.setState({
          employee: data
        });        
      })
      .catch(error => {
        console.log(error);
      });
  }

  onTextAreaKeyDown(e) {        
    if (e.event.key === 'Enter' && !e.event.shiftKey) {      
      e.event.target.blur();
      return false;
    }
  }

  onTextAreaValueChanged(e) {      
      this.updateUserNotes(e.value);      
  }

  updateUserNotes(notes) {
    var data = this.state.employee;
    data.userNotes = notes;
    this.setState({
      employee: data
    });

    var url =  Global.WebApiRootUrl + "/Employees/UpdateUserNotes";

    var formData = new FormData();

    const payload = {
      employeeId: this.props.employeeId,
      userNotes: this.state.employee.userNotes
    };
    formData.append("values", JSON.stringify(payload));
        
    // POST request using fetch with error handling
    const requestOptions = {
      method: 'POST',      
      body: formData,
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `Bearer ${this.state.loggedUser.token}`,
      }
    };

    fetch(url, requestOptions)
      .then(async response => {
          /*
          const data = await response.json();
          // check for error response
          if (!response.ok) {
              // get error message from body or default to response status
              const error = (data && data.message) || response.status;
              return Promise.reject(error);
          }
          //this.setState({ postId: data.id })
          */
      })
      .catch(error => {
          //this.setState({ errorMessage: error.toString() });
          console.error('There was an error!', error);
      });
  }

  renderItemText(itemData) {
    return (
      <span>{(itemData && itemData != '') ? (itemData) : ('-')}</span>
    );
  }

  renderItemDate(itemData) {
    return (
      <span>{(itemData && itemData != '0001-01-01T00:00:00') ? (new Intl.DateTimeFormat("it-IT").format(new Date(itemData))) : ('-')}</span>
    );
  }

  renderPatronSaintDate(itemData) {
    //0000-12-09
    var formattedDate = "";
    if (itemData && itemData != '') {
      var year = new Date().getFullYear();
      var month = itemData.substring(5, 7);
      var day = itemData.substring(9, 11);
      formattedDate = day + "/" + month + "/" + year;
    }
    
    return (
      <span>{formattedDate}</span>
    );
  }

  renderView(){
    if(this.state.lightView)
      return this.renderLigthView();
    else
      return this.renderFullView();
  }

  renderLigthView(){    
    return  <div>
              <Box direction="row" width="100%">
                <Item ratio={1}>                  
                  <div className="dx-field">
                    <div className="dx-field-label">Cognome e nome:</div>
                    <div className="dx-field-value-static">{this.state.employee.lastName} {this.state.employee.firstName}</div>
                  </div>
                </Item>
                </Box> 
                <Box direction="row" width="100%">
                <Item ratio={1}>
                  <div className="dx-field">
                    <div className="dx-field-label">Codice fiscale:</div>
                    <div className="dx-field-value-static">{this.renderItemText(this.state.employee.fiscalCode)}</div>
                  </div>
                </Item>   
                </Box>    
                <Box direction="row" width="100%">
                <Item ratio={1}>
                  <div className="dx-field">
                    <div className="dx-field-label">Codice INPS:</div>
                    <div className="dx-field-value-static">{this.renderItemText(this.state.employee.inpsCode)}</div>
                  </div>
                </Item>
                </Box>
                <Box direction="row" width="100%">
                <Item ratio={1}>
                  <div className="dx-field">
                    <div className="dx-field-label">Codice socio:</div>
                    <div className="dx-field-value-static">{this.renderItemText(this.state.employee.registryNotes)}</div>
                  </div>
                </Item>        
              </Box>          
            </div>
  }

  renderFullView(){    
    return  <div>
              <Box direction="row" width="100%">
                <Item ratio={1}>                  
                  <div className="dx-field">
                    <div className="dx-field-label">Cognome e nome:</div>
                    <div className="dx-field-value-static">{this.state.employee.lastName} {this.state.employee.firstName}</div>
                  </div>
                </Item>
                <Item ratio={1}>
                  <div className="dx-field">
                    <div className="dx-field-label">Codice fiscale:</div>
                    <div className="dx-field-value-static">{this.renderItemText(this.state.employee.fiscalCode)}</div>
                  </div>
                </Item>       
                <Item ratio={1}>
                  <div className="dx-field">
                    <div className="dx-field-label">Codice INPS:</div>
                    <div className="dx-field-value-static">{this.renderItemText(this.state.employee.inpsCode)}</div>
                  </div>
                </Item>
                <Item ratio={1}>
                  <div className="dx-field">
                    <div className="dx-field-label"> </div>
                    <div className="dx-field-value-static"> </div>
                  </div></Item>         
              </Box>          
              <Box direction="row" width="100%">
                <Item ratio={1}>                  
                  <div className="dx-field">
                    <div className="dx-field-label">Assunzione:</div>
                    <div className="dx-field-value-static">{this.renderItemDate(this.state.employee.recruitmentDate)}</div>
                  </div>
                </Item>
                <Item ratio={1}>
                  <div className="dx-field">
                    <div className="dx-field-label">Cessazione:</div>
                    <div className="dx-field-value-static">{this.renderItemDate(this.state.employee.endDate)}</div>
                  </div>
                </Item>
                <Item ratio={1}>
                  <div className="dx-field">
                    <div className="dx-field-label">Categoria contratto:</div>
                    <div className="dx-field-value-static">{this.renderItemText(this.state.employee.contractCategory)}</div>
                  </div>
                </Item>    
                <Item ratio={1}>
                  <div className="dx-field">
                    <div className="dx-field-label">Tipo contratto:</div>
                    <div className="dx-field-value-static">{this.renderItemText(this.state.employee.contractType)}</div>
                  </div>
                </Item>                
              </Box>          
              <Box direction="row" width="100%">
                <Item ratio={3}>                  
                  <div className="dx-field">
                    <div className="dx-field-label">Mansione:</div>
                    <div className="dx-field-value-static">{this.renderItemText(this.state.employee.task)}</div>
                  </div>
                </Item>
                <Item ratio={1}>
                  <div className="dx-field">
                    <div className="dx-field-label">Livello:</div>
                    <div className="dx-field-value-static">{this.renderItemText(this.state.employee.level)}</div>
                  </div>
                </Item>         
              </Box>          
              <Box direction="row" width="100%">
                <Item ratio={1}>                  
                  <div className="dx-field">
                    <div className="dx-field-label">Numero scatti:</div>
                    <div className="dx-field-value-static">{this.renderItemText(this.state.employee.stepsNumber)}</div>
                  </div>
                </Item>
                <Item ratio={1}>
                  <div className="dx-field">
                    <div className="dx-field-label">Ultimo scatto:</div>
                    <div className="dx-field-value-static">{this.renderItemDate(this.state.employee.lastStepDate)}</div>
                  </div>
                </Item>
                <Item ratio={1}>
                  <div className="dx-field">
                    <div className="dx-field-label">Prossimo scatto:</div>
                    <div className="dx-field-value-static">{this.renderItemDate(this.state.employee.nextStepDate)}</div>
                  </div>
                </Item>
                <Item ratio={1}>
                  <div className="dx-field">
                    <div className="dx-field-label">Mese erogaz. TFR:</div>
                    <div className="dx-field-value-static">{this.renderItemText(this.state.employee.tfrMonthPayment)}</div>
                  </div>
                </Item>
              </Box>          
              <Box direction="row" width="100%">
                <Item ratio={1}>
                  <div className="dx-field">
                    <div className="dx-field-label">Data santo patrono:</div>
                    <div className="dx-field-value-static">{this.renderPatronSaintDate(this.state.employee.patronSaintDate)}</div>
                  </div>
                </Item>
                <Item ratio={1}>
                  <div className="dx-field">
                    <div className="dx-field-label">Giorno di riposo:</div>
                    <div className="dx-field-value-static">{this.renderItemText(this.state.employee.dayOff)}</div>
                  </div>
                </Item>
                <Item ratio={2}>
                  <div className="dx-field">
                    <div className="dx-field-label">Mezza giornata di riposo:</div>
                    <div className="dx-field-value-static">{this.renderItemText(this.state.employee.halfDayOff)}</div>
                  </div>
                </Item>         
              </Box>          
              <Box direction="row" width="100%">
                <Item ratio={4}>
                  <div className="dx-field">
                    <div className="dx-field-label">Annotazioni dell'anagrafica Soggetti:</div>
                    <div className="dx-field-value-static">{this.renderItemText(this.state.employee.registryNotes)}</div>
                  </div>
                </Item>
              </Box>          
              <Box direction="row" width="100%">
                <Item ratio={4}>
                  <div className="dx-field">
                    <div className="dx-field-label">Annotazioni dell'anagrafica Contratti:</div>
                    <div className="dx-field-value-static">{this.renderItemText(this.state.employee.employeeNotes)}</div>
                  </div>
                </Item>
              </Box>          
              <Box direction="row" width="100%">
                <Item ratio={4}>
                  <div className="dx-field employee-notes-textarea">
                    <div className="dx-field-label">Note dipendente:</div>
                    <div className="dx-field-value-static">
                      <div className="ac-textarea">
                        <TextArea
                          height={46}
                          maxLength={256}
                          value={this.state.employee.userNotes}
                          onValueChanged={this.onTextAreaValueChanged} 
                          onKeyDown={this.onTextAreaKeyDown} />
                      </div>
                    </div>
                  </div>
                </Item>
              </Box>
            </div>
  }

  render() {      
    return (
      <div className={'employee-details-content-block'}>
        <div className="dx-fieldset employee-group">
          <div className="ac-fieldset-header">{this.props.title}</div>
          {this.renderView()}
        </div>
      </div>
    );
  }
}

export default EmployeeDetails;