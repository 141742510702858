import React from 'react';
import { DataGrid, Column, Pager, Paging, FilterRow, Lookup, Editing,  Popup, Position, Form} from 'devextreme-react/data-grid';
import { Item } from 'devextreme-react/form';
import { createStore } from 'devextreme-aspnet-data-nojquery';

import * as Global from '../../global'
import './users-grid.scss';

export class UsersGrid extends React.Component {
  constructor(props) {
    super(props);

    if (!this.props.authProvider)
      Global.Logout();

    const url = Global.WebApiRootUrl;

    this.state = {
      usersDataSource: createStore({
        key: 'userId',
        loadUrl: `${url}/Users`,
        insertUrl: `${url}/Users/InsertUser`,
        updateUrl: `${url}/Users/UpdateUser`,
        deleteUrl: `${url}/Users/DeleteUser`,
        onBeforeSend: (method, ajaxOptions) => {          
          ajaxOptions.xhrFields = { withCredentials: true };
          ajaxOptions.headers = { Authorization: `Bearer ${this.props.authProvider.user.token}`}
        },
        onAjaxError: ({ xhr, error}) => {
          this.props.authProvider.checkAuth(xhr);          
        }
      }),
      citiesDataSource: this.createCitiesDataSource(this.props.authProvider.user),
      rolesDataSource: createStore({
        key: 'roleId',
        loadUrl: `${url}/Roles`,
        onBeforeSend: (method, ajaxOptions) => {
          ajaxOptions.xhrFields = { withCredentials: true };
          ajaxOptions.headers = { Authorization: `Bearer ${this.props.authProvider.user.token}`}
        },
        onAjaxError: ({ xhr, error}) => {
          this.props.authProvider.checkAuth(xhr);          
        }
      }),
      validationRules: {
        userFirstName: [ { type: 'required', message: 'Il nome è obbligatorio' }, ],
        userLastName: [ { type: 'required', message: 'Il cognome è obbligatorio' }, ],
        userEmail: [ { type: 'required', message: 'L\'email è obbligatoria' } ],
        userPassword: [ { type: 'required', message: 'La password è obbligatoria' } ],
        userCity: [ { type: 'required', message: 'La città è obbligatoria' } ],
        userRole: [ { type: 'required', message: 'Il ruolo è obbligatorio' } ]
      },      
      authProvider: this.props.authProvider,
      citiesEditorOption: null
    };

    this.onToolbarPreparing = this.onToolbarPreparing.bind(this);    
    this.isEditEnabled = this.isEditEnabled.bind(this); 
    this.getCitiesEditorOptions = this.getCitiesEditorOptions.bind(this);
    this.createCitiesDataSource = this.createCitiesDataSource.bind(this);
  }

  componentDidMount() {
    if (!this.props.authProvider)
      Global.Logout();
  }

  createCitiesDataSource(loggedUser) {    
    return createStore({
      key: 'cityId',
      loadUrl: Global.WebApiRootUrl + '/Cities',
      onBeforeSend: (method, ajaxOptions) => {
        if (loggedUser && !loggedUser.cityIsMain && loggedUser.cityId) {
          if (method == "load" ) {            
            var payload = ajaxOptions.data;
            payload.values = JSON.stringify({ cityId: loggedUser.cityId });
            ajaxOptions.data = payload;            
          }
        }

        ajaxOptions.xhrFields = { withCredentials: true };
        ajaxOptions.headers = { Authorization: `Bearer ${this.props.authProvider.user.token}`}
      },
      onAjaxError: ({ xhr, error}) => {
        this.props.authProvider.checkAuth(xhr);          
      }
    });
  }

  getCitiesEditorOptions(loggedUser) {
    if ((loggedUser && loggedUser.cityIsMain) || (!loggedUser)) {
      //user of main city or not user
      return { items: this.state.citiesDataSource, searchEnabled: true};
    } else {
      //user of non main city, so it can't change city
      return { items: this.state.citiesDataSource, searchEnabled: true};
    }

    /*
    if(loggedUser) {
      if(loggedUser.cityIsMain) {
        //user of main city
        return { items: this.state.citiesDataSource, searchEnabled: true};
      } else {
        //user of non main city, so it can't change city
        return { items: this.state.citiesDataSource, searchEnabled: true, value: loggedUser.cityName, readOnly: true};
      }
    } else {
      //no user
      return { items: this.state.citiesDataSource, searchEnabled: true};
    }
    */
  }

  onToolbarPreparing(e) {    
    var toolbarOptions = e.toolbarOptions.items;          
    var addRowButton = toolbarOptions.filter(item => item.name === 'addRowButton')[0];        
    if (addRowButton && addRowButton.options) {
      addRowButton.options.hint = "Aggiungi utente";
      addRowButton.options.text = "Aggiungi utente";
    }      
  }

  isEditEnabled(record) {    
    if(!this.state.authProvider.user)
      return false;

    if(!record || !record.row || !record.row.key || !record.row.data.user_CityId)
      return false;

    if(this.state.authProvider.user.cityIsMain)
      return true;
    
    if(record.row.data.user_CityId == this.state.authProvider.user.cityId)
      return true;
  }

  render() {    
    return (
      <div id="users-grid">
        <DataGrid 
          className={'dx-card wide-card'} 
          dataSource={this.state.usersDataSource} 
          showBorders={false} 
          focusedRowEnabled={true} 
          defaultFocusedRowIndex={0} 
          columnAutoWidth={true} 
          columnHidingEnabled={true}
          onToolbarPreparing={this.onToolbarPreparing}
          >

          <Paging defaultPageSize={15} />
          <Pager showPageSizeSelector={true} showInfo={true} />
          <FilterRow visible={true} />
          <Editing mode="popup" allowUpdating={this.isEditEnabled} allowDeleting={this.isEditEnabled} allowAdding={true}>
            <Popup title="Inserimento/modifica utente" showTitle={true} width={600} height={550}>
              <Position my="center" at="center" of={window} />
            </Popup>
            <Form>
              <Item itemType="group" colCount={2} colSpan={2}>
              <Item dataField="lastName" editorOptions={{ maxLength: 50 }} validationRules={this.state.validationRules.userLastName} />
                <Item dataField="firstName" editorOptions={{ maxLength: 50 }} validationRules={this.state.validationRules.userFirstName} />                
              </Item>
              <Item itemType="group" colCount={2} colSpan={2}>
                <Item dataField="user_City" editorType="dxSelectBox" editorOptions={this.getCitiesEditorOptions(this.props.authProvider.user)} validationRules={this.state.validationRules.userCity}  />
                <Item dataField="email" editorOptions={{ mode: 'email', maxLength: 100 }} validationRules={this.state.validationRules.userEmail} />            
                <Item dataField="user_Role" editorType="dxSelectBox" editorOptions={{ items: this.state.citiesDataSource, searchEnabled: true }} validationRules={this.state.validationRules.userRole} />
                <Item dataField="password" editorOptions={{ mode: 'password', maxLength: 100 }} validationRules={this.state.validationRules.userPassword} />
              </Item>
              <Item itemType="group" colCount={2} colSpan={2}>
                <Item dataField="createdAt" editorOptions={{ disabled: true }} />
                <Item dataField="updatedAt" editorOptions={{ disabled: true }} />
              </Item>
            </Form>
          </Editing>
          
          <Column dataField={'userId'} hidingPriority={1} visible={false} />
          <Column dataField={'lastName'} caption={'Cognome'} hidingPriority={6} />
          <Column dataField={'firstName'} caption={'Nome'} hidingPriority={5} />          
          <Column dataField={'user_City'} caption={'Città'} hidingPriority={4} >
            <Lookup dataSource={this.state.citiesDataSource} valueExpr={'name'} displayExpr={'name'} />
          </Column>
          <Column dataField={'user_CityId'} caption={'Id città'} hidingPriority={4} visible={false} ></Column>
          <Column dataField={'email'} caption={'Email'} hidingPriority={3} />
          <Column dataField={'user_Role'} caption={'Ruolo'} hidingPriority={2} >
            <Lookup dataSource={this.state.rolesDataSource} valueExpr={'name'} displayExpr={'name'} />
          </Column>
          <Column dataField={'password'} caption={'Password'} hidingPriority={1} visible={false} />
          <Column dataField={'createdAt'} caption={'Data creazione'} hidingPriority={1} visible={false}  />
          <Column dataField={'updatedAt'} caption={'Data ultimo aggiornamento'} hidingPriority={1} visible={false} />
        </DataGrid> 
      </div>      
    );
  }
}

export default UsersGrid;