import React from 'react';

import CustomStore from 'devextreme/data/custom_store';
import { DataGrid, Column, Pager, Paging, FilterRow, Editing } from 'devextreme-react/data-grid';
import TextArea from 'devextreme-react/text-area';
import { Template } from 'devextreme-react/core/template';
import { formatDate } from 'devextreme/localization';
import { Button } from 'devextreme-react/button';

import * as Global from '../../global'

import './variation-month.scss';

export class VariationMonth extends React.Component {
  constructor(props) {
    super(props);

    if (!this.props.loggedUser)
        Global.Logout();

    const URL = Global.WebApiRootUrl + "/Employees/" + this.props.employeeId + "/Variations/" + this.props.year + "/" + this.props.month;

    this.state = {
      variationsData: new CustomStore({
        key: 'VariationRowId',
        load: () => this.sendVariationRowRequest(`${URL}`),
        insert: (values) => this.sendVariationRowRequest(`${URL}/InsertVariation`, 'POST', {
          values: JSON.stringify(values)
        }),
        update: (key, values) => this.sendVariationRowRequest(`${URL}/UpdateVariation`, 'PUT', {
          key: key,
          values: JSON.stringify(values)
        }),
        remove: (key) => this.sendVariationRowRequest(`${URL}/DeleteVariation`, 'DELETE', {
          key: key
        })
      }),
      variation: {},
      requests: [],
      refreshMode: 'reshape',
      lastUpdatedTdElement: null,
    };

    this.clearRequests = this.clearRequests.bind(this);
    this.handleRefreshModeChange = this.handleRefreshModeChange.bind(this);    

    this.onTextAreaValueChanged = this.onTextAreaValueChanged.bind(this);
    this.onTextAreaKeyDown = this.onTextAreaKeyDown.bind(this);

    this.toolbarItemRender = this.toolbarItemRender.bind(this);
    this.onToolbarPreparing = this.onToolbarPreparing.bind(this);

    this.onExportEmployeeToPDFButtonClick = this.onExportEmployeeToPDFButtonClick.bind(this);

    /*
    this.onRowPrepared = this.onRowPrepared.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onEditingStart = this.onEditingStart.bind(this);
    this.onRowUpdated = this.onRowUpdated.bind(this);
    this.onEditorPreparing = this.onEditorPreparing.bind(this);
    this.onContentReady = this.onContentReady.bind(this);
    */
    this.onCellPrepared = this.onCellPrepared.bind(this);

    this.dataGrid = null;
  }

  componentDidMount() {
    if (!this.props.loggedUser)
      Global.Logout();

    this.getVariation();
  }

  getVariation() {
    const URL = Global.WebApiRootUrl + "/Employees/" + this.props.employeeId + "/Variation/" + this.props.year + "/" + this.props.month;
    
    const localLoggedUser = Global.GetLoggedUser();

    const requestOptions = {
      method: 'GET',
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `Bearer ${localLoggedUser.token}`,
      }
    };

    fetch(URL, requestOptions)      
      .then(response => {          
        if(response.ok) 
          return response.json();
          
        Global.CheckResponseAuth(response);
        throw new Error('Request failed.');
      })
      .then(data => {        
        this.setState({ variation: data });
      });
  }
  
  sendVariationRowRequest(url, method, data) {
    const localLoggedUser = Global.GetLoggedUser();

    method = method || 'GET';
    data = data || {};

    this.logRequest(method, url, data);

    if(method === 'GET') {
      return fetch(url, {
        method: method,
        credentials: 'include',
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `Bearer ${localLoggedUser.token}`,
        }
      }).then(result => result.json().then(json => {
        if(result.ok) {          
          return json.data;
        }

        Global.CheckResponseAuth(result);
        throw json.Message;
      }));
    }

    const params = Object.keys(data).map((key) => {
      return `${encodeURIComponent(key) }=${ encodeURIComponent(data[key])}`;
    }).join('&');

    return fetch(url, {
      method: method,
      body: params,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        Authorization: `Bearer ${localLoggedUser.token}`,
      },
      credentials: 'include'
    }).then(result => {
      if(result.ok) {
        return result.text().then(text => text && JSON.parse(text));
      } else {
        Global.CheckResponseAuth(result);
        return result.json().then(json => {
          throw json.Message;
        });
      }
    });
  }

  logRequest(method, url, data) {
    const args = Object.keys(data || {}).map(function(key) {
      return `${key }=${ data[key]}`;
    }).join(' ');

    const time = formatDate(new Date(), 'HH:mm:ss');
    const request = [time, method, url.slice(URL.length), args].join(' ');

    this.setState((state) => {
      return { requests: [request].concat(state.requests) };
    });
  }

  clearRequests() {
    this.setState({ requests: [] });
  }

  handleRefreshModeChange(e) {
    this.setState({ refreshMode: e.value });
  }

  onTextAreaKeyDown(e) {        
    if (e.event.key === 'Enter' && !e.event.shiftKey) {      
      e.event.target.blur();
      return false;
    }
  }

  onTextAreaValueChanged(e) {
    this.updateVariationNotes(e.value);
    console.log(e);
    if(e && e.element) {      
      if(e.value)
        e.element.style.backgroundColor = '#daefcb';
      else
        e.element.style.backgroundColor = '#ffffff';
    }
  }

  updateVariationNotes(notes) {
    if(this && this.state.variation) {
      const localLoggedUser = Global.GetLoggedUser();

      var data = this.state.variation;
      data.notes = notes;
      this.setState({
        variation: data
      });

      const url =  Global.WebApiRootUrl + "/Employees/" + this.props.employeeId + "/UpdateVariationNotes/" + this.props.year + "/" + this.props.month;

      var formData = new FormData();
  
      const payload = {        
        variationNotes: this.state.variation.notes
      };
      formData.append("values", JSON.stringify(payload));
          
      // POST request using fetch with error handling
      const requestOptions = {
        method: 'POST',
        body: formData,
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",          
          Authorization: `Bearer ${localLoggedUser.token}`,
        },
      };
      fetch(url, requestOptions)
        .then(response => {          
          if(response.ok) 
            return response.json();
            
          Global.CheckResponseAuth(response);
          throw new Error('Request failed.');
        })
        .catch(error => {
            //this.setState({ errorMessage: error.toString() });
            console.error('There was an error!', error);
        });
    }
  }

  toolbarItemRender() {    
    return (
      <div className="variation-month-content-block-grid-toolbar">        
        <span className="variation-month-content-block-grid-toolbar-title">{this.props.title}</span>
      </div>
    );
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'before',
      template: 'totalGroupCount'
    });

    e.toolbarOptions.visible = false;  
  }

  onExportEmployeeToPDFButtonClick() {
    this.dataGrid.instance.addRow();
  }

  /*
  setCellUpdated(element) {
    if(element) {
      var className = element.className;
      className = className + " dx-cell-modified";
      element.className = className;
      console.log(element);
      console.log(element.className);
      return element;
    }

    return null;
  }

  onRowPrepared(e) {
    //console.log("onRowPrepared", e);
    //console.log("getVisibleRows", this.dataGrid.instance.getVisibleRows());

  }
  
  onEditorPreparing(e) {
    //console.log("onEditorPreparing", e);
    //console.log("getVisibleRows", this.dataGrid.instance.getVisibleRows());
  }

  onKeyDown(e) {
    var element = this.setCellUpdated(e.event.target.parentElement.parentElement.parentElement.parentElement.parentElement);    
    this.setState({ lastUpdatedTdElement: element });
    //alert("onKeyDown");
  }

  onRowUpdated(e) {
    return;

    var element = this.setCellUpdated(this.state.lastUpdatedTdElement);    
    this.setState({ lastUpdatedTdElement: element });
    //alert("onRowUpdated");

    setTimeout(function () {          
      var element = this.setCellUpdated(this.state.lastUpdatedTdElement);    
      this.setState({ lastUpdatedTdElement: null });
      alert("onRowUpdated setTimeot");
    }.bind(this), 1000);
  }

  onContentReady(e) {
    return;

    //console.log("onContentReady", e);
    var element = this.state.lastUpdatedTdElement;
    if(element) {
      //console.log("onRowUpdated", element);
      var className = element.className;
      className = "dx-cell-modified";
      element.className = className;
      this.setState({ lastUpdatedTdElement: null });
      console.log("onContentReady update cell", e);
      //alert("a");
    }    
  }

  onEditingStart(e) {
    //console.log("onEditingStart", e);    
  }
  */

  onCellPrepared(e) {
    //console.log("onCellPrepared", e);    
    if (e.value) {
      var element = e.cellElement;
      if(element) {    
        //console.log("onCellPrepared", element);
        //element.className = "ac-cell-modified";
        element.style.backgroundColor = '#daefcb';
      }   
    }
  }

  render() {
    
    return (
      <div className={'variation-month-content-block'}>     
        <div className={'variation-month-content-container'}>
          <div className={'variation-month-content-block-left'}>
            <div className="variation-month-content-block-grid-toolbar-title">{this.props.title}</div>
            <div>
              <Button icon="add" type="normal" stylingMode="outlined" onClick={this.onExportEmployeeToPDFButtonClick}  />
            </div>
          </div>
          <div className={'variation-month-content-block-right'}>
            <div>
              <DataGrid className={'dx-card_ wide-card_'} 
                ref={(ref) => this.dataGrid = ref}
                dataSource={this.state.variationsData} 
                showBorders={true} 
                focusedRowEnabled={false} 
                defaultFocusedRowIndex={0} 
                columnAutoWidth={true} 
                columnHidingEnabled={false}
                onToolbarPreparing={this.onToolbarPreparing}  

                //onRowPrepared={this.onRowPrepared}
                //onKeyDown={this.onKeyDown}
                //onEditingStart={this.onEditingStart}
                //onRowUpdated={this.onRowUpdated}
                //onEditorPreparing={this.onEditorPreparing}
                //onContentReady={this.onContentReady}
                onCellPrepared={this.onCellPrepared}
              >
                <Paging defaultPageSize={10} />
                <Pager showPageSizeSelector={true} showInfo={true} />
                <FilterRow visible={false} />
                <Editing mode="cell" allowUpdating={true} allowDeleting={true} allowAdding={true} />
                
                <Column dataField={'VariationId'} hidingPriority={20} visible={false} />
                <Column dataField={'VariationRowId'} hidingPriority={19} visible={false} />
                
                <Column caption="Ferie">
                  <Column dataField={'FER_DayFrom'} caption={'Dal'} hidingPriority={18} />
                  <Column dataField={'FER_DayTo'} caption={'Al'} hidingPriority={17} />                        
                  <Column dataField={'FER_Hours'} caption={'Gg'} hidingPriority={16} />
                </Column>

                <Column caption="Permesso non retribuito">
                  <Column dataField={'PER_DayFrom'} caption={'Dal'} hidingPriority={15} />
                  <Column dataField={'PER_DayTo'} caption={'Al'} hidingPriority={14} />                        
                  <Column dataField={'PER_Hours'} caption={'Ore'} hidingPriority={13} />                
                </Column>

                <Column caption="Maternità">
                  <Column dataField={'MAT_DayFrom'} caption={'Dal'} hidingPriority={12} />
                  <Column dataField={'MAT_DayTo'} caption={'Al'} hidingPriority={11} />
                </Column>

                <Column caption="Malattia">
                  <Column dataField={'MAL_DayFrom'} caption={'Dal'} hidingPriority={9} />
                  <Column dataField={'MAL_DayTo'} caption={'Al'} hidingPriority={8} />     
                </Column>

                <Column caption="Infortunio">
                  <Column dataField={'INF_DayFrom'} caption={'Dal'} hidingPriority={6} />
                  <Column dataField={'INF_DayTo'} caption={'Al'} hidingPriority={5} />         
                </Column>

                <Column caption="Straordinario">
                  <Column dataField={'STR_Hours'} caption={'Ore'} hidingPriority={3} />
                  <Column dataField={'STR_Percentage'} caption={'%'} hidingPriority={2} />                        
                  <Column dataField={'STR_Date'} caption={'Data'} hidingPriority={1} />
                </Column>

                <Template name="totalGroupCount" render={this.toolbarItemRender} />
                
              </DataGrid>
            </div>
            <div className="dx-field variation-month-notes-textarea">
              <div className="dx-field-label">Note mese:</div>
              <div className="dx-field-value-static">
                <div className="ac-textarea">
                  <TextArea
                    height={27}
                    maxLength={256}
                    value={this.state.variation.notes}
                    onValueChanged={this.onTextAreaValueChanged} 
                    onKeyDown={this.onTextAreaKeyDown} 
                    showClearButton={true}
                    style={{backgroundColor: (this.state.variation.notes) ? "#daefcb" : ""}}
                     />
                </div>
              </div>
            </div>
          </div>      
        </div>
      </div>
    );
  }
}

export default VariationMonth;