import React from 'react';
import { Link } from 'react-router-dom';
import './report-grid-row.scss';

export default function ReportDataGridRow(rowInfo) {

  var slug = rowInfo.data.lastName + '-' + rowInfo.data.firstName + '-' + rowInfo.data.fiscalCode;
  slug = slug.replace(/\s/g,'').toLowerCase();

  return (    
    <tr className="custom-row dx-row dx-data-row dx-row-lines">
        <td><Link to={{ pathname: rowInfo.data.path, search: slug, state: { stakeholder: rowInfo.data, from: 'report' } }} >{rowInfo.data.employerLastName}</Link></td>
        <td><Link to={{ pathname: rowInfo.data.path, search: slug, state: { stakeholder: rowInfo.data, from: 'report' } }} >{rowInfo.data.employerFirstName}</Link></td>        
        <td><Link to={{ pathname: rowInfo.data.path, search: slug, state: { stakeholder: rowInfo.data, from: 'report' } }} >{rowInfo.data.employeeRegistryNotes}</Link></td>
        <td><Link to={{ pathname: rowInfo.data.path, search: slug, state: { stakeholder: rowInfo.data, from: 'report' } }} >{rowInfo.data.employerFiscalCode}</Link></td>
        <td><Link to={{ pathname: rowInfo.data.path, search: slug, state: { stakeholder: rowInfo.data, from: 'report' } }} >{rowInfo.data.employeeFullName}</Link></td>        
        <td><Link to={{ pathname: rowInfo.data.path, search: slug, state: { stakeholder: rowInfo.data, from: 'report' } }} >{rowInfo.data.employeeInpsCode}</Link></td>
        <td><Link to={{ pathname: rowInfo.data.path, search: slug, state: { stakeholder: rowInfo.data, from: 'report' } }} >{rowInfo.data.employeeRecruitmentDate}</Link></td>
        <td><Link to={{ pathname: rowInfo.data.path, search: slug, state: { stakeholder: rowInfo.data, from: 'report' } }} >{rowInfo.data.employeeEndDate}</Link></td>
    </tr>      
  );
}
