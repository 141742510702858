import React from 'react';
import { withRouter } from 'react-router-dom'

import { DataGrid, Sorting, Column, Pager, Paging, FilterRow, Editing, HeaderFilter, Selection} from 'devextreme-react/data-grid';
import { createStore } from 'devextreme-aspnet-data-nojquery';

import * as DateConversion from '../../helper/dataConversion'
import * as Global from '../../global'

class ArchivedTransformationsDetailTemplate extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      closedTransformationsDataSource: this.createClosedTransformationsDataSource(this.props.data.key),
      loggedUser: Global.GetLoggedUser(),
    };

    this.onTransformationsTableSelectionChanged = this.onTransformationsTableSelectionChanged.bind(this);
  }

  createClosedTransformationsDataSource(employeeId) {
    var store = createStore({
      key: 'employeeId',
      loadUrl: Global.WebApiRootUrl + "/transformations/employeeClosedTransformations/" + employeeId,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = { withCredentials: true };
        ajaxOptions.headers = { Authorization: `Bearer ${this.state.loggedUser.token}`}
      },
      onAjaxError: ({ xhr, error}) => {
        Global.CheckResponseAuth(xhr);
      }
    });

    return store;
  }

  onDateTimeTableFormat(cellInfo) {
    //console.log(cellInfo);
    return DateConversion.GetDateFromISOString(cellInfo, false);
  }

  onTransformationsTableSelectionChanged(e) {
    console.log("selectionChanged", e);
    var employerId = e.currentSelectedRowKeys[0];
    var transformationVersionId = e.selectedRowsData[0].lastClosedTransformationVersionId;
    console.log("selectionChanged", employerId, transformationVersionId);
    this.props.history.push({pathname: '/transformations/' + employerId.toLowerCase() + /archived/ + transformationVersionId.toLowerCase(), key: Math.floor(Math.random() * 100001), state: { buildBreadcrumbEmployeeLink: false }});
  }

  transformationsTableStateCellRender(rowInfo) {
    //console.log("transformationsTableStateCellRender", rowInfo)
    /*
    var stateClass = "";
    if (rowInfo.data.state.toLowerCase() == "aperta")
      stateClass = "ac-grid-cell-opened-item";
    else if (rowInfo.data.state.toLowerCase() == "chiusa") 
      stateClass = "ac-grid-cell-closed-item";

    return (
    <span class={stateClass}>{rowInfo.data.state}</span>
    );
    */
   
      var stateClass = "";
      var stateVal = "";
  
      if (rowInfo.data.state == 9) {      
        stateClass = "ac-grid-cell-opened-item";
        stateVal = "Aperta";
      } else if (rowInfo.data.state == 3) {      
        stateClass = "ac-grid-cell-closed-item";
        stateVal = "Chiusa";
      } else if (rowInfo.data.state == 7) {      
        stateClass = "ac-grid-cell-suspended-item";
        stateVal = "Sospesa";
      } else if (rowInfo.data.state == 5) {      
        stateClass = "ac-grid-cell-closedIndefinitely-item";
        stateVal = "Chiusa";
      } 
  
      return (
        <span class={stateClass}>{stateVal}</span>
        );
  }

  render() {
    return (
      <React.Fragment>
        <div className="master-detail-caption">
          Trasformazioni chiuse
        </div>
        
        <div id="archived-transformations-grid">
          <DataGrid 
            id="gridContainer"
            keyExpr="Id"
            ref={(ref) => this.dataGrid = ref}
            className={'dx-card wide-card'} 
            dataSource={this.state.closedTransformationsDataSource} 
            remoteOperations={true}
            showBorders={true} 
            focusedRowEnabled={false} 
            defaultFocusedRowIndex={0} 
            columnAutoWidth={true} 
            columnHidingEnabled={true}
            onSelectionChanged={this.onTransformationsTableSelectionChanged}
          >
            <Selection mode="single" />
            <Paging defaultPageSize={15} />
            <Pager showPageSizeSelector={true} showInfo={true} />            
            <FilterRow visible={false} />
            <HeaderFilter visible={false} />
            <Sorting mode="multiple" />
                      
            <Column dataField={'id'} hidingPriority={1} visible={false} />
            <Column dataField={'transformationId'} hidingPriority={1} visible={false} />
            <Column dataField={'employeeId'} hidingPriority={1} visible={false} />
            <Column dataField={'lastClosedTransformationVersionId'} hidingPriority={1} visible={false} />

            <Column dataField={'takingChargeDate'} caption={'Data presa in carico'} dataType={'date'} format={'dd/MM/yyyy'}  hidingPriority={7} visible={false} />
            <Column dataField={'month'} caption={'Mese'} dataType={'date'} format={'MM/yyyy'} />

            <Column dataField={'inpsDelegation'} caption={'Delega inps'} hidingPriority={7} />
            <Column dataField={'inpsCommunication'} caption={'Comunicaz. inps'} hidingPriority={7} />
            <Column dataField={'transformationLetter'} caption={'Lettera trasform.'} hidingPriority={7} />
            <Column dataField={'onlineVariation'} caption={'Variazione online'} hidingPriority={7} />
            <Column dataField={'sent'} caption={'Inviato'} hidingPriority={7} />

            <Column dataField={'state'} caption={'Stato'} hidingPriority={8} cellRender={this.transformationsTableStateCellRender} />
            
            <Column dataField={'closedAt'} caption={'Data chiusura'} dataType={'date'}  hidingPriority={2} defaultSortIndex={0} defaultSortOrder="desc" format={this.onDateTimeTableFormat} />
            <Column dataField={'closedByUserFullName'} caption={'Chiusa da'} hidingPriority={3} />
          </DataGrid>
        </div>
        
      </React.Fragment>
    );
  }  
}

export default withRouter(ArchivedTransformationsDetailTemplate);