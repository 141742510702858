import React from 'react';

import { DataGrid, Sorting, Column, Pager, Paging, FilterRow, Lookup, Editing, SearchPanel, HeaderFilter} from 'devextreme-react/data-grid';
import Form, { SimpleItem, Label, GroupItem, RequiredRule } from 'devextreme-react/form';
import Box, { Item } from 'devextreme-react/box';
import RadioGroup from 'devextreme-react/radio-group';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { Link, useParams } from 'react-router-dom';
import { Button } from 'devextreme-react/button';
import notify from "devextreme/ui/notify";
import { confirm } from 'devextreme/ui/dialog';
import { LoadPanel } from 'devextreme-react/load-panel';

import ArchivedExpirationsDataGridRow from './archived-expirations-grid-row.js';
import EmployerDetails from '../../components/assindatcolf/employer-details'
import EmployeeDetails from '../../components/assindatcolf/employee-details'
import EmployeeHours from '../../components/assindatcolf/employee-hours'

import * as DateConversion from '../../helper/dataConversion'
import * as Notifier from '../../helper/notifier'
import * as API from '../../api/api'
import * as Global from '../../global'

import './expiration.scss';

export class Expiration extends React.Component {
  constructor(props) {
    super(props);
    if (!localStorage.getItem(Global.LocalStorageKey.Year)) {
      localStorage.setItem(Global.LocalStorageKey.Year, JSON.stringify(new Date().getFullYear()));
    }

    this.state = {
      year: JSON.parse(localStorage.getItem(Global.LocalStorageKey.Year)),
      loggedUser: Global.GetLoggedUser(),
      urlParamEmployeeId: null,
      urlParamExpirationVersionId: null,
      buildBreadcrumbEmployeeLink: true,
      emploeeyExpirationData: null,
      expiration: null,
      loadManager: this.createLoadManager(),
      archivedExpirationsDataSource: null,
      isLoadPanelVisible: false,
      readOnly: false,
    };

    this.archivedExpirationsDataGrid = null;

    this.onSaveButtonClick = this.onSaveButtonClick.bind(this);
    this.onDeleteExpirationButtonClick = this.onDeleteExpirationButtonClick.bind(this);
    this.onCloseExpirationButtonClick = this.onCloseExpirationButtonClick.bind(this);
    this.onExportExpirationToPDFButtonClick = this.onExportExpirationToPDFButtonClick.bind(this);
    this.onLetterToBePreparedRadioValueChanged = this.onLetterToBePreparedRadioValueChanged.bind(this);

    this.hideLoadPanel = this.hideLoadPanel.bind(this);
    this.showLoadPanel = this.showLoadPanel.bind(this);
  }

  componentDidMount() {    
    return;
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.match.params.employeeId) {
      this.setState({
        loadManager: this.createLoadManager()}, () => {
          if(nextProps.match.params.expirationVersionId) {
            //impiegato e trasformazione archiviata, carico la trasformazione archiviata
            var buildBreadcrumbEmployeeLinkProp = false;
            if(this.props && this.props.location && this.props.location.state && this.props.location.state.buildBreadcrumbEmployeeLink)
              buildBreadcrumbEmployeeLinkProp = this.props.location.state.buildBreadcrumbEmployeeLink;

            this.setState({
              urlParamEmployeeId: nextProps.match.params.employeeId}, () => {
                this.setState({
                  buildBreadcrumbEmployeeLink: buildBreadcrumbEmployeeLinkProp,
                  urlParamExpirationVersionId: nextProps.match.params.expirationVersionId}, () => {
                    this.getEmployee();       
                    this.getExpiration(false);
                  });
              });
          } else {
            //solo impiegato, carico l'ultima trasformazione aperta
            this.setState({
              urlParamEmployeeId: nextProps.match.params.employeeId}, () => {
                this.getEmployee();
                this.getExpiration(true);
              });  
          }          
        });
    }
    else
      this.props.history.push({pathname: '/expirations'});
  }

  createLoadManager() {    
    var l = {
      isEmployeeLoaded: false,
      isExpirationLoaded: false,
      isLoadingComplete : function() { 
        return (this.isEmployeeLoaded && this.isExpirationLoaded);
      }
    };

    return l;
  }

  createArchivedExpirationsDataSource(expirationId) {   
    var store = createStore({
      key: 'expirationVersionId',
      loadUrl: Global.WebApiRootUrl + '/expirations/archived/' + expirationId,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = { withCredentials: true };
        ajaxOptions.headers = { Authorization: `Bearer ${this.state.loggedUser.token}`}
      },
      onAjaxError: ({ xhr, error}) => {
        Global.CheckResponseAuth(xhr);
      }
    });

    return store;
  }

  getEmployee() {
    let emploeeyPromise = API.getEmployeeWithEmployer(this.state.loggedUser.token, this.state.urlParamEmployeeId);
    let emploeey = emploeeyPromise.then(result => {
      let lm = this.state.loadManager;
      lm.isEmployeeLoaded = true;

      this.setState({
        emploeeyExpirationData: result,
        loadManager: lm
      });

      return result; 
    })
    .catch(error => {
      console.log("Error: ", error)
      return null;
    });
  }

  getExpiration(getLastOpenExpiration) {
    let promise = null;
    
    if (getLastOpenExpiration)
      promise = API.getEmployeeLastExpiration(this.state.loggedUser.token, this.state.urlParamEmployeeId);
    else
      promise = API.getEmployeeArchivedExpiration(this.state.loggedUser.token, this.state.urlParamEmployeeId, this.state.urlParamExpirationVersionId);
      
    let emploeey = promise.then(result => {
      console.log("Last expiration 1", result);

      if(result && result.data && result.isNewRecord && result.data.expirationDate)
        result.data.expirationDate = null; //toca pelotas...
      
      var lm = this.state.loadManager;
      lm.isExpirationLoaded = true;

      this.setState({
        expiration: result,
        loadManager: lm,
        archivedExpirationsDataSource: this.createArchivedExpirationsDataSource(result.base.expirationId),
        readOnly: (this.state.urlParamExpirationVersionId != null && result.data.archived == true && result.isLastExpirationVersion == false), //readonly nei casi in cui è archiviata e non l'ultima versione, poichè si può modificare sia l'ultima aperta che l'ultima archiviata
      });
      
      return result; 
    })
    .catch(error => {
      console.log("Error: ", error)
      return null;
    });
  }

  onExportExpirationToPDFButtonClick() {
    this.showLoadPanel();

    const url =  Global.WebApiRootUrl + "/expirations/exportExpirationToPDF";

    var t = this.state.expiration;
    t.userId = this.state.loggedUser.userId;
    t.year = this.state.year;    

    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(t),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.state.loggedUser.token}`
      }
    };

    fetch(url, requestOptions)
      .then(response => {       
        if(response.ok) return response.blob();        
        Global.CheckResponseAuth(response);
      })
      .then(blob => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;

        var slug = this.state.emploeeyExpirationData.employer.lastName + '-' + this.state.emploeeyExpirationData.employer.firstName + '-' + this.state.emploeeyExpirationData.employer.fiscalCode;
        slug = slug.replace(/\s/g,'').toLowerCase();

        a.download = slug + ".pdf";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();    
        a.remove();  //afterwards we remove the element again 
        
        this.hideLoadPanel();
      })
      .catch(error => { this.hideLoadPanel(); notify(error, 'error', 5000); });
  }

  onDeleteExpirationButtonClick() {
    let result = confirm("Confermi l'eliminazione della cessazione (e di tutte le relative cessazioni archiviate)?", "Confirma eliminazione");
    result.then((dialogResult) => {
      if (dialogResult) {
        this.showLoadPanel();

        var url =  Global.WebApiRootUrl + "/expirations/delete";

        var t = this.state.expiration;
        t.userId = this.state.loggedUser.userId;

        const requestOptions = {
          method: 'DELETE',
          body: JSON.stringify(t),
          headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${this.state.loggedUser.token}`
          }
        };
    
        return fetch(url, requestOptions)
          .then(response => {
            if(response.ok) return response.json();
            Global.CheckResponseAuth(response);
            return null;
          })
          .then(data => {
            this.hideLoadPanel();           
            Notifier.SetGlobalToastMessage(data.message, (data.success ? 'success' : 'error'));              
            this.props.history.push({pathname: '/expirations'});
          })
          .catch(error => { this.hideLoadPanel(); notify(error, 'error', 5000); }); 
        }  
    });
    return null;
  }

  onCloseExpirationButtonClick() {
    let result = confirm("Confermi la chiusura della cessazione?", "Confirma chiusura");
    result.then((dialogResult) => {
      if (dialogResult) {
        this.showLoadPanel();

        var url =  Global.WebApiRootUrl + "/expirations/close";

        var t = this.state.expiration;
        t.userId = this.state.loggedUser.userId;

        const requestOptions = {
          method: 'POST',
          body: JSON.stringify(t),
          headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${this.state.loggedUser.token}`
          }
        };
    
        return fetch(url, requestOptions)
          .then(response => {
            if(response.ok) return response.json();
            Global.CheckResponseAuth(response);
            return null;
          })
          .then(data => {        
            this.hideLoadPanel();      
            Notifier.SetGlobalToastMessage(data.message, (data.success ? 'success' : 'error'));              
            this.props.history.push({pathname: '/expirations'});
          })
          .catch(error => { this.hideLoadPanel(); notify(error, 'error', 5000); }); 
        }  
    });
    return null;
  }

  validate() {
    if(!this.state.expiration.data.takingChargeDate || !this.state.expiration.data.expirationDate)
      return {
        isValid: false,
        message: 'Data di presa in carico e Data di cessazione sono obbligatorie',
        notifyType: 'error'
      }
      
    return {
      isValid: true,
      message: 'OK',
      notifyType: 'success'
    }     
  }

  onSaveButtonClick(e) {   
    var valid = this.validate();
    if(valid.isValid) {
      this.showLoadPanel();

      var url =  Global.WebApiRootUrl + "/expirations/createOrUpdate";

      var t = this.state.expiration;
      t.userId = this.state.loggedUser.userId;

      const requestOptions = {
        method: 'POST',
        body: JSON.stringify(t),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.state.loggedUser.token}`
        }
      };
  
      return fetch(url, requestOptions)
        .then(response => {
            if(response.ok) return response.json();
            Global.CheckResponseAuth(response);
            return null;
        })
        .then(data => {
            console.log(data); 
            this.hideLoadPanel();

            notify(data.message, data.success ? 'success' : 'error', 5000);
      
            if(data.success) {
              if(data.data.data.archived) {
                //salvataggio di una cessazione già chiusa e archiviata, devo navigare nella nuova cessazione arcihivata
                this.props.history.push({pathname: '/expirations/' + data.data.base.employeeId.toLowerCase() + /archived/ + data.data.data.expirationVersionId.toLowerCase()});
              } else {
                //salvataggio di una trasformazione aperta, rimango nella stessa vista ricaricando la trasformazione con quella passata
                this.setState({
                  expiration: data.data,
                  archivedExpirationsDataSource: this.createArchivedExpirationsDataSource(data.data.base.expirationId)
                });
              }
            } 
        })
        .catch(error => { this.hideLoadPanel(); notify(error, 'error', 5000); });
    } else {      
      notify(valid.message, valid.notifyType, 5000);
    }

    return null;
  }

  showLoadPanel() {
    this.setState({
      isLoadPanelVisible: true
    });
  }

  hideLoadPanel() {
    this.setState({
      isLoadPanelVisible: false
    });
  }

  onFormSubmit(e) {
    if(e && e.onSaveButtonClick)
      e.onSaveButtonClick();
    else
      alert("Si è verificato un errore, non è possibile procedere.")    
  }

  onLetterToBePreparedRadioValueChanged(e) {
    //console.log("onLetterToBePreparedRadioValueChanged", e);
    var ex = this.state.expiration;

    if(e.value === 'SI')
      ex.data.letterToBePrepared = true;
    else
      ex.data.letterToBePrepared = false;

    this.setState({
      expiration: ex
    });
  }

  renderView() {
    if(!this.state.loadManager.isLoadingComplete())
      return this.renderLoader();
    else
      return this.renderContent();
  }

  renderLoader() {
    return (
      <React.Fragment>
        <div className={'content-block ac-page-title'}><Link to="/expirations">Cessazioni</Link> &gt; ... </div>
        <div className={'content-block'}>
          <div className={'dx-card responsive-paddings'}>
            <div className={'ac-centered'}><LoadIndicator id="large-indicator" height={60} width={60} /></div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderExpirationState(state) {    
    /*
    if(isNewRecord) { 
      //nuova cessazione
      return (<div className={'ac-container-new-item'}>Nuova</div>);
    } 
    
    if(!isLastExpirationVersion) { 
      //cessazione archiviata, chiusa o aperta
      return (<div className={'ac-container-archived-item'}>Archiviata</div>);
    }
    */

    if (state == 0) { 
      //nuova cessazione
      return (<div className={'ac-container-new-item'}>Nuova</div>);
    } else if(state == 1) { 
      //cessazione archiviata, chiusa o aperta
      return (<div className={'ac-container-archived-item'}>Archiviata</div>);
    } else if (state == 9) {
      //cessazione aperta
      return (<div className={'ac-container-opened-item'}>Aperta</div>);
    } else if (state == 7) {
      //cessazione sospesa
      return (<div className={'ac-container-suspended-item'}>Sospesa</div>);
    } else if (state == 5) {
      //cessazione chiusa ma trasformazione a tempo indeterminato che può essere uno stato chiuso per le altre entità
      return (<div className={'ac-container-closedIndefinitely-item'}>Chiusa</div>);
    } else if (state == 3) {
      //cessazione chiusa
      return (<div className={'ac-container-closed-item'}>Chiusa</div>);
    }
    
    //se arrivo qui c'è qualche problema con la valutazione dello stato della cessazione 
    return (<div className={'ac-container-new-item'}>indefinita</div>);
  }

  renderBreadcrumb() {
    if(!this.state.urlParamExpirationVersionId) {
      //path if: current expiration - else: archived expiration
      return (
        <div>
          <div>
            <Link to="/expirations">Cessazioni</Link> &gt; {this.state.emploeeyExpirationData.lastName} {this.state.emploeeyExpirationData.firstName} ({this.state.emploeeyExpirationData.employer.lastName} {this.state.emploeeyExpirationData.employer.firstName})
          </div>
          <div className={'ac-margin-top-05'}>
            <div className={'ac-float-letf'}>{this.renderExpirationState(this.state.expiration.state)}</div>
            <div className={'ac-float-right'}>
              {
                this.state.expiration.isNewRecord == false 
                  ? 
                    <span> 
                      <Button width={120} text="Chiudi" type="default" stylingMode="outlined" onClick={this.onCloseExpirationButtonClick} />&nbsp;
                      <Button width={120} text="Elimina" type="default" stylingMode="outlined" onClick={this.onDeleteExpirationButtonClick} />&nbsp;
                      <Button width={130} text="Genera PDF" type="default" stylingMode="outlined" onClick={this.onExportExpirationToPDFButtonClick} />&nbsp;
                    </span> 
                  : 
                    <span></span>
              }
              <Button className="ac-button-default" width={120} text="Salva" type="default" stylingMode="contained" useSubmitBehavior={true} onClick={this.onSaveButtonClick} />
            </div>
            <div className={'ac-clear-both'}></div>
          </div>
        </div>);
    } else {
      var returnPathName = '/expirations/' + this.state.emploeeyExpirationData.employeeId.toLowerCase();
      if(this.state.expiration.base.closedAt)
        returnPathName = '/expirations/' + this.state.emploeeyExpirationData.employeeId.toLowerCase() + '/archived/' + this.state.expiration.lastClosedExpirationVersionId.toLowerCase()

      return (
        <div>
          <div>
            <span><Link to="/expirations">Cessazioni</Link> &gt; </span>
            <Link to={{ pathname: returnPathName, state: { archivedReload: false } }} >
              <span> {this.state.emploeeyExpirationData.lastName} {this.state.emploeeyExpirationData.firstName}</span>
              <span> ({this.state.emploeeyExpirationData.employer.lastName} {this.state.emploeeyExpirationData.employer.firstName})</span>
            </Link>
            <span> &gt; {DateConversion.GetDateFromISOString(this.state.expiration.data.archivedAt, false)}</span>
            <span> ({this.state.expiration.archivedByByUserFullName})</span>
          </div>
          <div>
            <div className={'ac-float-letf'}>{this.renderExpirationState(this.state.expiration.state)}</div>
            <div className={'ac-float-right'}>
              <Button width={120} text="Elimina" type="default" stylingMode="outlined" onClick={this.onDeleteExpirationButtonClick} />&nbsp;
              <Button width={130} text="Genera PDF" type="default" stylingMode="outlined" onClick={this.onExportExpirationToPDFButtonClick} />&nbsp;
              {
                this.state.expiration.isLastExpirationVersion == true
                  ?
                    <span><Button className="ac-button-default" width={120} text="Salva" type="default" stylingMode="contained" useSubmitBehavior={true} onClick={this.onSaveButtonClick} /></span>
                  : 
                    <span><Button className="ac-button-default" width={120} text="Salva" type="default" stylingMode="contained" useSubmitBehavior={true} disabled={true} /></span>
              }
            </div>
            <div className={'ac-clear-both'}></div>
          </div>
        </div>);
    }
  }

  renderContent() {
    return (
      <React.Fragment>
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          visible={this.state.isLoadPanelVisible}
          showIndicator={true}
          shading={true}
          showPane={true}
          closeOnOutsideClick={false}
        />

        <div className={'content-block ac-page-title'}>
          {this.renderBreadcrumb()}
        </div>
     
        <div className={'ac-margin-top-10 content-block'}>
          <Box
            direction="row"
            width="100%">
            <Item ratio={3}>
              <div className={'dx-card responsive-paddings'}>
                <div className="ac-card-title">Anagrafiche datore/dipendente</div>
                <div className={'ac-margin-top-05'}> 
                  <EmployerDetails title="Datore di lavoro" employerId={this.state.emploeeyExpirationData.employerId} lightView={true} />
                </div>
                <div className={'ac-margin-top-20'}> 
                  <EmployeeDetails title="Dipendente" employeeId={this.state.emploeeyExpirationData.employeeId} lightView={true} />
                </div>
              </div>
            </Item>
            <Item ratio={2}>
              <div className={'dx-card responsive-paddings ac-margin-left-10'}>
              <div className="ac-card-title">Storico cessazioni</div>
                <div id="tramsformation-archived-grid">
                  <DataGrid 
                    id="gridContainer"
                    ref={(ref) => this.archivedExpirationsDataGrid = ref}
                    className={'dx-card wide-card'} 
                    dataSource={this.state.archivedExpirationsDataSource} 
                    remoteOperations={true}
                    showBorders={true} 
                    focusedRowEnabled={false} 
                    defaultFocusedRowIndex={0} 
                    columnAutoWidth={true} 
                    columnHidingEnabled={true}    
                    height={239}       
                    rowRender={ArchivedExpirationsDataGridRow}
                  >
                    <Paging defaultPageSize={4} />
                    <Pager showPageSizeSelector={false} showInfo={true} />            
                    <FilterRow visible={false} />
                    <HeaderFilter visible={false} />
                    <Editing mode="popup" allowUpdating={false} allowDeleting={false} allowAdding={false} />
                    <Sorting mode="multiple" />                                
                    
                    <Column dataField={'employeeId'} hidingPriority={1} visible={false} />
                    <Column dataField={'expirationId'} hidingPriority={1} visible={false} />
                    <Column dataField={'expirationVersionId'} hidingPriority={1} visible={false} />
                    
                    <Column dataField={'archivedAt'} caption={'Data archiviazione'} dataType={'date'} format={'dd/MM/yyyy hh:mm:ss'} hidingPriority={4} defaultSortOrder="desc" />
                    <Column dataField={'archivedByUserFullName'} caption={'Archiviato da'} hidingPriority={3} />
                  </DataGrid>
                </div> 
              </div>
            </Item>
          </Box>
          
          <div className={'tramsformation-form-container dx-card responsive-paddings'}>
            <form action="#" className={'report-filter-form'} onSubmit={ e => {e.preventDefault(); this.onFormSubmit(this);}}>
              <Form colCount={3} id="form2" formData={this.state.expiration.data} >

                <GroupItem colSpan={3} colCount={3} >
                  <SimpleItem dataField="permanentTransformation" editorType="dxCheckBox" editorOptions={{readOnly: this.state.readOnly}} >
                    <Label text="Trasformazione a tempo indeterminato" />
                  </SimpleItem>
                  <SimpleItem dataField="suspended" editorType="dxCheckBox" editorOptions={{readOnly: this.state.readOnly}} >
                    <Label text="Sospesa" />
                  </SimpleItem>
                </GroupItem>
                
                <GroupItem colSpan={3} colCount={3} >
                  <SimpleItem dataField="takingChargeDate" editorType="dxDateBox" editorOptions={{maxLength: 25, readOnly: this.state.readOnly, dateSerializationFormat: "yyyy-MM-ddTHH:mm:ssx", displayFormat: "dd/MM/yyyy"}} >
                    <Label text="Data presa in carico" />
                    <RequiredRule message="Data presa in carico è obbligatoria" />
                  </SimpleItem>                
                  <SimpleItem dataField="expirationDate" editorType="dxDateBox" editorOptions={{maxLength: 25, readOnly: this.state.readOnly, dateSerializationFormat: "yyyy-MM-ddTHH:mm:ssx", displayFormat: "dd/MM/yyyy" }} >
                    <Label text="Data di cessazione" />
                    <RequiredRule message="Data di cessazione è obbligatorio" />
                  </SimpleItem>                
                  <SimpleItem dataField="expirationMethod" editorType="dxTextBox" editorOptions={{maxLength: 25, readOnly: this.state.readOnly}} >
                    <Label text="Modalità di cessazione" />
                  </SimpleItem>
                </GroupItem>

                <GroupItem colSpan={3} colCount={2} >
                  <SimpleItem dataField="notice" editorType="dxTextBox" editorOptions={{maxLength: 25, readOnly: this.state.readOnly}} >
                    <Label text="Preavviso" />
                  </SimpleItem>                
                  <SimpleItem dataField="letterPreparation" editorType="dxTextBox" editorOptions={{maxLength: 25, readOnly: this.state.readOnly}} >
                    <Label text="Lettera da predisporre" />
                  </SimpleItem>
                </GroupItem>

                <GroupItem colSpan={3} colCount={2} >
                  <SimpleItem dataField="marriageLeave" editorType="dxTextBox" editorOptions={{maxLength: 25, readOnly: this.state.readOnly}} >
                    <Label text="Ind. congedo matrimoniale" />
                  </SimpleItem>
                  <SimpleItem dataField="holidaySituation" editorType="dxTextBox" editorOptions={{maxLength: 25, readOnly: this.state.readOnly}} >
                    <Label text="Situazione ferie" />
                  </SimpleItem>
                </GroupItem>

                <GroupItem colSpan={3} colCount={1} >
                  <Label text="Lettera da predisporre" />
                  <RadioGroup items={['SI', 'NO']} defaultValue={'NO'} layout="horizontal" value={(this.state.expiration.data.letterToBePrepared === true ? 'SI' : 'NO')} onValueChanged={this.onLetterToBePreparedRadioValueChanged} disabled={this.state.readOnly} />
                </GroupItem>

                <GroupItem colSpan={3} colCount={1} >
                  <SimpleItem dataField="notes" editorType="dxTextArea" editorOptions={{maxLength: 256, readOnly: this.state.readOnly}} >
                    <Label text="Note" />
                  </SimpleItem>
                </GroupItem>

                <GroupItem colSpan={3} colCount={7} >
                  <SimpleItem dataField="consensualResignation" editorType="dxCheckBox" editorOptions={{readOnly: this.state.readOnly}} >
                    <Label text="Dimissioni/consensuale" />
                  </SimpleItem>
                  <SimpleItem dataField="firing" editorType="dxCheckBox" editorOptions={{readOnly: this.state.readOnly}} >
                    <Label text="Licenziamento" />
                  </SimpleItem>

                  <SimpleItem dataField="letterSent" editorType="dxCheckBox" editorOptions={{readOnly: this.state.readOnly}} >
                    <Label text="Lettera ricevuta" />
                  </SimpleItem>
                  <SimpleItem dataField="onlineVariation" editorType="dxCheckBox" editorOptions={{readOnly: this.state.readOnly}} >
                    <Label text="Variazione online" />
                  </SimpleItem>
                  <SimpleItem dataField="inpsCommunication" editorType="dxCheckBox" editorOptions={{readOnly: this.state.readOnly}} >
                    <Label text="Comunicaz. inps" />
                  </SimpleItem>
                  <SimpleItem dataField="pagopa" editorType="dxCheckBox" editorOptions={{readOnly: this.state.readOnly}} >
                    <Label text="Pagopa" />
                  </SimpleItem>
                  
                  {/* 
                  <SimpleItem dataField="inpsDelegation" editorType="dxCheckBox" editorOptions={{readOnly: this.state.readOnly}} >
                    <Label text="Delega inps" />
                  </SimpleItem>                  
                  <SimpleItem dataField="sendingDocuments" editorType="dxCheckBox" editorOptions={{readOnly: this.state.readOnly}} >
                    <Label text="Invio documenti" />
                  </SimpleItem>
                  */}

                  <SimpleItem dataField="sendingDocumentsDate" editorType="dxDateBox" editorOptions={{maxLength: 25, readOnly: this.state.readOnly, dateSerializationFormat: "yyyy-MM-ddTHH:mm:ssx", displayFormat: "dd/MM/yyyy" }} >
                    <Label text="Data invio" />
                  </SimpleItem>
                </GroupItem>
              </Form>

            </form>      
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {    
    return this.renderView();    
  }
};

export default Expiration;