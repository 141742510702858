import React from 'react';

import { DataGrid, Sorting, Column, Pager, Paging, FilterRow, Editing, HeaderFilter, Selection, Button} from 'devextreme-react/data-grid';
import PopupEmployersMergeConfirm from './popup-employers-merge-confirm';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { LoadPanel } from 'devextreme-react/load-panel';
import notify from "devextreme/ui/notify";

import * as Global from '../../global'

export class EmployersWithSameMemberCodeGrid extends React.Component {
  constructor(props) {
    super(props);

    //console.log(this);
    //console.log(this.props);
    
    this.state = {
      employersDataSource: this.createEmployersDataSource(this.props.data.id),
      loggedUser: Global.GetLoggedUser(),
      memberCode: this.props.data.id,
      selectedEmployers: null,
      employersToMove: null,
      employerToHold: null
    };    

    this.popupEmployersMergeConfirm = React.createRef();

    this.isHoldThisButtonVisible = this.isHoldThisButtonVisible.bind(this);
    this.onHoldThisButtonClick = this.onHoldThisButtonClick.bind(this);
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.onPopupEmployersMergeConfirm = this.onPopupEmployersMergeConfirm.bind(this);

    this.dataGrid = null;
  }

  createEmployersDataSource(memberCode) {
    var store = createStore({
      key: 'employerId',
      loadUrl: Global.WebApiRootUrl + "/CheckData/GetEmployersWithSameMemberCodeGrid/" + memberCode,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = { withCredentials: true };
        ajaxOptions.headers = { Authorization: `Bearer ${this.state.loggedUser.token}`}
      },
      onAjaxError: ({ xhr, error}) => {
        Global.CheckResponseAuth(xhr);
      }
    });

    return store;
  }

  onSelectionChanged(e) {
    //console.log("onSelectionChanged e", e);

    this.setState({
      selectedEmployers: e.selectedRowsData
    });

    this.dataGrid.instance.refresh();
  }

  isHoldThisButtonVisible(data) {
    //console.log("isHoldThisButtonVisible", data);
        
    return data.row.isSelected && this.state.selectedEmployers.length > 1; //at least two employers selected
  }

  onHoldThisButtonClick(e) {    
    //>>set employer to Hold
    this.setState({
      employerToHold: e.row.data,
      isLoadPanelVisible: false,
    });

    //>>remove employer to Hold from all selected employers 
    var newEmployersToMove = this.state.selectedEmployers.slice(); //clone all selected employers

    //var indexEmployerToHold = newEmployersToMove.indexOf(this.state.employerToHold); //find employer to Hold
    var indexEmployerToHold = newEmployersToMove.map(function(e) { return e.employerId; }).indexOf(e.row.key);
    if (indexEmployerToHold !== -1) {
      newEmployersToMove.splice(indexEmployerToHold, 1); //remove finded employer
    }

    this.setState({
      employersToMove: newEmployersToMove
    });
    //end

    /*
    console.log("onHoldThisClick selectedEmployers", this.state.selectedEmployers);
    console.log("onHoldThisClick employersToMove", this.state.employersToMove);
    console.log("onHoldThisClick employerToHold", this.state.employerToHold);
    */
    //console.log("aa", this.dataGrid.instance.getSelectedRowsData());

    this.popupEmployersMergeConfirm.showPopup(this.state.employerToHold, this.state.employersToMove, this.state.memberCode);
  };

  onPopupEmployersMergeConfirm() {
    this.popupEmployersMergeConfirm.hidePopup();

    if(!this.state || !this.state.employerToHold || !this.state.employersToMove || this.state.employersToMove.length < 1) {
      notify("Non è stato possibile recuperare correttamente i datori di lavoro da unire, non è possibile proseguire", 'error', 5000);
      return;
    }      
    
    var employersIdToMove = [];
    this.state.employersToMove.forEach((element, index, array) => {
      employersIdToMove.push(element.employerId);      
    });

    var mergeInfo = {employerIdToHold: this.state.employerToHold.employerId, employersIdToMove: employersIdToMove};
    //var mergeInfo = {employerIdToHold: this.state.employerToHold.employerId};

    this.showLoadPanel();

    //console.log("onPopupEmployersMergeConfirm", JSON.stringify({employerIdToHold: this.state.employerToHold.employerId, employersIdToMove: employersIdToMove}));
    this.mergeEmployers({employerIdToHold: this.state.employerToHold.employerId, employersIdToMove: employersIdToMove});
  }

  mergeEmployers(mergeInfo) {        
    var url =  Global.WebApiRootUrl + "/CheckData/MergeEmployers";

    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(mergeInfo),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.state.loggedUser.token}`
      }
    };

    return fetch(url, requestOptions)
      .then(response => {
        if(response.ok) return response.json();
        Global.CheckResponseAuth(response);
        return null;
      })
      .then(data => {
        this.hideLoadPanel();
        notify(data.message, data.success ? 'success' : 'error', 5000);

        if(data.success) {          
          this.props.onMergeSuccess();
        }
        
      })
      .catch(error => { this.hideLoadPanel(); notify(error, 'error', 5000); });
  }  

  showLoadPanel() {
    this.setState({
      isLoadPanelVisible: true
    });
  }

  hideLoadPanel() {
    this.setState({
      isLoadPanelVisible: false
    });
  }

  render() {
    return (
      <React.Fragment>
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          visible={this.state.isLoadPanelVisible}
          showIndicator={true}
          shading={true}
          showPane={true}
          closeOnOutsideClick={false}
        />

        <div className="master-detail-caption">
          Datori di Lavoro con Codice Socio <b>{this.state.memberCode}</b>
        </div>
        
        <div id="employers-with-same-inps-code-container">
          <DataGrid 
            id="employers-with-same-inps-code-grid"
            keyExpr="employerId"
            ref={(ref) => this.dataGrid = ref}
            className={'dx-card wide-card'} 
            dataSource={this.state.employersDataSource} 
            remoteOperations={true}
            showBorders={true} 
            focusedRowEnabled={false} 
            defaultFocusedRowIndex={0} 
            columnAutoWidth={true} 
            columnHidingEnabled={true}
            onSelectionChanged={this.onSelectionChanged}
            onRowClick={this.onRowClick}
          >
            <Selection mode="multiple" selectAllMode="allPages" showCheckBoxesMode="always" />
            <Paging defaultPageSize={15} />
            <Pager showPageSizeSelector={true} showInfo={true} />            
            <FilterRow visible={false} />
            <HeaderFilter visible={false} />
            <Sorting mode="multiple" />
                      
            <Column dataField={'employerId'} visible={false} />
            <Column dataField={'holdThis'} caption={'Mantieni questo'} type="buttons" width={100}>
              <Button hint="Avvia la procedura di trasferimento mantenendo questo datore di lavoro" icon="selectall" visible={this.isHoldThisButtonVisible} onClick={this.onHoldThisButtonClick} />
            </Column>
            <Column dataField={'lastName'} caption={'Cognome'} hidingPriority={5} />
            <Column dataField={'firstName'} caption={'Nome'} hidingPriority={4} />
            <Column dataField={'fiscalCode'} caption={'Codice fiscale'} hidingPriority={3} />
            <Column dataField={'email'} caption={'Email'} hidingPriority={2} />
            <Column dataField={'updatedAt'} caption={'Data aggiornamento'} dataType={'date'} format={'dd/MM/yyyy'} hidingPriority={1} defaultSortIndex={1} defaultSortOrder='desc' />
          </DataGrid>
          <PopupEmployersMergeConfirm onRef={ref => (this.popupEmployersMergeConfirm = ref)} onPopupEmployersMergeConfirm={this.onPopupEmployersMergeConfirm}></PopupEmployersMergeConfirm>

        </div>
        
      </React.Fragment>
    );
  }
}

export default EmployersWithSameMemberCodeGrid;