import React from 'react';
import { Link } from 'react-router-dom';
import './stakeholders-grid-row.scss';

export default function StakeholdersDataGridRow(rowInfo) {  
  var slug = rowInfo.data.lastName + '-' + rowInfo.data.firstName + '-' + rowInfo.data.fiscalCode;
  slug = slug.replace(/\s/g,'').toLowerCase();

  return (    
    <tr className="custom-row dx-row dx-data-row dx-row-lines">
        <td><Link to={{ pathname: rowInfo.data.path, search: slug, state: { stakeholder: rowInfo.data, from: 'stakeholders' } }} >{rowInfo.data.lastName}</Link></td>
        <td><Link to={{ pathname: rowInfo.data.path, search: slug, state: { stakeholder: rowInfo.data, from: 'stakeholders' } }} >{rowInfo.data.firstName}</Link></td>        
        <td><Link to={{ pathname: rowInfo.data.path, search: slug, state: { stakeholder: rowInfo.data, from: 'stakeholders' } }} >{rowInfo.data.employeeRegistryNotes}</Link></td>
        <td><Link to={{ pathname: rowInfo.data.path, search: slug, state: { stakeholder: rowInfo.data, from: 'stakeholders' } }} >{rowInfo.data.fiscalCode}</Link></td>

        <td><Link to={{ pathname: rowInfo.data.path, search: slug, state: { stakeholder: rowInfo.data, from: 'stakeholders' } }} >{rowInfo.data.employeeInpsCode}</Link></td>
        <td><Link to={{ pathname: rowInfo.data.path, search: slug, state: { stakeholder: rowInfo.data, from: 'stakeholders' } }} >{rowInfo.data.employeeRecruitmentDate}</Link></td>
        <td><Link to={{ pathname: rowInfo.data.path, search: slug, state: { stakeholder: rowInfo.data, from: 'stakeholders' } }} >{rowInfo.data.employeeEndDate}</Link></td>

        <td><Link to={{ pathname: rowInfo.data.path, search: slug, state: { stakeholder: rowInfo.data, from: 'stakeholders' } }} >{rowInfo.data.type}</Link></td>
    </tr>      
  );
}
