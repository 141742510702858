import React from 'react';

import VariationMonth from '../../components/assindatcolf/variation-month'
import * as Global from '../../global'

import './employee-variations.scss';
 
export class EmployeeVariations extends React.Component {
  constructor(props) {
      super(props);

      if (!this.props.loggedUser)
        Global.Logout();

      this.state = {
        employeeId: this.props.employeeId,
        year: this.props.year,
        loggedUser: this.props.loggedUser,
    };
  }

  componentDidMount() {
    if (!this.props.loggedUser)
      Global.Logout();
  }

  getMonthsArray(year){ 
    var months = [];

    months.push({
      "month": 12,
      "year": year - 1
    });

    for(var i = 1; i <= 12; i++)
      months.push({
        "month": i,
        "year": year
      });

    months.push({
      "month": 1,
      "year": year + 1
    });

    return months;
  }

  render() {    
    var months = this.getMonthsArray(this.props.year);
    var localEmployeeId = this.props.employeeId;
    var variationsRender = months.map(function(months){
      let localMonthName = getMonthName(months.month).toString().substring(0, 3) + " " + months.year.toString().substring(2, 4);
      return <VariationMonth title={localMonthName} loggedUser={Global.GetLoggedUser()} employeeId={localEmployeeId} year={months.year} month={months.month} />;
    })
    
    return (
      <div className={'employee-hours-content-block'}>
        <div>
          <div className="employee-hours-content-block-header">Variazioni</div>
        </div>
        <div className="employee-variation-content-block">
          {variationsRender}
        </div>
      </div>
    );
  }
}

function getMonthName(idx) {
  var objDate = new Date();
  objDate.setDate(1);
  objDate.setMonth(idx-1);

  var locale = "it-IT", month = objDate.toLocaleString(locale, { month: "long" });
  return (month.charAt(0).toUpperCase() + month.slice(1));
}

export default EmployeeVariations;