export function GetDateFromISOString(value, isOnlyDate) {    
    var strDate = "";
    if(value) {
        var today = new Date(value);
        if(typeof today.getMonth === 'function') {
            var day = today.getDate() + "";
            if(day.length == 1)
                day = "0" + day;
            
            var month = (today.getMonth() + 1) + "";
            if(month.length == 1)
                month = "0" + month;
            
            var year = today.getFullYear() + "";
            if(year.length == 1)
                year = "0" + year;

            if(isOnlyDate) {
                var strDate = day + "/" + month + "/" + year
            } else {
                var hour = today.getHours() + "";
                if(hour.length == 1)
                    hour = "0" + hour;
                
                var minutes = today.getMinutes() + "";
                if(minutes.length == 1)
                    minutes = "0" + minutes;

                var seconds = today.getSeconds() + "";
                if(seconds.length == 1)
                    seconds = "0" + seconds;
                
                var strDate = day + "/" + month + "/" + year + " " + hour + ":" + minutes + ":" + seconds;
            }
        }
    }
    return strDate;
}