import React from 'react';

import 'devextreme/data/odata/store';
import { DataGrid, Column, Pager, Paging, FilterRow, Lookup, Editing} from 'devextreme-react/data-grid';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { Link } from 'react-router-dom';
import * as Global from '../../global'
import DataRow from './dataRow.js';

import EmlyerDetails from '../../components/assindatcolf/employer-details'

import './employer.scss';

export class Employer extends React.Component {
  constructor(props) {
    super(props);

    if (!localStorage.getItem('ac-year')) {
      localStorage.setItem('ac-year', JSON.stringify(new Date().getFullYear()));
    }

    this.state = {
      year: JSON.parse(localStorage.getItem('ac-year')),
      loggedUser: Global.GetLoggedUser()
    };

    //Global.gblIsLoggedUserAdmin();
  }

  componentDidMount() {
    if(!this.props.history.location.state) {       
      this.props.history.push({pathname: 'stakeholders'});
    }
  }

  render() {    
    if(!this.props.history.location.state) {  
      return (<React.Fragment></React.Fragment>);
    } else {
      const employeesDataSourceUrl = Global.WebApiRootUrl + "/Employers/" + this.props.history.location.state.stakeholder.employerId + "/Employees";

      const employeesDataSource = createStore({
        key: 'employeeId',
        loadUrl: employeesDataSourceUrl,        
        onBeforeSend: (method, ajaxOptions) => {          
          ajaxOptions.xhrFields = { withCredentials: true };
          ajaxOptions.headers = { Authorization: `Bearer ${this.state.loggedUser.token}`}
        },
        onAjaxError: ({ xhr, error}) => {
          Global.CheckResponseAuth(xhr);
        }
      });

      return (
        <React.Fragment>
          <div className={'content-block ac-page-title'}><Link to="/stakeholders">Inserimento variazioni</Link> &gt; {this.props.history.location.state.stakeholder.lastName} {this.props.history.location.state.stakeholder.firstName}</div>
          <div className={'content-block'}>
            <div className={'dx-card responsive-paddings'}>
              <p className={'employer-year'}>Anno: {this.state.year}</p>
              <EmlyerDetails title="Anagrafica datore di lavoro" employerId={this.props.history.location.state.stakeholder.employerId} />

              <div>
                <div>Dipendenti</div>
              </div>
              <div>
                <DataGrid 
                  className={'dx-card wide-card'} 
                  dataSource={employeesDataSource} 
                  showBorders={true} 
                  focusedRowEnabled={true} 
                  defaultFocusedRowIndex={0} 
                  columnAutoWidth={true} 
                  columnHidingEnabled={true}
                  rowRender={DataRow}
                >

                  <Paging defaultPageSize={10} />
                  <Pager showPageSizeSelector={true} showInfo={true} />
                  <FilterRow visible={true} />
                  <Editing mode="popup" allowUpdating={false} allowDeleting={false} allowAdding={false} />
                  <Column dataField={'employeeId'} hidingPriority={1} visible={false} />                  
                  <Column dataField={'lastName'} caption={'Cognome'} hidingPriority={4} />        
                  <Column dataField={'firstName'} caption={'Nome'} hidingPriority={3} />
                  <Column dataField={'employeeRegistryNotes'} caption={'Codice socio'} hidingPriority={3} alignment='left' />
                  <Column dataField={'fiscalCode'} caption={'Codice fiscale'} hidingPriority={2} />
                  <Column dataField={'employeeInpsCode'} caption={'Codice INPS'} hidingPriority={2} />
                  <Column dataField={'employeeRecruitmentDate'} caption={'Data ass.'} hidingPriority={2} />
                  <Column dataField={'employeeEndDate'} caption={'Data cess.'} hidingPriority={2} />
                  <Column dataField={'employeeContractCategory'} caption={'employeeContractCategory'} hidingPriority={1} visible={false} />
                  <Column dataField={'employeeContractType'} caption={'employeeContractType'} hidingPriority={1} visible={false} />
                  <Column dataField={'employeeHalfDayOff'} caption={'employeeHalfDayOff'} hidingPriority={1} visible={false} />
                  <Column dataField={'employeeLevel'} caption={'employeeLevel'} hidingPriority={1} visible={false} />
                  <Column dataField={'employeeTask'} caption={'employeeTask'} hidingPriority={1} visible={false} />
                  <Column dataField={'employeeNotes'} caption={'employeeNotes'} hidingPriority={1} visible={false} />
                  <Column dataField={'employeeUserNotes'} caption={'employeeUserNotes'} hidingPriority={1} visible={false} />
                </DataGrid>
              </div>

            </div>
          </div>
        </React.Fragment>
      );
    }
  }
}

export default Employer;