import React, { useRef } from 'react';
import * as Global from '../../global';
import { useAuth } from '../../contexts/auth';
import Header from '../../components/assindatcolf/header';
import MemberCodeWithMultipleEmployersGrid from '../../components/assindatcolf/member-code-with-multiple-employers-grid';

import './checkdata.scss';

export default () => {
  const authProvider = useAuth(); 

  return (
    <React.Fragment>
      <Header sectionTitle="Verifica duplicati" authProvider={authProvider} showYearSelector={false} ></Header>
      
      <MemberCodeWithMultipleEmployersGrid authProvider={authProvider} ></MemberCodeWithMultipleEmployersGrid>
    </React.Fragment>
  );
};