import React, { useRef } from 'react';
import * as Global from '../../global';
import { useAuth } from '../../contexts/auth';
import ExpirationsGrid from './expirations-grid';
import Header from '../../components/assindatcolf/header';
import * as Notifier from '../../helper/notifier'

import './expirations.scss';

export default () => {
  const authProvider = useAuth(); 

  var cityId = "00000000-0000-0000-0000-000000000000";

  if (!localStorage.getItem('ac-year')) {
    localStorage.setItem('ac-year', JSON.stringify(new Date().getFullYear()));
  }
  var year = JSON.parse(localStorage.getItem('ac-year'));
  
  if (authProvider) { 
    if(authProvider.user.cityId) {
      cityId = authProvider.user.cityId;
    } 
  }

  var onYearChange = {
    raise() {
      window.location.reload(); //fallback classico
    }
  }

  Notifier.CheckGlobalToastMessage();

  return (
    <React.Fragment>
      <Header sectionTitle="Cessazioni" authProvider={authProvider} showYearSelector={true} parentYear={year} onYearChange={onYearChange} ></Header>
      <ExpirationsGrid userCityId={cityId} authProvider={authProvider} year={year} ></ExpirationsGrid>
    </React.Fragment>
  );
};
