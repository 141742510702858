import React from 'react';
import { Link, Route, useRouteMatch } from "react-router-dom";

import * as DateConversion from '../../helper/dataConversion'

import './archived-transformations-grid-row.scss';

export default function ArchivedTransformationsDataGridRow(rowInfo) { 
  
  var slug = rowInfo.data.transformationId;
  slug = slug.replace(/\s/g,'').toLowerCase();

  return (    
      <tr className="custom-row dx-row dx-data-row dx-row-lines">
          <td><Link to={{ pathname: '/transformations/' + rowInfo.data.employeeId.toLowerCase() + /archived/ + rowInfo.data.transformationVersionId.toLowerCase(), key: Math.floor(Math.random() * 100001), state: { archivedReload: true } }} >{DateConversion.GetDateFromISOString(rowInfo.data.archivedAt, false)}</Link></td>
          <td><Link to={{ pathname: '/transformations/' + rowInfo.data.employeeId.toLowerCase() + /archived/ + rowInfo.data.transformationVersionId.toLowerCase(), key: Math.floor(Math.random() * 100001), state: { archivedReload: true } }} >{rowInfo.data.archivedByUserFullName}</Link></td>
      </tr>  
  );
}
