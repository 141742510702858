import React from 'react';

import { DataGrid, Sorting, Column, Pager, Paging, FilterRow, Lookup, Editing, SearchPanel, HeaderFilter} from 'devextreme-react/data-grid';
import Form, { SimpleItem, Label, GroupItem, RequiredRule } from 'devextreme-react/form';
import Box, { Item } from 'devextreme-react/box';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { Link, useParams } from 'react-router-dom';
import { Button } from 'devextreme-react/button';
import notify from "devextreme/ui/notify";
import { confirm } from 'devextreme/ui/dialog';
import { LoadPanel } from 'devextreme-react/load-panel';

import ArchivedRecruitmentsDataGridRow from './archived-recruitments-grid-row.js';
import EmployerDetails from '../../components/assindatcolf/employer-details'
import EmployeeDetails from '../../components/assindatcolf/employee-details'

import * as DateConversion from '../../helper/dataConversion'
import * as Notifier from '../../helper/notifier'
import * as API from '../../api/api'
import * as Global from '../../global'

import './recruitment.scss';

export class Recruitment extends React.Component {
  constructor(props) {
    super(props);

    //console.log("Recruitment constructor");

    if (!localStorage.getItem(Global.LocalStorageKey.Year)) {
      localStorage.setItem(Global.LocalStorageKey.Year, JSON.stringify(new Date().getFullYear()));
    }

    this.state = {
      year: JSON.parse(localStorage.getItem(Global.LocalStorageKey.Year)),
      loggedUser: Global.GetLoggedUser(),
      urlParamEmployeeId: null,
      urlParamRecruitmentVersionId: null,
      buildBreadcrumbEmployeeLink: true,
      emploeeyRecruitmentData: null,
      recruitment: null,
      loadManager: this.createLoadManager(),
      archivedRecruitmentsDataSource: null,
      isLoadPanelVisible: false,
    };

    this.archivedRecruitmentsDataGrid = null;

    this.onSaveButtonClick = this.onSaveButtonClick.bind(this);
    this.onDeleteRecruitmentButtonClick = this.onDeleteRecruitmentButtonClick.bind(this);
    this.onCloseRecruitmentButtonClick = this.onCloseRecruitmentButtonClick.bind(this);
    this.onExportRecruitmentToPDFButtonClick = this.onExportRecruitmentToPDFButtonClick.bind(this);

    this.hideLoadPanel = this.hideLoadPanel.bind(this);
    this.showLoadPanel = this.showLoadPanel.bind(this);
  }

  componentDidMount() {      
    //alert("b");
    return;
  }

  componentWillReceiveProps(nextProps) {        
    //alert("a");
    if(nextProps.match.params.employeeId) {
      this.setState({
        loadManager: this.createLoadManager()}, () => {
          if(nextProps.match.params.recruitmentVersionId) {
            //impiegato e trasformazione archiviata, carico la trasformazione archiviata
            var buildBreadcrumbEmployeeLinkProp = false;
            if(this.props && this.props.location && this.props.location.state && this.props.location.state.buildBreadcrumbEmployeeLink)
              buildBreadcrumbEmployeeLinkProp = this.props.location.state.buildBreadcrumbEmployeeLink;

            this.setState({
              urlParamEmployeeId: nextProps.match.params.employeeId}, () => {
                this.setState({
                  buildBreadcrumbEmployeeLink: buildBreadcrumbEmployeeLinkProp,
                  urlParamRecruitmentVersionId: nextProps.match.params.recruitmentVersionId}, () => {
                    this.getEmployee();       
                    this.getRecruitment(false);
                  });
              });
          } else {
            //solo impiegato, carico l'ultima trasformazione aperta
            this.setState({
              urlParamEmployeeId: nextProps.match.params.employeeId}, () => {
                this.getEmployee();
                this.getRecruitment(true);
              });  
          }          
        });
    }
    else
      this.props.history.push({pathname: '/recruitments'});
  }

  createLoadManager() {    
    var l = {
      isEmployeeLoaded: false,
      isRecruitmentLoaded: false,
      isLoadingComplete : function() { 
        return (this.isEmployeeLoaded && this.isRecruitmentLoaded);
      }
    };

    return l;
  }

  createArchivedRecruitmentsDataSource(recruitmentId) {   
    var store = createStore({
      key: 'recruitmentVersionId',
      loadUrl: Global.WebApiRootUrl + '/recruitments/archived/' + recruitmentId,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = { withCredentials: true };
        ajaxOptions.headers = { Authorization: `Bearer ${this.state.loggedUser.token}`}
      },
      onAjaxError: ({ xhr, error}) => {
        Global.CheckResponseAuth(xhr);
      }
    });

    return store;
  }

  getEmployee() {
    let emploeeyPromise = API.getEmployeeWithEmployer(this.state.loggedUser.token, this.state.urlParamEmployeeId);
    let emploeey = emploeeyPromise.then(result => {
      let lm = this.state.loadManager;
      lm.isEmployeeLoaded = true;

      this.setState({
        emploeeyRecruitmentData: result,
        loadManager: lm
      });

      return result; 
    })
    .catch(error => {
      console.log("Error: ", error)
      return null;
    });
  }

  getRecruitment(getLastOpenRecruitment) {
    let promise = null;
    
    if (getLastOpenRecruitment)
      promise = API.getEmployeeRecruitment(this.state.loggedUser.token, this.state.urlParamEmployeeId);
    else
      promise = API.getEmployeeArchivedRecruitment(this.state.loggedUser.token, this.state.urlParamEmployeeId, this.state.urlParamRecruitmentVersionId);
      
    let emploeey = promise.then(result => {
      //console.log("Last Recruitment", result.base.recruitmentId);
      
      var lm = this.state.loadManager;
      lm.isRecruitmentLoaded = true;

      this.setState({
        recruitment: result,
        loadManager: lm,
        archivedRecruitmentsDataSource: this.createArchivedRecruitmentsDataSource(result.base.recruitmentId),
      });
      
      return result; 
    })
    .catch(error => {
      console.log("Error: ", error)
      return null;
    });
  }

  onExportRecruitmentToPDFButtonClick() {
    this.showLoadPanel();

    const url =  Global.WebApiRootUrl + "/recruitments/exportRecruitmentToPDF";

    var t = this.state.recruitment;
    t.userId = this.state.loggedUser.userId;
    t.year = this.state.year;    

    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(t),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.state.loggedUser.token}`
      }
    };

    fetch(url, requestOptions)
      .then(response => {       
        if(response.ok) return response.blob();        
        Global.CheckResponseAuth(response);
      })
      .then(blob => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;

        var slug = this.state.emploeeyRecruitmentData.employer.lastName + '-' + this.state.emploeeyRecruitmentData.employer.firstName + '-' + this.state.emploeeyRecruitmentData.employer.fiscalCode;
        slug = slug.replace(/\s/g,'').toLowerCase();

        a.download = slug + ".pdf";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();    
        a.remove();  //afterwards we remove the element again 
        
        this.hideLoadPanel();
      })
      .catch(error => { this.hideLoadPanel(); notify(error, 'error', 5000); });
  }

  onDeleteRecruitmentButtonClick() {
    let result = confirm("Confermi l'eliminazione dell'assunzione (si eliminerà anche il dipendente, con il relativo codice inps, e le relative assunzioni archiviate)?", "Conferma eliminazione");
    result.then((dialogResult) => {
      if (dialogResult) {
        this.showLoadPanel();

        var url =  Global.WebApiRootUrl + "/recruitments/delete";

        var t = this.state.recruitment;
        t.userId = this.state.loggedUser.userId;

        const requestOptions = {
          method: 'DELETE',
          body: JSON.stringify(t),
          headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${this.state.loggedUser.token}`
          }
        };
    
        return fetch(url, requestOptions)
          .then(response => {
            if(response.ok) return response.json();
            Global.CheckResponseAuth(response);
            return null;
          })
          .then(data => {
            this.hideLoadPanel();           
            Notifier.SetGlobalToastMessage(data.message, (data.success ? 'success' : 'error'));              
            this.props.history.push({pathname: '/recruitments'});
          })
          .catch(error => { this.hideLoadPanel(); notify(error, 'error', 5000); }); 
        }  
    });
    return null;
  }

  onCloseRecruitmentButtonClick() {
    let result = confirm("Confermi la chiusura dell'assunzione?", "Conferma chiusura");
    result.then((dialogResult) => {
      if (dialogResult) {
        this.showLoadPanel();

        var url =  Global.WebApiRootUrl + "/recruitments/close";

        var t = this.state.recruitment;
        t.userId = this.state.loggedUser.userId;

        const requestOptions = {
          method: 'POST',
          body: JSON.stringify(t),
          headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${this.state.loggedUser.token}`
          }
        };
    
        return fetch(url, requestOptions)
          .then(response => {
            if(response.ok) return response.json();
            Global.CheckResponseAuth(response);
            return null;
          })
          .then(data => {        
            this.hideLoadPanel();      
            //Notifier.SetGlobalToastMessage(data.message, (data.success ? 'success' : 'error'));              
            notify(data.message, data.success ? 'success' : 'error', 5000);
            this.props.history.push({pathname: '/recruitments'});
          })
          .catch(error => { this.hideLoadPanel(); notify(error, 'error', 5000); }); 
        }  
    });
    return null;
  }

  validate() {
    if(!this.state.recruitment.data.takingChargeDate || !this.state.recruitment.data.recruitmentDate)
      return {
        isValid: false,
        message: 'Data di presa in carico e Data di assunzione sono obbligatorie',
        notifyType: 'error'
      }
      
    return {
      isValid: true,
      message: 'OK',
      notifyType: 'success'
    }     
  }

  onSaveButtonClick(e) { 
    var valid = this.validate();
    if(valid.isValid) {  
      this.showLoadPanel();

      var url =  Global.WebApiRootUrl + "/recruitments/update";

      var t = this.state.recruitment;
      t.userId = this.state.loggedUser.userId;

      const requestOptions = {
        method: 'POST',
        body: JSON.stringify(t),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.state.loggedUser.token}`
        }
      };
  
      return fetch(url, requestOptions)
        .then(response => {
            if(response.ok) return response.json();
            Global.CheckResponseAuth(response);
            return null;
        })
        .then(data => {
            //console.log(data); 
            this.hideLoadPanel();

            notify(data.message, data.success ? 'success' : 'error', 5000);
      
            if(data.success) {
              this.setState({
                recruitment: data.data,
                archivedRecruitmentsDataSource: this.createArchivedRecruitmentsDataSource(data.data.base.recruitmentId)
              });
            } 
        })
        .catch(error => { this.hideLoadPanel(); notify(error, 'error', 5000); });
    } else {      
      notify(valid.message, valid.notifyType, 5000);
    }

    return null;
  }

  showLoadPanel() {
    this.setState({
      isLoadPanelVisible: true
    });
  }

  hideLoadPanel() {
    this.setState({
      isLoadPanelVisible: false
    });
  }

  onFormSubmit(e) {
    if(e && e.onSaveButtonClick)
      e.onSaveButtonClick();
    else
      alert("Si è verificato un errore, non è possibile procedere.")    
  }

  renderView() {
    if(!this.state.loadManager.isLoadingComplete())
      return this.renderLoader();
    else
      return this.renderContent();
  }

  renderLoader() {
    return (
      <React.Fragment>
        <div className={'content-block ac-page-title'}><Link to="/recruitments">Assunzioni</Link> &gt; ... </div>
        <div className={'content-block'}>
          <div className={'dx-card responsive-paddings'}>
            <div className={'ac-centered'}><LoadIndicator id="large-indicator" height={60} width={60} /></div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderBreadcrumb() {
    if(!this.state.urlParamRecruitmentVersionId) {
      //path if: current recruitment - else: archived recruitment
      return (
        <div>
          <div>
            <Link to="/recruitments">Assunzioni</Link> &gt; {this.state.emploeeyRecruitmentData.lastName} {this.state.emploeeyRecruitmentData.firstName} ({this.state.emploeeyRecruitmentData.employer.lastName} {this.state.emploeeyRecruitmentData.employer.firstName})
          </div>
          <div className={'ac-margin-top-05'}>
            <div className={'ac-float-letf'}>
              {this.state.recruitment.base.closedAt ? <div className={'ac-container-closed-item'}>Chiusa</div> : <div className={'ac-container-opened-item'}>Aperta</div>}
            </div>
            <div className={'ac-float-right'}>
              
              {
                this.state.recruitment.isNewRecord == false 
                  ? 
                    <span> 
                      <Button width={120} text="Chiudi" type="default" stylingMode="outlined" onClick={this.onCloseRecruitmentButtonClick} />&nbsp;
                      <Button width={120} text="Elimina" type="default" stylingMode="outlined" onClick={this.onDeleteRecruitmentButtonClick} />&nbsp;
                      <Button width={130} text="Genera PDF" type="default" stylingMode="outlined" onClick={this.onExportRecruitmentToPDFButtonClick} />&nbsp;
                    </span> 
                  : 
                    <span></span>
              }
              <Button className="ac-button-default" width={120} text="Salva" type="default" stylingMode="contained" useSubmitBehavior={true} onClick={this.onSaveButtonClick} />
            </div>
            <div className={'ac-clear-both'}></div>
          </div>
        </div>);
    } else {
      var returnPathName = '/recruitments/' + this.state.emploeeyRecruitmentData.employeeId.toLowerCase();
      if(this.state.recruitment.base.closedAt)
        returnPathName = '/recruitments/' + this.state.emploeeyRecruitmentData.employeeId.toLowerCase() + '/archived/' + this.state.recruitment.lastClosedRecruitmentVersionId.toLowerCase()

      return (
        <div>
          <div>
            <span><Link to="/recruitments">Assunzioni</Link> &gt; </span>
            <Link to={{ pathname: returnPathName, state: { archivedReload: false } }} >
              <span> {this.state.emploeeyRecruitmentData.lastName} {this.state.emploeeyRecruitmentData.firstName}</span>
              <span> ({this.state.emploeeyRecruitmentData.employer.lastName} {this.state.emploeeyRecruitmentData.employer.firstName})</span>
            </Link>

            <span> &gt; {DateConversion.GetDateFromISOString(this.state.recruitment.data.archivedAt, false)}</span>
            <span> ({this.state.recruitment.archivedByByUserFullName})</span>
          </div>
          <div>
            <div className={'ac-float-letf'}>
              {this.state.recruitment.base.closedAt ? <div className={'ac-container-closed-item'}>Chiusa</div> : <div className={'ac-container-archived-item'}>Archiviata</div>}
            </div>
            <div className={'ac-float-right'}>
              <Button width={120} text="Elimina" type="default" stylingMode="outlined" onClick={this.onDeleteRecruitmentButtonClick} />&nbsp;
              <Button width={130} text="Genera PDF" type="default" stylingMode="outlined" onClick={this.onExportRecruitmentToPDFButtonClick} />
            </div>
            <div className={'ac-clear-both'}></div>
          </div>
        </div>);
    }
  }

  renderContent() {
    return (
      <React.Fragment>
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          visible={this.state.isLoadPanelVisible}
          showIndicator={true}
          shading={true}
          showPane={true}
          closeOnOutsideClick={false}
        />

        <div className={'content-block ac-page-title'}>
          {this.renderBreadcrumb()}
        </div>
     
        <div className={'ac-margin-top-10 content-block'}>
          <Box
            direction="row"
            width="100%">
            <Item ratio={3}>
              <div className={'dx-card responsive-paddings'}>
                <div className="ac-card-title">Anagrafiche datore/dipendente</div>
                <div className={'ac-margin-top-05'}> 
                  <EmployerDetails title="Datore di lavoro" employerId={this.state.emploeeyRecruitmentData.employerId} lightView={true} />
                </div>
                <div className={'ac-margin-top-20'}> 
                  <EmployeeDetails title="Dipendente" employeeId={this.state.emploeeyRecruitmentData.employeeId} lightView={true} />
                </div>
              </div>
            </Item>
            <Item ratio={2}>
              <div className={'dx-card responsive-paddings ac-margin-left-10'}>
              <div className="ac-card-title">Storico trasformazioni</div>
                <div id="tramsformation-archived-grid">
                  <DataGrid 
                    id="gridContainer"
                    ref={(ref) => this.archivedRecruitmentsDataGrid = ref}
                    className={'dx-card wide-card'} 
                    dataSource={this.state.archivedRecruitmentsDataSource} 
                    remoteOperations={true}
                    showBorders={true} 
                    focusedRowEnabled={false} 
                    defaultFocusedRowIndex={0} 
                    columnAutoWidth={true} 
                    columnHidingEnabled={true}    
                    height={239}       
                    rowRender={ArchivedRecruitmentsDataGridRow}
                  >
                    <Paging defaultPageSize={4} />
                    <Pager showPageSizeSelector={false} showInfo={true} />            
                    <FilterRow visible={false} />
                    <HeaderFilter visible={false} />
                    <Editing mode="popup" allowUpdating={false} allowDeleting={false} allowAdding={false} />
                    <Sorting mode="multiple" />                                
                    
                    <Column dataField={'employeeId'} hidingPriority={1} visible={false} />
                    <Column dataField={'recruitmentId'} hidingPriority={1} visible={false} />
                    <Column dataField={'recruitmentVersionId'} hidingPriority={1} visible={false} />
                    
                    <Column dataField={'archivedAt'} caption={'Data archiviazione'} dataType={'date'} format={'dd/MM/yyyy hh:mm:ss'} hidingPriority={4} defaultSortOrder="desc" />
                    <Column dataField={'archivedByUserFullName'} caption={'Archiviato da'} hidingPriority={3} />
                  </DataGrid>
                </div> 
              </div>
            </Item>
          </Box>
          
          <div className={'tramsformation-form-container dx-card responsive-paddings'}>
            <form action="#" className={'report-filter-form'} onSubmit={ e => {e.preventDefault(); this.onFormSubmit(this);}}>
              <Form colCount={3} id="form2" formData={this.state.recruitment.data} >
                
                <GroupItem colSpan={3} colCount={3} >
                  <SimpleItem dataField="takingChargeDate" editorType="dxDateBox" editorOptions={{maxLength: 25, readOnly: (this.state.urlParamRecruitmentVersionId != null)}} >
                    <Label text="Data presa in carico" />
                    <RequiredRule message="Data presa in carico è obbligatoria" />
                  </SimpleItem>                
                  <SimpleItem dataField="recruitmentDate" editorType="dxDateBox" editorOptions={{maxLength: 25, readOnly: (this.state.urlParamRecruitmentVersionId != null)}} >
                    <Label text="Data di assunzione" />
                    <RequiredRule message="Data di assunzione è obbligatoria" />
                  </SimpleItem>                
                  <SimpleItem dataField="level" editorType="dxTextBox" editorOptions={{maxLength: 25, readOnly: (this.state.urlParamRecruitmentVersionId != null)}} >
                    <Label text="Livello" />
                  </SimpleItem>
                </GroupItem>

                <GroupItem colSpan={3} colCount={1} >
                  <SimpleItem dataField="workingTime" editorType="dxTextArea" editorOptions={{maxLength: 256, readOnly: (this.state.urlParamRecruitmentVersionId != null)}} >
                      <Label text="Orario di lavoro" />
                    </SimpleItem>
                </GroupItem>

                <GroupItem colSpan={3} colCount={1} >
                  <SimpleItem dataField="notes" editorType="dxTextArea" editorOptions={{maxLength: 256, readOnly: (this.state.urlParamRecruitmentVersionId != null)}} >
                      <Label text="Note" />
                    </SimpleItem>
                </GroupItem>

                <GroupItem colSpan={3} colCount={6} >
                  <SimpleItem dataField="inpsDelegation" editorType="dxCheckBox" editorOptions={{readOnly: (this.state.urlParamRecruitmentVersionId != null)}} >
                    <Label text="Delega inps" />
                  </SimpleItem>
                  <SimpleItem dataField="inpsCommunication" editorType="dxCheckBox" editorOptions={{readOnly: (this.state.urlParamRecruitmentVersionId != null)}} >
                    <Label text="Comunicaz. inps" />
                  </SimpleItem>                  
                  <SimpleItem dataField="processedPay" editorType="dxCheckBox" editorOptions={{readOnly: (this.state.urlParamRecruitmentVersionId != null)}} >
                    <Label text="Paga elaborata" />
                  </SimpleItem>                  
                </GroupItem>
              </Form>

            </form>      
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {    
    return this.renderView();    
  }
};

export default Recruitment;