import React from 'react';

import { createStore } from 'devextreme-aspnet-data-nojquery';
import { SelectBox } from 'devextreme-react/select-box';
import Form, { Item, SimpleItem, Label, GroupItem, RequiredRule } from 'devextreme-react/form';
import notify from "devextreme/ui/notify";

import * as Global from '../../global'
import * as API from '../../api/api'

import './employee-editor.scss';
 
export class EmployeeEditor extends React.Component {
  constructor(props) {
    super(props);

    if (!this.props.loggedUser)
      Global.Logout();

    this.state = {      
      loggedUser: this.props.loggedUser,
      selectedEmployerId: null,
      newEmployer: this.createNewEmployer('', '', '', ''),
      newEmployee: this.createNewEmployee('', '', '', ''),      
      employers: null,            
      employees: null,
    };        

    this.onSelectBoxEmployersValueChanged = this.onSelectBoxEmployersValueChanged.bind(this);
    this.onSelectBoxEmployeesValueChanged = this.onSelectBoxEmployeesValueChanged.bind(this);
  }

  componentDidMount() {
    if (!this.props.loggedUser)
      Global.Logout();

    this.getAllEmployersByCityId();
    this.getAllEmployeesByCityId();
  }

  createNewEmployer(firstName, lastName, fiscaleCode, memberCode) {
    return {
      firstName: firstName,
      lastName: lastName,
      fiscaleCode: fiscaleCode,
      memberCode: memberCode,
    };
  }

  validate() {
    if((this.state.selectedEmployerId === null || this.state.selectedEmployerId === '') && (this.state.newEmployer.firstName === '' || this.state.newEmployer.lastName === '' || this.state.newEmployer.fiscaleCode === '')) 
      return {
        isValid: false,
        message: 'Selezionare un datore di lavoro già esistente oppure inserire tutti i dati del nuovo datore di lavoro',
        notifyType: 'error'
      }
      
    if(this.state.newEmployee.firstName === '' || this.state.newEmployee.lastName === '' || this.state.newEmployee.fiscaleCode === '' || this.state.newEmployee.inpsCode === '')
      return {
        isValid: false,
        message: 'Inserire tutti i dati del nuovo dipendente',
        notifyType: 'error'
      }

    return {
      isValid: true,
      message: 'OK',
      notifyType: 'success'
    }     
  }

  getEmployeeWithEmployer() {
    return {
      cityId: this.state.loggedUser.cityId,
      employerId: this.state.selectedEmployerId,
      employeeId: null,
      employeeFirstName: this.state.newEmployee.firstName,
      employeeFiscalCode: this.state.newEmployee.fiscaleCode,
      employeeInpsCode: this.state.newEmployee.inpsCode,
      employeeLastName: this.state.newEmployee.lastName,
      employerFirstName: (this.state.selectedEmployerId !== null && this.state.selectedEmployerId !== '' ? '' : this.state.newEmployer.firstName),
      employerFiscalCode: (this.state.selectedEmployerId !== null && this.state.selectedEmployerId !== '' ? '' : this.state.newEmployer.fiscaleCode),
      employerLastName: (this.state.selectedEmployerId !== null && this.state.selectedEmployerId !== '' ? '' : this.state.newEmployer.lastName),
      employerMemberCode: (this.state.selectedEmployerId !== null && this.state.selectedEmployerId !== '' ? null : this.state.newEmployer.memberCode),
    };
  }

  save() {
    var valid = this.validate();
    if(valid.isValid) {
      var url =  Global.WebApiRootUrl + "/stakeholders/createEmployeeWithEmployer";

      const requestOptions = {
        method: 'POST',
        body: JSON.stringify(this.getEmployeeWithEmployer()),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.state.loggedUser.token}`
        }
      };
  
      return fetch(url, requestOptions)
        .then(response => {
            if(response.ok) return response.json();
            this.props.onSaveEnd(null);
            Global.CheckResponseAuth(response);
            return null;
        })
        .then(data => {
            //this.hideLoadPanel();

            notify(data.message, data.success ? 'success' : 'error', 5000);

            //if success, notify to parent with all data to create new recruitment
            if(data.success) 
              this.props.onSaveEnd(data.data.employeeId); 
            else
              this.props.onSaveEnd(null);
        })
        .catch(error => { this.props.onSaveEnd(null); notify(error, 'error', 5000); });

    } else {
      this.props.onSaveEnd(null);
      notify(valid.message, valid.notifyType, 5000);
    }

    return;
  }

  createNewEmployee(firstName, lastName, inpsCode, fiscaleCode) {
    return {
      firstName: firstName,
      lastName: lastName,
      inpsCode: inpsCode,
      fiscaleCode: fiscaleCode,
    };
  }

  onSelectBoxItemRender(data) {    
    return <span>{data.lastName} {data.firstName} ({data.fiscalCode})</span>;
  }

  onSelectBoxDisplayExpr(data) {
    var expr = "";

    if(data)
      expr = data.lastName + ' ' + data.firstName + ' (' + data.fiscalCode +')';
    
    return expr;
  }

  onSelectBoxEmployersValueChanged(e) {    
    var selEmployerId = null;
    if(e && e.value && e.value.employerId) 
      selEmployerId = e.value.employerId;
    
    this.setState({
      selectedEmployerId: selEmployerId
    });      
  }

  onSelectBoxEmployeesValueChanged(e) {  
    var employee = this.createNewEmployee('', '', '', '');
    
    if(e && e.value && e.value.firstName && e.value.lastName && e.value.fiscalCode) 
      employee = this.createNewEmployee(e.value.firstName, e.value.lastName, '', e.value.fiscalCode);
    
    this.setState({
      newEmployee: employee,
    });
  }

  getAllEmployersByCityId() {
    let employersPromise = API.getAllEmployersByCityId(this.state.loggedUser.token, this.state.loggedUser.cityId, 'true');
    employersPromise.then(result => {
      this.setState({
        employers: result
      });  

      return result; 
    })
    .catch(error => { notify(error, 'error', 5000); });
  }

  getAllEmployeesByCityId() {
    let employeesPromise = API.getAllEmployeesByCityId(this.state.loggedUser.token, this.state.loggedUser.cityId, 'true');
    employeesPromise.then(result => {
      this.setState({
        employees: result
      });  

      return result; 
    })
    .catch(error => { notify(error, 'error', 5000); });
  }
 
  render() {
    return (
      <div>
        <div className={'ac-margin-top-10 content-block'}>
          <div className={'dx-card responsive-paddings'}>
            <div class="ac-card-title"><b>Datore</b></div>

            <div class="ac-card-title ac-margin-top-10">Selezionare un datore di lavoro già esistente</div>              
            <div class="ac-margin-top-10">
              <SelectBox 
                items={this.state.employers}
                displayExpr={this.onSelectBoxDisplayExpr}
                itemRender={this.onSelectBoxItemRender}
                searchEnabled={true}
                searchMode="contains"
                searchExpr={['lastName', 'firstName', 'fiscalCode']}
                searchTimeout={300}
                minSearchLength={3}
                showDataBeforeSearch={false}
                showClearButton={true}
                placeholder="Cerca per cognome, nome o codice fiscale (inserire almeno tre caratteri)..."                
                onValueChanged={this.onSelectBoxEmployersValueChanged} />
            </div>

            <div class="ac-card-title ac-margin-top-30">Oppure inserire un nuovo datore di lavoro (consentito solo se non si seleziona un datore già esistente)</div>              
            <div>          
              <Form colCount={1} id="newEmployerForm" formData={this.state.newEmployer}  >                  
                <GroupItem  colSpan={1} colCount={4}>
                  <SimpleItem dataField="lastName" editorType="dxTextBox" editorOptions={{maxLength: 50, disabled: (this.state.selectedEmployerId), inputAttr: {'style': 'text-transform: uppercase'}}} >
                    <Label text="Cognome" />
                    <RequiredRule message="Il cognome è obbligatorio" />
                  </SimpleItem>
                  <SimpleItem dataField="firstName" editorType="dxTextBox" editorOptions={{maxLength: 50, disabled: (this.state.selectedEmployerId), inputAttr: {'style': 'text-transform: uppercase'}}} >
                    <Label text="Nome" />
                    <RequiredRule message="Il nome è obbligatorio" />
                  </SimpleItem>                    
                  <SimpleItem dataField="fiscaleCode" editorType="dxTextBox" editorOptions={{maxLength: 25, disabled: (this.state.selectedEmployerId), inputAttr: {'style': 'text-transform: uppercase'}}} >
                    <Label text="Codice fiscale" />
                    <RequiredRule message="Il codice fiscale è obbligatorio" />
                  </SimpleItem>
                  <SimpleItem dataField="memberCode" editorType="dxNumberBox" editorOptions={{maxLength: 10, disabled: (this.state.selectedEmployerId), inputAttr: {'style': 'text-transform: uppercase'}, max: 999999999999 }} >
                    <Label text="Codice socio" />
                  </SimpleItem>
                </GroupItem>
              </Form>          
            </div>
          </div>
        </div>

        <div className={'ac-margin-top-10 content-block'}>
          <div className={'dx-card responsive-paddings'}>
            <div class="ac-card-title"><b>Dipendente</b></div>

            <div class="ac-card-title ac-margin-top-10">Selezionare un dipendente già esistente per riportare cognome, nome e codice fiscale</div>                          
            <div class="ac-margin-top-10">
              <SelectBox 
                items={this.state.employees}
                displayExpr={this.onSelectBoxDisplayExpr}
                itemRender={this.onSelectBoxItemRender}
                searchEnabled={true}
                searchMode="contains"
                searchExpr={['lastName', 'firstName', 'fiscalCode']}
                searchTimeout={300}
                minSearchLength={3}
                showDataBeforeSearch={false}
                showClearButton={true}
                placeholder="Cerca per cognome, nome o codice fiscale (inserire almeno tre caratteri)..."
                onValueChanged={this.onSelectBoxEmployeesValueChanged} />
            </div>

            <div class="ac-card-title ac-margin-top-30">Oppure inserire manualmente i dati del nuovo dipendente</div>              
            <div>          
              <Form colCount={1} id="newEmployeeForm" formData={this.state.newEmployee}  >                  
                <GroupItem  colSpan={1} colCount={4}>
                  <SimpleItem dataField="lastName" editorType="dxTextBox" editorOptions={{maxLength: 50, inputAttr: {'style': 'text-transform: uppercase'}}} >
                    <Label text="Cognome" />
                    <RequiredRule message="Il cognome è obbligatorio" />
                  </SimpleItem>
                  <SimpleItem dataField="firstName" editorType="dxTextBox" editorOptions={{maxLength: 50, inputAttr: {'style': 'text-transform: uppercase'}}} >
                    <Label text="Nome" />
                    <RequiredRule message="Il nome è obbligatorio" />
                  </SimpleItem>                    
                  <SimpleItem dataField="fiscaleCode" editorType="dxTextBox" editorOptions={{maxLength: 25, inputAttr: {'style': 'text-transform: uppercase'}}} >
                    <Label text="Codice fiscale" />
                    <RequiredRule message="Il codice fiscale è obbligatorio" />
                  </SimpleItem>
                  <SimpleItem dataField="inpsCode" editorType="dxTextBox" editorOptions={{maxLength: 25, inputAttr: {'style': 'text-transform: uppercase'}}} >
                    <Label text="Codice inps" />
                    <RequiredRule message="Il codice inps è obbligatorio" />
                  </SimpleItem>
                </GroupItem>
              </Form>          
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default EmployeeEditor;