export const navigation = [
  {
    text: 'Home',
    path: '/home',
    icon: 'home',
    visible: true,
    adminOnly: false,
    testerOnly: false,
  },
  /*
  {
    text: 'Examples',
    icon: 'folder',
    items: [
      {
        text: 'Profile',
        path: '/profile'
      },
      {
        text: 'Tasks',
        path: '/tasks'
      }
    ]
  }, 
  */
  {
    text: 'Inserimento variazioni',
    path: '/stakeholders',
    icon: 'rename',
    visible: true,
    adminOnly: false,
    testerOnly: false,
  },
  {
    text: 'Report',
    path: '/report',
    icon: 'exportpdf',
    visible: true,
    adminOnly: false,
    testerOnly: false,
  },
  {
    text: 'Trasformazioni',
    path: '/transformations',
    icon: 'decreaselinespacing',
    visible: true,
    adminOnly: false,
    testerOnly: false,
  },   
  {
    text: 'Cessazioni',
    path: '/expirations',
    icon: 'exportxlsx',
    visible: true,
    adminOnly: false,
    testerOnly: false,
  },
  {
    text: 'Assunzioni',
    path: '/recruitments',
    icon: 'exportselected',
    visible: true,
    adminOnly: false,
    testerOnly: false,
  },
  {
    text: 'Importazione anagrafica',
    path: '/import',
    icon: 'increaseindent',
    visible: false,
    adminOnly: true,
    testerOnly: false,
  },
  {
    text: 'Gestione utenti',
    path: '/users',
    icon: 'group',
    visible: false,
    adminOnly: true,
    testerOnly: false,
  }, 
  {
    text: 'Verifica duplicati',
    path: '/checkdata',
    icon: 'fieldchooser',
    visible: false,
    adminOnly: true,
    testerOnly: true,
  }
];