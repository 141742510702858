import React from 'react';

import Button from 'devextreme-react/button';
import Popup from 'devextreme-react/popup';
import FileUploader from 'devextreme-react/file-uploader';
import CheckBox from 'devextreme-react/check-box';
import SelectBox from 'devextreme-react/select-box';
import { LoadPanel } from 'devextreme-react/load-panel';

import * as Global from '../../global'

import './import-upload-popup.scss';
 
const renderContent = () => {
    return (
        <p>Importazione</p>
    );
};
 
export class ImportUploadPopup extends React.Component {
  constructor(props) {
      super(props);

      this.state = {
          currentCity: {},
          isPopupVisible: false,
          
          loadPanelVisible: false,
          showIndicator: true,
          shading: true,
          showPane: true
      };

      this.showPopup = this.showPopup.bind(this);
      this.hidePopup = this.hidePopup.bind(this);

      this.hideLoadPanel = this.hideLoadPanel.bind(this);
      this.onShowIndicatorChange = this.onShowIndicatorChange.bind(this);
      this.onShadingChange = this.onShadingChange.bind(this);
      this.onShowPaneChange = this.onShowPaneChange.bind(this);

      this.formElement = React.createRef();
      this.onClick = this.onClick.bind(this);
  }

  onClick() {
    //Notify('Uncomment the line to enable sending a form to the server.');

    this.setState({
      loadPanelVisible: true
    });

    this.formElement.current.submit();
  }

  componentDidMount() {
    this.props.onRef(this)
  }
  componentWillUnmount() {
    this.props.onRef(undefined)
  }

  showPopup(city) {
    //console.log(city);
    this.setState({
        currentCity: city,
        isPopupVisible: true
    });
  }

  hidePopup() {
      this.setState({
          isPopupVisible: false
      });
  }

  hideLoadPanel() {
    this.setState({
      loadPanelVisible: false
    });
  }

  onShowIndicatorChange(e) {
    this.setState({
      showIndicator: e.value
    });
  }

  onShadingChange(e) {
    this.setState({
      shading: e.value
    });
  }

  onShowPaneChange(e) {
    this.setState({
      showPane: e.value
    });
  }

  render() {
    const simpleProducts = [
      (new Date().getFullYear()) - 1,
      (new Date().getFullYear()),
      (new Date().getFullYear()) + 1,
    ];

    const actionUrl = Global.WebApiRootUrl + "/Import/FileUpload";

    return (
      <div>
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          onHiding={this.hideLoadPanel}
          visible={this.state.loadPanelVisible}
          showIndicator={this.state.showIndicator}
          shading={this.state.shading}
          showPane={this.state.showPane}
          closeOnOutsideClick={false}
        />

          <Popup
              title="Importazione anagrafica"
              visible={this.state.isPopupVisible}
              onHiding={this.hidePopup}
              width={500} height={540}
          >
            <form id="form" ref={this.formElement} method="post" action={actionUrl} encType="multipart/form-data" >
              <input type="hidden" id="cityId" name="cityId" value={this.state.currentCity.cityId} />
              <input type="hidden" id="cityName" name="cityName" value={this.state.currentCity.name} />  
              <h3>Città: {this.state.currentCity.name}</h3>

              <div className="select">
                <div>Anno:</div>
                <div><SelectBox name="year" items={simpleProducts} defaultValue={simpleProducts[1]}/></div>
              </div>

              <div className="checkbox">
                <CheckBox name="original" text="Importa come primaria" />
              </div>
              
              <div className="fileuploader-container">
                <FileUploader selectButtonText="Seleziona file excel da importare" labelText="" accept=".xlsx, .xls" uploadMode="useForm" />
              </div>
              
              <Button className="button" text="Importa" type="default" onClick={this.onClick} />
            </form>
          </Popup>
      </div>
    );
  }
}