//import defaultUser from '../utils/default-user';

import * as Global from '../global'

function login(email, password, rememberMe) {    
  var url =  Global.WebApiLoginUrl + "/Auth/Login";
  
  /*
  const payload = { 
    grant_type: 'password',
    Email: email,
    Password: password
  };
  */

 const payload = {   
    Email: email,
    Password: password,
    RememberMe: rememberMe
  };
 

  /*
  var formData = new FormData();
  formData.append("values", JSON.stringify(payload));
  */
  
  //const loginInfo = `${data}&grant_type=password`;
  return fetch(url, {
    method: 'POST',    
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(payload),
  }).then((response) => response.json())
    .then(user => {      
      if (user) {
        user.avatarUrl = 'https://presenze.assindatcolf.mi.it/user-profile-icon.png';        
        localStorage.setItem('ac-user', JSON.stringify(user));
        localStorage.setItem('ac-year', JSON.stringify(new Date().getFullYear()));
        return user;
      } else {
        return null;
      }
    });

  return null;
}

export async function signIn(email, password, rememberMe) {    
  try {
    let userLogin = await login(email, password, rememberMe)

    if(userLogin) {      
      return {
          isOk: true,
          data: userLogin
      };
    } else {
      return {
        isOk: false,
        message: "Autenticazione fallita"
      };
    }    
  }
  catch {
    return {
      isOk: false,
      message: "Authentication failed"
    };
  }
}

export async function getUser() {  
  try {
    // Send request
    let user = JSON.parse(localStorage.getItem('ac-user'));

    //console.log("getUser", getUser);

    if (user) {
      return {
        isOk: true,
        data: user
      };
    } else {
      return {
        isOk: false,
        data: null
      };
    }
  }
  catch {
    return {
      isOk: false
    };
  }
}

export async function createAccount(email, password) {
  try {
    // Send request
    console.log(email, password);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to create account"
    };
  }
}

export async function changePassword(email, recoveryCode) {
  try {
    // Send request
    console.log(email, recoveryCode);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to change password"
    }
  };
}

export async function resetPassword(email) {
  try {
    // Send request
    console.log(email);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to reset password"
    };
  }
}
