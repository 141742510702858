import React from 'react';
import { withRouter } from 'react-router-dom'
import { DataGrid, Sorting, Column, Pager, Paging, FilterRow, Editing, HeaderFilter, MasterDetail, Selection} from 'devextreme-react/data-grid';
import Form, { Item, SimpleItem, Label, GroupItem } from 'devextreme-react/form';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { Button } from 'devextreme-react/button';

import * as Global from '../../global'

import './recruitments-grid.scss';

export class RecruitmentsGrid extends React.Component {
  constructor(props) {
    super(props);

    if (!this.props.authProvider)
      Global.Logout();

    var storedGridFilter = this.initGridFilter();

    this.year = new Date().getFullYear();;
    if (this.props.year)
      this.year = this.props.year;

    this.userCityId = "00000000-0000-0000-0000-000000000000";
    if (this.props.userCityId)
      this.userCityId = this.props.userCityId;

    this.state = {
      recruitmentsDataSource: this.createRecruitmentsDataSource(),
      gridFilter: storedGridFilter,      
      authProvider: this.props.authProvider,
      toastManager: this.createToastManager(),
    };

    this.onRecruitmentsTableSelectionChanged = this.onRecruitmentsTableSelectionChanged.bind(this);
    this.onNewRecruitmentButtonclick = this.onNewRecruitmentButtonclick.bind(this);
    this.onSearchButtonClick = this.onSearchButtonClick.bind(this);  
    this.onResetButtonClick = this.onResetButtonClick.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);    
    this.dataGrid = null;
  }

  componentDidMount() {
    if (!this.props.authProvider)
      Global.Logout();
  }

  refreshDataGrid() {
    this.dataGrid.instance.refresh();
  }

  initGridFilter() {    
    var filter = this.createGridFilter();
    
    if (JSON.parse(localStorage.getItem(Global.LocalStorageKey.FilterRecruitments))) {
      filter = JSON.parse(localStorage.getItem(Global.LocalStorageKey.FilterRecruitments));
    }    

    return filter;
  }

  createGridFilter() {    
    var filter = {
      employerLastName: '',
      employerFiscalCode: '',
      employeeLastNameFirstLetterFrom: '',
      employeeLastNameFirstLetterTo: '',
      takingChargeDateFrom: '',
      takingChargeDateTo: '',
      recruitmentDateFrom: '',
      recruitmentDateTo: '',
      employeeExcludeDateEnd: 1,
      employeeWithrecruitments: 0,
      inpsDelegationOn: 0,
      inpsDelegationOff: 0,
      inpsCommunicationOn: 0,
      inpsCommunicationOff: 0,
      processedPayOn: 0,
      processedPayOff: 0,
    };

    return filter;
  }

  createFilterPayLoad(cityId, year, gridFilter) {
    const payload = { 
      cityId: cityId,
      year: year,
      employerLastName: gridFilter.employerLastName,
      employerFiscalCode: gridFilter.employerFiscalCode,
      employeeLastNameFirstLetterFrom: gridFilter.employeeLastNameFirstLetterFrom,
      employeeLastNameFirstLetterTo: gridFilter.employeeLastNameFirstLetterTo,
      employeeExcludeDateEnd: gridFilter.employeeExcludeDateEnd, 
      employeeWithRecruitments: gridFilter.employeeWithRecruitments, 
      takingChargeDateFrom: gridFilter.takingChargeDateFrom,
      takingChargeDateTo: gridFilter.takingChargeDateTo,
      recruitmentDateFrom: gridFilter.recruitmentDateFrom,
      recruitmentDateTo: gridFilter.recruitmentDateTo,
      inpsDelegationOn: gridFilter.inpsDelegationOn,
      inpsDelegationOff: gridFilter.inpsDelegationOff,
      inpsCommunicationOn: gridFilter.inpsCommunicationOn,
      inpsCommunicationOff: gridFilter.inpsCommunicationOff,
      processedPayOn: gridFilter.processedPayOn,
      processedPayOff: gridFilter.processedPayOff,     
    };

    return payload;
  }

  createToastManager() {    
    var t = {
      isVisible: false,
      type: 'info',
      message: ''
    };

    return t;
  }

  createRecruitmentsDataSource() {   
    var store = createStore({
      key: 'id',
      loadUrl: Global.WebApiRootUrl + '/recruitments/Find',
      onBeforeSend: (method, ajaxOptions) => {
        if (method == "load" ) {
            //ajaxOptions.method = "POST";
                        
            var payload = ajaxOptions.data;
            payload.values = JSON.stringify(this.createFilterPayLoad(this.userCityId, this.year, this.state.gridFilter));
            ajaxOptions.data = payload;
            
            //console.log(ajaxOptions.data);
        }        
        ajaxOptions.xhrFields = { withCredentials: true };
        ajaxOptions.headers = { Authorization: `Bearer ${this.state.authProvider.user.token}`}
      },
      onAjaxError: ({ xhr, error}) => {
        this.props.authProvider.checkAuth(xhr);
      }
    });

    return store;
  }

  onSearchButtonClick(e) {    
    this.setState({
      recruitmentsDataSource: this.createRecruitmentsDataSource(),
    });

    localStorage.setItem(Global.LocalStorageKey.FilterRecruitments, JSON.stringify(this.state.gridFilter));

    this.refreshDataGrid();    
  }

  onResetButtonClick(e) {    
    var filter = this.createGridFilter();

    this.filterForm.instance.resetValues();

    this.setState({
      recruitmentsDataSource: this.createRecruitmentsDataSource(),
      gridFilter: filter
    });

    localStorage.removeItem(Global.LocalStorageKey.FilterRecruitments);

    this.refreshDataGrid();
  }

  onFormSubmit(e) {
    if(e && e.onSearchButtonClick)
      e.onSearchButtonClick();
    else
      alert("Si è verificato un errore, non è possibile procedere.")    
  }

  onNewRecruitmentButtonclick(e) {    
    this.props.history.push({pathname: 'recruitments/new'});
  }

  onRecruitmentsTableSelectionChanged(e) {
    //console.log("selectionChanged", e);
    if(e.selectedRowsData[0].state == "Chiusa") {
      //console.log("selectionChanged chiusa");
      //pathname: '/recruitments/' + rowInfo.data.employeeId.toLowerCase() + /archived/ + rowInfo.data.recruitmentVersionId.toLowerCase()
      this.props.history.push({pathname: 'recruitments/' + e.currentSelectedRowKeys[0].toLowerCase() + /archived/ + e.selectedRowsData[0].lastClosedRecruitmentVersionId.toLowerCase()});
    } else {
      //console.log("selectionChanged aperta");
      this.props.history.push({pathname: 'recruitments/' + e.currentSelectedRowKeys[0]});
    }      
  }

  onRecruitmentsTableRowPrepared(e) {
    //console.log("onRecruitmentsTableRowPrepared", e);
    
    //remove arrow if there is not recruitments
    if(e && e.rowType && e.rowType == "data" && e.data && e.data.closedRecruitmentsCount == 0) {      
      if(e.rowElement && e.rowElement.childNodes[0] && e.rowElement.childNodes[0].childNodes[0] && e.rowElement.childNodes[0].childNodes[0].style)
        e.rowElement.childNodes[0].childNodes[0].style.display = "none";      
    }    
  }

  recruitmentsTableStateCellRender(rowInfo) {
    //console.log("recruitmentsTableStateCellRender", rowInfo)

    var stateClass = "";
    if (rowInfo.data.state.toLowerCase() == "aperta")
      stateClass = "ac-grid-cell-opened-item";
    else if (rowInfo.data.state.toLowerCase() == "chiusa") 
      stateClass = "ac-grid-cell-closed-item";

    return (
      <span class={stateClass}>{rowInfo.data.state}</span>
      );
  }

  render() {    
    return (
      <div className={'content-block'}>
        
        <div className={'stakeholders-filter-container dx-card wide-card'}>
          <div className="stakeholders-filter-form-container">
            <form action="#" className={'stakeholders-filter-form'} onSubmit={ e => {e.preventDefault(); this.onFormSubmit(this);}}>
              <Form colCount={5} id="form2" formData={this.state.gridFilter} ref={(ref) => this.filterForm = ref} >                  
                <GroupItem colSpan={5} colCount={4} caption="Ricerca anagrafica per:" >
                  <SimpleItem dataField="employerLastName" editorType="dxTextBox" editorOptions={{maxLength: 25}}  >
                    <Label text="Cognome datore" />
                  </SimpleItem>
                  <SimpleItem dataField="employeeLastNameFirstLetterFrom" editorType="dxTextBox" editorOptions={{maxLength: 10}} >
                    <Label text="Iniziale cognome datore dal" />
                  </SimpleItem>
                  <SimpleItem dataField="employeeLastNameFirstLetterTo" editorType="dxTextBox" editorOptions={{maxLength: 10}} >
                    <Label text="Iniziale cognome datore al" />
                  </SimpleItem>
                  <SimpleItem dataField="employerFiscalCode" editorType="dxTextBox" editorOptions={{maxLength: 25}}  >
                    <Label text="Codice fiscale datore" />
                  </SimpleItem>
                </GroupItem>
                <GroupItem colSpan={5} colCount={4} >
                  <SimpleItem dataField="takingChargeDateFrom" editorType="dxDateBox" editorOptions={{maxLength: 25}} >
                    <Label text="Data presa in carico dal" />
                  </SimpleItem>
                  <SimpleItem dataField="takingChargeDateTo" editorType="dxDateBox" editorOptions={{maxLength: 25}} >
                    <Label text="Data presa in carico al" />
                  </SimpleItem>     
                  <SimpleItem dataField="recruitmentDateFrom" editorType="dxDateBox" editorOptions={{maxLength: 25}} >
                    <Label text="Data assunzione dal" />
                  </SimpleItem>
                  <SimpleItem dataField="recruitmentDateTo" editorType="dxDateBox" editorOptions={{maxLength: 25}} >
                    <Label text="Data assunzione al" />
                  </SimpleItem>
                </GroupItem>             
                
                <GroupItem colSpan={5} colCount={3} caption="Campi ON:" >                  
                  <SimpleItem dataField="inpsDelegationOn" editorType="dxCheckBox" >
                    <Label text="Delega inps" />
                  </SimpleItem>
                  <SimpleItem dataField="inpsCommunicationOn" editorType="dxCheckBox" >
                    <Label text="Comunicaz. inps" />
                  </SimpleItem>
                  <SimpleItem dataField="processedPayOn" editorType="dxCheckBox" >
                    <Label text="Paga elaborata" />
                  </SimpleItem>
                </GroupItem>

                <GroupItem colSpan={5} colCount={3} caption="Campi OFF:" >                  
                  <SimpleItem dataField="inpsDelegationOff" editorType="dxCheckBox" >
                    <Label text="Delega inps" />
                  </SimpleItem>
                  <SimpleItem dataField="inpsCommunicationOff" editorType="dxCheckBox" >
                    <Label text="Comunicaz. inps" />
                  </SimpleItem>
                  <SimpleItem dataField="processedPayOff" editorType="dxCheckBox" >
                    <Label text="Paga elaborata" />
                  </SimpleItem>
                </GroupItem>
              </Form>

              <div>
                <div className={'ac-float-letf'}>                  
                  <Button className="ac-button-default" width={120} text="Cerca" type="default" stylingMode="contained" useSubmitBehavior={true} />
                  <span> </span>
                  <Button width={155} text="Cancella filtri" type="default" stylingMode="outlined" onClick={this.onResetButtonClick} />
                </div>
                <div className={'ac-float-right'}>
                  <Button className="ac-button-default" width={185} text="Nuova assunzione" type="default" stylingMode="contained" onClick={this.onNewRecruitmentButtonclick} />
                </div>
              </div>
            </form>
          </div>
        </div>

        <div id="recruitments-grid">
          <DataGrid 
            id="gridContainer"
            keyExpr="Id"
            ref={(ref) => this.dataGrid = ref}
            className={'dx-card wide-card'} 
            dataSource={this.state.recruitmentsDataSource} 
            remoteOperations={true}
            showBorders={true} 
            focusedRowEnabled={true} 
            defaultFocusedRowIndex={0} 
            columnAutoWidth={true}             
            allowColumnResizing={true} 
            columnHidingEnabled={false}
            onSelectionChanged={this.onRecruitmentsTableSelectionChanged}
            onRowPrepared={this.onRecruitmentsTableRowPrepared} 
          >
            <Selection mode="single" />
            <Paging defaultPageSize={15} />
            <Pager showPageSizeSelector={true} showInfo={true} />            
            <FilterRow visible={false} />
            <HeaderFilter visible={false} />
            <Editing mode="popup" allowUpdating={false} allowDeleting={false} allowAdding={false} />
            <Sorting mode="multiple" />
                      
            <Column dataField={'id'}  visible={false} />
            <Column dataField={'recruitmentId'}  visible={false} />
            <Column dataField={'employerId'}  visible={false} />
            <Column dataField={'employeeId'}  visible={false} />

            <Column dataField={'employerLastName'} caption={'Cognome'}  defaultSortIndex={2} defaultSortOrder="asc" />
            <Column dataField={'employerFirstName'} caption={'Nome'}  defaultSortIndex={3} defaultSortOrder="asc" />

            <Column dataField={'employeeRegistryNotes'} caption={'Codice socio'} alignment='left' />
            <Column dataField={'employerFiscalCode'} caption={'Codice fiscale'} />
            <Column dataField={'employeeFullName'} caption={'Dipendente'} />
            <Column dataField={'employeeInpsCode'} caption={'Codice inps'} />

            <Column dataField={'state'} caption={'Stato'} cellRender={this.recruitmentsTableStateCellRender} defaultSortIndex={0} defaultSortOrder="asc" width={75} />

            <Column dataField={'takingChargeDate'} caption={'Data presa in carico'} dataType={'date'} format={'dd/MM/yyyy'}  width={125} visible={false} />
            <Column dataField={'recruitmentDate'} caption={'Data assunzione'} dataType={'date'} format={'dd/MM/yyyy'} defaultSortIndex={1} defaultSortOrder="desc" width={95} />

            <Column dataField={'inpsDelegation'} caption={'Delega inps'} width={63} />
            <Column dataField={'inpsCommunication'} caption={'Comunicaz. inps'} width={63} />            
            <Column dataField={'processedPay'} caption={'Paga elaborata'} width={63} />

          </DataGrid>
        </div> 
      </div>
    );
  }
}

export default withRouter(RecruitmentsGrid);