import React, { useRef } from 'react';

import { useAuth } from '../../contexts/auth'; 
import StakeholdersGrid from './stakeholders-grid';
import Header from '../../components/assindatcolf/header';

import * as Global from '../../global';

import './stakeholders.scss';

export default () => {
  const authProvider = useAuth(); 

  const stakeholdersGridRef = useRef(null);

  var cityId = "00000000-0000-0000-0000-000000000000";
  
  if (!localStorage.getItem('ac-year')) {
    localStorage.setItem('ac-year', JSON.stringify(new Date().getFullYear()));
  }
  var year = JSON.parse(localStorage.getItem('ac-year'));
  
  const user = useAuth(); 
  if (user) { 
    if(user.user.cityId) {
      cityId = user.user.cityId;
    } 
  }

  var onYearChange = {
    raise() {             
      window.location.reload(); //fallback classico
      /* //tentativo react
      if (stakeholdersGridRef) {
        console.log("onYearChange", stakeholdersGridRef);
        if(stakeholdersGridRef.current.refreshAll) {
          console.log("onYearChange 2", stakeholdersGridRef.current);
          stakeholdersGridRef.current.year = JSON.parse(localStorage.getItem('ac-year'));
          console.log("onYearChange 2", stakeholdersGridRef.current);
          stakeholdersGridRef.current.forceUpdate();
          if(stakeholdersGridRef.current.refreshAll) {
            console.log("onYearChange 3", stakeholdersGridRef);
            stakeholdersGridRef.current.refreshAll();
          }          
        }
      } 
      //tentativo react */
    }
  }
  
  return (
    <React.Fragment>
      <Header sectionTitle="Inserimento variazioni" authProvider={authProvider} showYearSelector={true} parentYear={year} onYearChange={onYearChange} ></Header>
      <StakeholdersGrid userCityId={cityId} year={year} ref={stakeholdersGridRef} authProvider={authProvider}></StakeholdersGrid>      
    </React.Fragment>
  );
};