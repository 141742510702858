import React from 'react';

import { DataGrid, Sorting, Column, Pager, Paging, FilterRow, HeaderFilter, Selection} from 'devextreme-react/data-grid';
import Button from 'devextreme-react/button';
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import Box, { Item } from 'devextreme-react/box';
import * as DateConversion from '../../helper/dataConversion'

import * as Global from '../../global'

import './popup-employers-merge-confirm.scss';
 
export class PopupEmployersMergeConfirm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      employersToMove: null,
      employerToHold: null,          
      isPopupVisible: false,          
      loadPanelVisible: false,
      showIndicator: true,
      shading: true,
      showPane: true
    };

    this.showPopup = this.showPopup.bind(this);
    this.hidePopup = this.hidePopup.bind(this);
    this.onConfirmButtonClick = this.onConfirmButtonClick.bind(this);

    this.cancelButtonOptions = {      
      text: 'Annulla',
      type: 'danger',
      stylingMode: 'outlined',
      onClick: this.hidePopup,
    };
    this.confirmButtonOptions = {
      text: 'Conferma',
      type: 'success',
      stylingMode: 'contained',
      onClick: this.onConfirmButtonClick,
    };
  }

  componentDidMount() {
    this.props.onRef(this)
  }
  componentWillUnmount() {
    this.props.onRef(undefined)
  }

  onConfirmButtonClick() {
    this.props.onPopupEmployersMergeConfirm();    
  }

  showPopup(employerToHold, employersToMove, memberCode) {
    //console.log(city);
    this.setState({
      employersToMove: employersToMove,
      employerToHold: employerToHold,
      memberCode: memberCode,
      isPopupVisible: true
    });
  }

  hidePopup() {
    this.setState({        
      isPopupVisible: false
    });
  }

  renderItemText(itemData) {
    if(!itemData)
      return "";

    return (
      <span>{(itemData && itemData != '') ? (itemData) : ('-')}</span>
    );
  }

  render() {
    if(!this.state.employerToHold)
      return "";

    return (
      <div>        
        <Popup title="Unione datori di lavoro" visible={this.state.isPopupVisible} onHiding={this.hidePopup} width={840} height={550} >          
          <ToolbarItem widget="dxButton" toolbar="bottom" location="after" options={this.cancelButtonOptions} />
          <ToolbarItem widget="dxButton" toolbar="bottom" location="after" options={this.confirmButtonOptions} />

          <div className={'popup-employers-merge-confirm-container'}>       
            <p className={'popup-employers-merge-confirm-title'}>Conferma l'unione dei datori di lavoro con codice socio <b>{this.state.memberCode}</b></p>

            <div>
              <div className="ac-margin-top-20">Datore di lavoro che verrà <b>mantenuto</b>:</div>
              <div className="popup-employers-merge-confirm-employer-tohold-data">
                <Box direction="row" width="100%">
                  <Item ratio={1}>
                    <div className="dx-field">
                      <div className="dx-field-label">Cognome e nome:</div>
                      <div className="dx-field-value-static">{this.renderItemText(this.state.employerToHold.lastName)} {this.renderItemText(this.state.employerToHold.firstName)}</div>
                    </div>
                  </Item>
                </Box>  
                <Box direction="row" width="100%"> 
                  <Item ratio={1}>
                    <div className="dx-field">
                      <div className="dx-field-label">Codice fiscale:</div>
                      <div className="dx-field-value-static">{this.renderItemText(this.state.employerToHold.fiscalCode)}</div>
                    </div>
                  </Item>  
                </Box> 
                <Box direction="row" width="100%">
                  <Item ratio={1}>
                    <div className="dx-field">
                      <div className="dx-field-label">E-mail:</div>
                      <div className="dx-field-value-static">{this.renderItemText(this.state.employerToHold.email)}</div>
                    </div>
                  </Item>              
                </Box>            
                <Box direction="row" width="100%">
                  <Item ratio={1}>
                    <div className="dx-field">
                      <div className="dx-field-label">Data aggiornamento:</div>
                      <div className="dx-field-value-static">{this.renderItemText(DateConversion.GetDateFromISOString(this.state.employerToHold.updatedAt, true))}</div>
                    </div>
                  </Item>              
                </Box>              
              </div>
            </div>

            <div>
              <div className="ac-margin-top-20">Datori di lavoro da cui verranno <b>trasferiti i dipendenti</b> (se già presenti nel datore di lavoro da mantenere saranno <b>sostituiti</b>):</div>
              <DataGrid 
                id="popup-employers-merge-confirm-employers-tomove-grid"
                keyExpr="employerId"                
                className={'dx-card wide-card'} 
                dataSource={this.state.employersToMove} 
                remoteOperations={false}
                showBorders={true} 
                focusedRowEnabled={false} 
                defaultFocusedRowIndex={0} 
                columnAutoWidth={true} 
                columnHidingEnabled={true}                
              >
                <Selection mode="single" />
                <Paging defaultPageSize={3} />
                <Pager showPageSizeSelector={true} showInfo={true} />            
                <FilterRow visible={false} />
                <HeaderFilter visible={false} />
                <Sorting mode="multiple" />
                          
                <Column dataField={'employerId'} visible={false} />
                <Column dataField={'lastName'} caption={'Cognome'} hidingPriority={5} />
                <Column dataField={'firstName'} caption={'Nome'} hidingPriority={4} />
                <Column dataField={'fiscalCode'} caption={'Codice fiscale'} hidingPriority={3} />
                <Column dataField={'email'} caption={'Email'} hidingPriority={2} />
                <Column dataField={'updatedAt'} caption={'Data aggiornamento'} dataType={'date'} format={'dd/MM/yyyy'} hidingPriority={1} defaultSortIndex={1} defaultSortOrder='desc' />
              </DataGrid>
            </div>

          </div>

        </Popup>
      </div>
    );
  }
}

export default PopupEmployersMergeConfirm;