import React from 'react';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import SelectBox from 'devextreme-react/select-box';
import { Button } from 'devextreme-react/button';
import { confirm } from 'devextreme/ui/dialog';

import * as Global from '../../global'

import './header.scss';
 
export class Header extends React.Component {
    constructor(props) {
        super(props);

        if (!this.props.authProvider)
            Global.Logout();

        if (!localStorage.getItem('ac-year')) {
            localStorage.setItem('ac-year', JSON.stringify(new Date().getFullYear()));
        }

        this.state = {
            sectionTitle: this.props.sectionTitle,
            showYearSelector: this.props.showYearSelector,
            showPdfReportButtons: (this.props.showPdfReportButtons) ? this.props.showPdfReportButtons : false,
            onYearChange: this.props.onYearChange,
            yearsDataSource: [],
            disabled: (this.props.disabled) ? this.props.disabled : false,
            userCityId: (this.props.userCityId) ? this.props.userCityId : '00000000-0000-0000-0000-000000000000',
            userCityName: (this.props.userCityName) ? this.props.userCityName : '',
            year: JSON.parse(localStorage.getItem('ac-year')),
            pdfDownloadButtonState: this.createPdfDownloadButtonState("PDF non generato", false),
            pdfGenerateButtonEnabled: false,
            authProvider: this.props.authProvider,
        };

        this.onYearSelectorValueChanged = this.onYearSelectorValueChanged.bind(this);

        this.onPdfGenerationButtonClick = this.onPdfGenerationButtonClick.bind(this);
        this.onPdfDownloadButtonClick = this.onPdfDownloadButtonClick.bind(this);
        this.getPdfDownloadButtonState = this.getPdfDownloadButtonState.bind(this);
    }

    componentDidMount() {
        if (!this.props.authProvider)
            Global.Logout();

        if (this.state.showYearSelector)
            this.getYears();

        if (this.state.showPdfReportButtons)
            this.getPdfDownloadButtonState();
    }

    getYears() {
        var url =  Global.WebApiRootUrl + '/Stakeholders/VariationsYearsCount';

        const requestOptions = {
            method: 'GET',
            withCredentials: true,
            headers: {
                Accept: "application/json, text/plain, */*",
                Authorization: `Bearer ${this.state.authProvider.user.token}`,
            }
        };
                
        fetch(url, requestOptions)
          .then(response => {
            if(response.ok) 
                return response.json();

            this.props.authProvider.checkAuth(response);                    
            throw new Error('Request failed.');
          })
          .then(data => {   
            this.setState({
                yearsDataSource: data
            });        
            
          })
          .catch(error => {
            console.log(error);
          });
    }

    onYearSelectorValueChanged(e) {
        localStorage.setItem('ac-year', e.value);
        
        //se è impostato un trigger di notifica, viene chiamato
        if (this.state.onYearChange)
            if (this.state.onYearChange.raise)
                this.state.onYearChange.raise();
    }

    renderYearSelector() {
        if (this.state.showYearSelector)
            return (
                <div className={'ac-float-right'}>
                    <div className={'ac-float-letf'}>Anno:</div>
                    <div className={'ac-float-right header-year-selector'}>
                        <SelectBox items={this.state.yearsDataSource} width={70} onValueChanged={this.onYearSelectorValueChanged} defaultValue={this.state.year} disabled={this.state.disabled} />
                    </div>
                    <div className={'ac-clear-both'}></div>
                </div>);
        else
            return ("");
    }

    createPdfDownloadButtonState(buttonText, buttonEnabled, downloadFilename) { 
        const pdfDownloadButtonState = { 
            text: buttonText,
            enabled: buttonEnabled,
            filename: (downloadFilename) ? downloadFilename : ((new Date()).getDate() + "-" + ((new Date()).getMonth() + 1) + "-" + (new Date()).getFullYear() + ".pdf"),
        };

        return pdfDownloadButtonState; 
    }

    getPdfDownloadButtonState() {
        //return;

        //console.log("getPdfDownloadButtonState");        
        this.setState({ pdfDownloadButtonState: this.createPdfDownloadButtonState("Caricamento stato PDF", false) });

        var url =  Global.WebApiRootUrl + '/Report/GetPdfReportState';

        var formData = new FormData();

        const payload = {
            cityId: this.state.userCityId,
            year: this.state.year,
            isGlobal: '1',
        };
        formData.append("values", JSON.stringify(payload));

        const requestOptions = {
            method: 'POST',
            body: formData,
            withCredentials: true,
            headers: {
                Accept: "application/json, text/plain, */*",
                Authorization: `Bearer ${this.state.authProvider.user.token}`,
            }
        };
                
        fetch(url, requestOptions)
            .then(response => {
                if(response.ok) 
                    return response.json();

                this.props.authProvider.checkAuth(response);   
                throw new Error('Request failed.');
            })
            .then(data => {  
                //console.log("getPdfDownloadButtonState", data);                
                if(data) {
                    if(data.downloadButtonText) {
                        this.setState({ 
                            pdfDownloadButtonState: this.createPdfDownloadButtonState(data.downloadButtonText, data.downloadButtonEnabled, data.downloadedFilename),
                            pdfGenerateButtonEnabled: data.generateButtonEnabled,
                        });
                    }
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({ 
                    pdfDownloadButtonState: this.createPdfDownloadButtonState("PDF non disponibile", false),
                    pdfGenerateButtonEnabled: false,
                });
            });
    }

    onPdfGenerationButtonClick() {
        //return;

        let result = confirm("Avviare la generazione del PDF globale?", "Confirma generazione PDF");
        result.then((dialogResult) => {        
            if (dialogResult) {
                var url =  Global.WebApiRootUrl + '/Report/GeneratePdfReport';
                var formData = new FormData();
                const payload = {
                    cityId: this.state.userCityId,
                    year: this.state.year,
                    isGlobal: '1',
                };
                formData.append("values", JSON.stringify(payload));
                const requestOptions = {
                    method: 'POST',
                    body: formData,
                    withCredentials: true,
                    headers: {
                        Accept: "application/json, text/plain, */*",
                        Authorization: `Bearer ${this.state.authProvider.user.token}`,
                    }
                };

                fetch(url, requestOptions)
                    .then(response => {
                        //console.log("getPdfDownloadButtonState 1", response);                        
                        if(response.ok) 
                            return response.json();
                            
                        this.props.authProvider.checkAuth(response);   
                        throw new Error('Request failed.');
                    })
                    .then(data => {  
                        //console.log("getPdfDownloadButtonState 2");                
                        this.getPdfDownloadButtonState();
                    })
                    .catch(error => {
                        console.log(error);
                        //this.setState({ pdfDownloadButtonState: this.createPdfDownloadButtonState("PDF non disponibile", false) });
                    });

                //console.log("getPdfDownloadButtonState 3");                
                this.setState({ pdfDownloadButtonState: this.createPdfDownloadButtonState("Generazione in corso", false), pdfGenerateButtonEnabled: false });
            }
        });
    }

    onPdfDownloadButtonClick() {
        
        var url =  Global.WebApiRootUrl + '/Report/DownloadPdfReport';
        var formData = new FormData();
        const payload = {
            cityId: this.state.userCityId,
            year: this.state.year,
            isGlobal: '1',
        };
        formData.append("values", JSON.stringify(payload));
        const requestOptions = {
            method: 'POST',
            body: formData,
            responseType: 'blob',
            withCredentials: true,
            headers: {
              Accept: "application/pdf",
              Authorization: `Bearer ${this.state.authProvider.user.token}`,
            }
        };

        fetch(url, requestOptions)
            .then(response => {        
                if(response.ok) 
                    return response.blob();
            
                this.props.authProvider.checkAuth(response);  
            })            
            .then(blob => {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;

                //var today = new Date(); 
                //var filename = this.state.userCityName + "_" + today.getDate() + "-" + (today.getMonth() + 1) + "-" + today.getFullYear() + ".pdf";
                var filename = this.state.pdfDownloadButtonState.filename;

                a.download = filename;
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();    
                a.remove();  //afterwards we remove the element again       
                
                //this.hideLoadPanel();
            })
            .catch(error => {
                //this.setState({ errorMessage: error.toString() });
                console.error('There was an error!', error);
            });
    }

    renderPdfReportButtons() {
        if (this.state.showPdfReportButtons)
            return (
                <div className={'ac-float-right'}>
                    <Button className="ac-button-default" width={190} text="Genera PDF globale" disabled={!this.state.pdfGenerateButtonEnabled} type="default" stylingMode="contained" onClick={this.onPdfGenerationButtonClick} />
                    <span> </span>
                    <Button width={225} text={this.state.pdfDownloadButtonState.text} disabled={!this.state.pdfDownloadButtonState.enabled} type="default" stylingMode="outlined" onClick={this.onPdfDownloadButtonClick} />
                    <span>&nbsp;&nbsp;</span>
                    <div className={'ac-clear-both'}></div>
                </div>);
        else
            return ("");
    }

    render() {
        return (
            <div className={'content-block ac-page-title '}>
                <div className={'ac-float-letf'}>{this.state.sectionTitle}</div>                
                {this.renderYearSelector()}
                {this.renderPdfReportButtons()}
                <div className={'ac-clear-both'}></div>
            </div>
        );
    }
}

export default Header;