import React from 'react';
import Form, { Item, SimpleItem, Label, GroupItem } from 'devextreme-react/form';
import { Button } from 'devextreme-react/button';
import { DataGrid, Sorting, Column, Pager, Paging, FilterRow, Lookup, Editing, SearchPanel} from 'devextreme-react/data-grid';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { LoadPanel } from 'devextreme-react/load-panel';
import ReportDataGridRow from './report-grid-row.js';
import DateBox from 'devextreme-react/date-box';
import Box from 'devextreme-react/box';
import * as Global from '../../global';
import './report-grid.scss';

export class ReportGrid extends React.Component {
  constructor(props) {
    super(props);

    if (!this.props.authProvider)
      Global.Logout();

    var storedGridFilter = this.initGridFilter();

    this.year = new Date().getFullYear();;
    if (this.props.year)
      this.year = this.props.year;

    this.userCityId = "00000000-0000-0000-0000-000000000000";
    if (this.props.userCityId)
      this.userCityId = this.props.userCityId;

    this.userCityName = "CittaNonDefinita";
    if (this.props.userCityName)
      this.userCityName = this.props.userCityName;

    this.state = {
      stakeholdersDataSource: this.createStakeholdersDataSource(),
      lookupStakeholderType: ['Datore', 'Dipendente'],
      gridFilter: storedGridFilter,
      loadPanelVisible: false,
      variationsTypeItems: null, //this.createVariationsTypeDataSource(),
      monthItems: this.getMonthItems(),
      authProvider: this.props.authProvider,
    };
    
    this.onSearchButtonClick = this.onSearchButtonClick.bind(this);  
    this.onResetButtonClick = this.onResetButtonClick.bind(this);  
    
    this.dataGrid = null;
    this.filterForm = null;

    this.onExportEmployeesToPDFButtonClick = this.onExportEmployeesToPDFButtonClick.bind(this); 

    this.hideLoadPanel = this.hideLoadPanel.bind(this);
    this.showLoadPanel = this.showLoadPanel.bind(this);

    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  componentDidMount() {
    if (!this.props.authProvider)
      Global.Logout();

    this.getVariationsTypeItems();
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  initGridFilter() {    
    var filter = this.createGridFilter();
    
    if (JSON.parse(localStorage.getItem('ac-report-filter'))) {
      filter = JSON.parse(localStorage.getItem('ac-report-filter'));
    }    

    return filter;
  }

  createGridFilter() {    
    var filter = {
      employeeName: '',
      employeeLastNameFirstLetterFrom: '',
      employeeLastNameFirstLetterTo: '',
      employeeVariationsMonthFrom: 0,
      employeeVariationsMonthTo: 0,
      employerSendingMethodOrdinaryMail: 0,
      employerSendingMethodEMail: 0,
      employerSendingMethodFax: 0,
      employerSendingMethodLocalPrint: 0,
      employeeVariationType: '',
      employeeRecruitmentDateFrom: '',
      employeeRecruitmentDateTo: '',
      employeeRegistryNotesFrom: '',
      employeeRegistryNotesTo: '',
      employeeNotes: '',
      employeeNotesNotEmpty: 0,
      employeeExcludeDateEnd: 1,

      pdfPrintOptionVariationsMonthFrom: 0,
      pdfPrintOptionVariationsMonthTo: 0,
    };

    return filter;
  }

  createStakeholdersDataSource() {
    var store = createStore({
      key: 'id',
      loadUrl: Global.WebApiRootUrl + '/Report/FindEmployees',
      onBeforeSend: (method, ajaxOptions) => {
        if (method == "load" ) {
            //ajaxOptions.method = "POST";
            //console.log(ajaxOptions.data);

            var payload = ajaxOptions.data;
            payload.values = JSON.stringify(this.createFilterPayLoad(this.userCityId, this.year, this.state.gridFilter));

            ajaxOptions.data = payload;
            //console.log(ajaxOptions.data);
        }
        ajaxOptions.xhrFields = { withCredentials: true };
        ajaxOptions.headers = { Authorization: `Bearer ${this.state.authProvider.user.token}`}
      },
      onAjaxError: ({ xhr, error}) => {
        this.props.authProvider.checkAuth(xhr);
      }
    });

    return store;
  }

  getVariationsTypeItems() {
    var url =  Global.WebApiRootUrl + '/Stakeholders/VariationTypes';

    const requestOptions = {
      method: 'GET',
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `Bearer ${this.state.authProvider.user.token}`,
      }
    };
            
    fetch(url, requestOptions)
      .then(response => {
        if(response.ok) 
          return response.json();
        
        this.props.authProvider.checkAuth(response);
        throw new Error('Request failed.');
      })
      .then(data => {          
        if(data && data.data) { 
          //console.log(data && data.data);
          data.data.unshift({variationTypeId: "00000000-0000-0000-0000-000000000000", name: "-", code: ""});
          //console.log(data);
          this.setState({
            variationsTypeItems: data.data
          });       
        } 
      })
      .catch(error => {
        console.log(error);
      });
  }

  getMonthItems() {
    return Array.from({length: 13}, (e, i) => {
              if (i == 0)
                return {value: i, text: '-'};
              else
                return {value: i, text: this.capitalizeFirstLetter(new Date(null, i, null).toLocaleDateString("it", {month: "long"})) + ' ' + this.year};
            }); 
  }

  refreshDataGrid() {
    this.dataGrid.instance.refresh();
  }

  onSearchButtonClick(e) {    
    this.setState({
      stakeholdersDataSource: this.createStakeholdersDataSource(),
    });

    localStorage.setItem('ac-report-filter', JSON.stringify(this.state.gridFilter));

    this.refreshDataGrid();
  }

  onResetButtonClick(e) {
    var filter = this.createGridFilter();

    this.filterForm.instance.resetValues();

    this.setState({
      stakeholdersDataSource: this.createStakeholdersDataSource(),
      gridFilter: filter
    });

    localStorage.removeItem('ac-report-filter');

    this.refreshDataGrid();
  }

  createFilterPayLoad(cityId, year, gridFilter) {
    const payload = { 
      cityId: cityId,
      year: year,
      employeeName: gridFilter.employeeName,
      employeeLastNameFirstLetterFrom: gridFilter.employeeLastNameFirstLetterFrom,
      employeeLastNameFirstLetterTo: gridFilter.employeeLastNameFirstLetterTo,
      employeeVariationsMonthFrom: gridFilter.employeeVariationsMonthFrom,
      employeeVariationsMonthTo: gridFilter.employeeVariationsMonthTo,
      employerSendingMethodOrdinaryMail: gridFilter.employerSendingMethodOrdinaryMail,
      employerSendingMethodEMail: gridFilter.employerSendingMethodEMail,
      employerSendingMethodFax: gridFilter.employerSendingMethodFax,
      employerSendingMethodLocalPrint: gridFilter.employerSendingMethodLocalPrint,
      employeeVariationType: gridFilter.employeeVariationType,
      employeeRecruitmentDateFrom: gridFilter.employeeRecruitmentDateFrom,
      employeeRecruitmentDateTo: gridFilter.employeeRecruitmentDateTo,
      employeeRegistryNotesFrom: gridFilter.employeeRegistryNotesFrom,
      employeeRegistryNotesTo: gridFilter.employeeRegistryNotesTo,
      employeeNotes: gridFilter.employeeNotes,
      employeeNotesNotEmpty: gridFilter.employeeNotesNotEmpty,
      employeeExcludeDateEnd: gridFilter.employeeExcludeDateEnd,
      pdfPrintOptionVariationsMonthFrom: gridFilter.pdfPrintOptionVariationsMonthFrom,
      pdfPrintOptionVariationsMonthTo: gridFilter.pdfPrintOptionVariationsMonthTo,
    };

    return payload;
  }

  onExportEmployeesToPDFButtonClick() {
    this.showLoadPanel();

    const url =  Global.WebApiRootUrl + "/Report/ExportEmployeesToPDF";

      var formData = new FormData();
  
      formData.append("values", JSON.stringify(this.createFilterPayLoad(this.userCityId, this.year, this.state.gridFilter)));
          
      // POST request using fetch with error handling
      const requestOptions = {
        method: 'POST',
        body: formData,
        responseType: 'blob',
        withCredentials: true,
        headers: {
          Accept: "application/pdf",
          Authorization: `Bearer ${this.state.authProvider.user.token}`,
        }
      };
      fetch(url, requestOptions)
        .then(response => {
          if(response.ok) 
            return response.blob();

          Global.CheckResponseAuth(response);
        })
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;

            var today = new Date(); 
            var filename = this.userCityName + "_" + today.getDate() + "-" + (today.getMonth() + 1) + "-" + today.getFullYear() + "_filtri.pdf";

            a.download = filename;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();  //afterwards we remove the element again       
            
            this.hideLoadPanel();
        })
        .catch(error => {
            //this.setState({ errorMessage: error.toString() });
            console.error('There was an error!', error);
        });
  }

  showLoadPanel() {
    this.setState({
      loadPanelVisible: true
    });
  }

  hideLoadPanel() {
    this.setState({
      loadPanelVisible: false
    });
  }

  onFormSubmit(e) {
    if(e && e.onSearchButtonClick)
      e.onSearchButtonClick();
    else
      alert("Si è verificato un errore, non è possibile procedere.")    
  }

  render() {    
    return (
      <div className={'content-block'}>

        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          visible={this.state.loadPanelVisible}
          showIndicator={true}
          shading={true}
          showPane={true}
          closeOnOutsideClick={false}
        />

        <div className={'report-filter-container dx-card wide-card'}>
          <div className="report-filter-title">Ricerca datore per:</div>
          <div className="report-filter-form-container">
            <form action="#" className={'report-filter-form'} onSubmit={ e => {e.preventDefault(); this.onFormSubmit(this);}}>
              <Form colCount={3} id="form2" formData={this.state.gridFilter} ref={(ref) => this.filterForm = ref} >
                  
                  <GroupItem colSpan={1} colCount={1} >
                    <SimpleItem dataField="employeeName" editorType="dxTextBox" editorOptions={{maxLength: 25}}  >
                      <Label text="Cognome" />
                    </SimpleItem>
                  </GroupItem>
                  <GroupItem  colSpan={1} colCount={2}>
                    <SimpleItem dataField="employeeLastNameFirstLetterFrom" editorType="dxTextBox" editorOptions={{maxLength: 10}} >
                      <Label text="Iniziale cognome dal" />
                    </SimpleItem>
                    <SimpleItem dataField="employeeLastNameFirstLetterTo" editorType="dxTextBox" editorOptions={{maxLength: 10}} >
                      <Label text="Iniziale cognome al" />
                    </SimpleItem>
                  </GroupItem>
                  <GroupItem  colSpan={1} colCount={2}>
                    <SimpleItem dataField="employeeVariationsMonthFrom" editorType="dxSelectBox" editorOptions={{ items: this.state.monthItems, displayExpr: 'text', valueExpr: 'value' }} >
                      <Label text="Variazioni inserite dal" />
                    </SimpleItem>
                    <SimpleItem dataField="employeeVariationsMonthTo" editorType="dxSelectBox" editorOptions={{ items: this.state.monthItems, displayExpr: 'text', valueExpr: 'value' }} >
                      <Label text="Variazioni inserite al" />
                    </SimpleItem>
                  </GroupItem>
                  
                  <GroupItem colSpan={1} colCount={4} >
                    <SimpleItem dataField="employerSendingMethodOrdinaryMail" editorType="dxCheckBox" >
                      <Label text="Invio Posta" />
                    </SimpleItem>
                    <SimpleItem dataField="employerSendingMethodEMail" editorType="dxCheckBox" >
                      <Label text="Invio Email" />
                    </SimpleItem>
                    <SimpleItem dataField="employerSendingMethodFax" editorType="dxCheckBox" >
                      <Label text="Invio Fax" />
                    </SimpleItem>
                    <SimpleItem dataField="employerSendingMethodLocalPrint" editorType="dxCheckBox" >
                      <Label text="Stampa locale" />
                    </SimpleItem>
                  </GroupItem>
                  <GroupItem  colSpan={1} colCount={1}>
                    <SimpleItem dataField="employeeVariationType" editorType="dxSelectBox" editorOptions={{ items: this.state.variationsTypeItems, displayExpr: 'name', valueExpr: 'code' }} >
                      <Label text="Tipologia variazioni" />
                    </SimpleItem>            
                  </GroupItem>
                  <GroupItem  colSpan={1} colCount={2}>
                    <SimpleItem dataField="employeeRecruitmentDateFrom" editorType="dxDateBox" >
                      <Label text="Data assunzione da" />
                    </SimpleItem>
                    <SimpleItem dataField="employeeRecruitmentDateTo" editorType="dxDateBox" >
                      <Label text="Data assunzione a" />
                    </SimpleItem>
                  </GroupItem>

                  <GroupItem  colSpan={1} colCount={2}>
                    <SimpleItem dataField="employeeRegistryNotesFrom" editorType="dxTextBox" editorOptions={{maxLength: 10}} >
                      <Label text="Codice socio da" />
                    </SimpleItem>
                    <SimpleItem dataField="employeeRegistryNotesTo" editorType="dxTextBox" editorOptions={{maxLength: 10}} >
                      <Label text="Codice socio a" />
                    </SimpleItem>
                  </GroupItem>
                  <GroupItem  colSpan={1} colCount={1}>
                    <SimpleItem dataField="employeeNotes" editorType="dxTextBox" editorOptions={{maxLength: 25}} >
                      <Label text="Annot. dell'anagrafica Contratti" />
                    </SimpleItem>            
                  </GroupItem>
                  <GroupItem colSpan={1} colCount={2} >
                    <SimpleItem dataField="employeeNotesNotEmpty" editorType="dxCheckBox" >
                      <Label text="Spazio annot. anagrafica Contratti" />
                    </SimpleItem>
                    <SimpleItem dataField="employeeExcludeDateEnd" editorType="dxCheckBox" >
                      <Label text="Escludi dip. con data cess. precedente all'anno selezionato" />
                    </SimpleItem>
                  </GroupItem>

                  <GroupItem  colSpan={1} colCount={2}>
                    <SimpleItem dataField="pdfPrintOptionVariationsMonthFrom" editorType="dxSelectBox" editorOptions={{ items: this.state.monthItems, displayExpr: 'text', valueExpr: 'value' }} >
                      <Label text="Stampa in PDF variazioni dal" />
                    </SimpleItem>
                    <SimpleItem dataField="pdfPrintOptionVariationsMonthTo" editorType="dxSelectBox" editorOptions={{ items: this.state.monthItems, displayExpr: 'text', valueExpr: 'value' }} >
                      <Label text="Stampa in PDF variazioni al" />
                    </SimpleItem>
                  </GroupItem>

              </Form>

              <div className={'report-filter-buttons'}>
                <div className={'report-filter-buttons-left'}>
                  <Button className="ac-button-default" width={120} text="Cerca" type="default" stylingMode="contained" useSubmitBehavior={true} />
                  <span> </span>
                  <Button width={155} text="Cancella filtri" type="default" stylingMode="outlined" onClick={this.onResetButtonClick} />
                </div>
                <div className={'report-filter-buttons-right'}>
                  <Button className="ac-button-default" width={125} text="Genera PDF" type="default" stylingMode="contained" onClick={this.onExportEmployeesToPDFButtonClick} />
                </div>
              </div>
            </form>
          </div>
        </div>

        <div id="report-employees-grid">
          <DataGrid 
            id="gridContainer"
            ref={(ref) => this.dataGrid = ref}
            className={'dx-card wide-card'} 
            dataSource={this.state.stakeholdersDataSource} 
            remoteOperations={true}
            showBorders={true} 
            focusedRowEnabled={true} 
            defaultFocusedRowIndex={0} 
            columnAutoWidth={true} 
            columnHidingEnabled={true}
            rowRender={ReportDataGridRow}      
          >

            <Paging defaultPageSize={15} />
            <Pager showPageSizeSelector={true} showInfo={true} />
            <FilterRow visible={false} />
            <Editing mode="popup" allowUpdating={false} allowDeleting={false} allowAdding={false} />
            <Sorting mode="multiple" />
                      
            <Column dataField={'id'} hidingPriority={1} visible={false} />
            <Column dataField={'employerId'} hidingPriority={1} visible={false} />
            <Column dataField={'employeeId'} hidingPriority={1} visible={false} />

            <Column dataField={'employerLastName'} caption={'Cognome'} hidingPriority={4} defaultSortIndex={0} defaultSortOrder="asc" />
            <Column dataField={'employerFirstName'} caption={'Nome'} hidingPriority={3} defaultSortIndex={1} defaultSortOrder="asc" />

            <Column dataField={'employeeRegistryNotes'} caption={'Codice socio'} hidingPriority={1} alignment='left' />

            <Column dataField={'employerFiscalCode'} caption={'Codice fiscale'} hidingPriority={2}  />

            <Column dataField={'lastName'} caption={'Cognome'} hidingPriority={1} visible={false} />
            <Column dataField={'firstName'} caption={'Nome'} hidingPriority={1} visible={false} />                        
            <Column dataField={'fiscalCode'} caption={'Codice fiscale'} hidingPriority={1} visible={false} />
            <Column dataField={'employeeFullName'} caption={'Dipendente'} hidingPriority={1} />
            <Column dataField={'employeeInpsCode'} caption={'Codice INPS'} hidingPriority={2} />
            <Column dataField={'employeeRecruitmentDate'} caption={'Data ass.'} hidingPriority={2} />
            <Column dataField={'employeeEndDate'} caption={'Data cess.'} hidingPriority={2} />

            <Column dataField={'type'} caption={'Tipo'} hidingPriority={1} visible={false} >
              <Lookup dataSource={this.state.lookupStakeholderType} />
            </Column>
            <Column dataField={'entity'} caption={'Entità'} hidingPriority={1} visible={false} />
            <Column dataField={'path'} caption={'Path'} hidingPriority={1} visible={false} />

            <Column dataField={'employeeContractCategory'} caption={'employeeContractCategory'} hidingPriority={1} visible={false} />
            <Column dataField={'employeeContractType'} caption={'employeeContractType'} hidingPriority={1} visible={false} />
            <Column dataField={'employeeHalfDayOff'} caption={'employeeHalfDayOff'} hidingPriority={1} visible={false} />
            <Column dataField={'employeeLevel'} caption={'employeeLevel'} hidingPriority={1} visible={false} />
            <Column dataField={'employeeTask'} caption={'employeeTask'} hidingPriority={1} visible={false} />
            <Column dataField={'employeeNotes'} caption={'employeeNotes'} hidingPriority={1} visible={false} />
            <Column dataField={'employeeUserNotes'} caption={'employeeUserNotes'} hidingPriority={1} visible={false} allowSearch={false} />
          </DataGrid>
        </div> 
      </div>
    );
  }
}

export default ReportGrid;