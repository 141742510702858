import * as Global from '../global'
import notify from "devextreme/ui/notify";


export function SetGlobalToastMessage(messageText, messageType) {
    var toastMessage = {
        text: messageText,      
        type: messageType
    };
    localStorage.setItem(Global.LocalStorageKey.ToastMessage, JSON.stringify(toastMessage));
}

export function CheckGlobalToastMessage() {
    var toastMessage = JSON.parse(localStorage.getItem(Global.LocalStorageKey.ToastMessage));
    if(toastMessage) {    
        notify(toastMessage.text, toastMessage.type, 5000);       
        localStorage.removeItem(Global.LocalStorageKey.ToastMessage);
        toastMessage = null;
    }  
}