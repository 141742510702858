import React, { useRef } from 'react';
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom';
import { Button } from 'devextreme-react/button';
import { LoadPanel } from 'devextreme-react/load-panel';
import EmployeeEditor from '../../components/assindatcolf/employee-editor'
import notify from "devextreme/ui/notify";

import * as Global from '../../global';
import * as Notifier from '../../helper/notifier'

export class RecruitmentsCreateEmployee extends React.Component {
    constructor(props) {
        super(props);
        if (!localStorage.getItem(Global.LocalStorageKey.Year)) {
            localStorage.setItem(Global.LocalStorageKey.Year, JSON.stringify(new Date().getFullYear()));
        }

        this.state = {
            year: JSON.parse(localStorage.getItem(Global.LocalStorageKey.Year)),
            loggedUser: Global.GetLoggedUser(),
            isLoadPanelVisible: false,
        };

        this.employeeEditorRef = React.createRef();

        this.onSaveButtonClick = this.onSaveButtonClick.bind(this);        
        this.onEmployeeEditorSaveEnd = this.onEmployeeEditorSaveEnd.bind(this);
        this.createNewRecruitment = this.createNewRecruitment.bind(this);
    }
  
    componentDidMount() {    
        return;
    }

    showLoadPanel() {
        this.setState({
          isLoadPanelVisible: true
        });
      }
    
      hideLoadPanel() {
        this.setState({
          isLoadPanelVisible: false
        });
      }

    onSaveButtonClick(e) {           
        this.showLoadPanel();
        
        try {            
            this.employeeEditorRef.current.save();
            //this.hideLoadPanel();
        } catch (error) {
            this.hideLoadPanel();
            notify(error, 'error', 5000);
        }

        return null;
    }

    onEmployeeEditorSaveEnd(employeeId) {
        //if this event is raised, new employee is added and id is returnet to create new recruitment
        //data is EmployeeWithEmployerForm object with employeeId
        //console.log("finito nel parent per davvero: employ id = ", employeeId);
        if(employeeId)
            this.createNewRecruitment(employeeId);
        else
            this.hideLoadPanel();
    }

    createNewRecruitment(employeeId) {        
        var url =  Global.WebApiRootUrl + "/recruitments/create";

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({employeeId: employeeId, userId: this.state.loggedUser.userId}),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.state.loggedUser.token}`
            }
        };
    
        return fetch(url, requestOptions)
            .then(response => {
                if(response.ok) return response.json();
                Global.CheckResponseAuth(response);
                return null;
            })
            .then(data => {
                this.hideLoadPanel();
                notify(data.message, data.success ? 'success' : 'error', 5000);

                if(data.success)
                    this.props.history.push({pathname: '/recruitments/' + employeeId});
            })
            .catch(error => { this.hideLoadPanel(); notify(error, 'error', 5000); });
    }

    render() {    
        return(
            <React.Fragment>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    visible={this.state.isLoadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />

                <div className={'content-block ac-page-title'}>
                    <div>
                        <div>
                            <span><Link to="/recruitments">Assunzioni</Link> &gt; Inserimento datore/dipendente</span>
                        </div>
                    </div>
                    <div className={'ac-margin-top-05'}>
                        <div className={'ac-float-letf'}>
                            <div className={'ac-container-new-item'}>Nuova</div>
                        </div>
                        <div className={'ac-float-right'}>
                            <Button className="ac-button-default" width={120} text="Salva" type="default" stylingMode="contained" useSubmitBehavior={true} onClick={this.onSaveButtonClick} />
                        </div>
                        <div className={'ac-clear-both'}></div>
                    </div>
                </div>            
                                       
                <EmployeeEditor ref={this.employeeEditorRef} loggedUser={this.state.loggedUser} onSaveEnd={this.onEmployeeEditorSaveEnd}></EmployeeEditor>
                    
            </React.Fragment>  
        );
    }
};
   
export default withRouter(RecruitmentsCreateEmployee);
