import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import { Button } from 'devextreme-react/button';
import { confirm } from 'devextreme/ui/dialog';  

import Accordion from 'devextreme-react/accordion';
import EmployeeHours from '../../components/assindatcolf/employee-hours'
import EmployeeVariations from '../../components/assindatcolf/employee-variations'
import EmployerDetails from '../../components/assindatcolf/employer-details'
import EmployeeDetails from '../../components/assindatcolf/employee-details'
import { LoadPanel } from 'devextreme-react/load-panel';

import * as Global from '../../global'

import './employee.scss';

export class Employee extends React.Component {
  constructor(props) {
    super(props);

    //console.log("constructor", props);

    if (!localStorage.getItem('ac-year')) {
      localStorage.setItem('ac-year', JSON.stringify(new Date().getFullYear()));
    }  

    this.state = {
        year: JSON.parse(localStorage.getItem('ac-year')),
        accordionDetailsData: [],
        section: '',
        breadcrumbRootLabel: '',
        breadcrumbRootUrl: '',
        loadPanelVisible: false,
        headerStickyTop: null,
        loggedUser: Global.GetLoggedUser()
    };

    this.renderToolbarYear = this.renderToolbarYear.bind(this);
    this.renderToolbarExportEmployeeToPDFButton = this.renderToolbarExportEmployeeToPDFButton.bind(this);
    this.onExportEmployeeToPDFButtonClick = this.onExportEmployeeToPDFButtonClick.bind(this);
    this.onDeleteEmployeeButtonClick = this.onDeleteEmployeeButtonClick.bind(this);

    this.hideLoadPanel = this.hideLoadPanel.bind(this);
    this.showLoadPanel = this.showLoadPanel.bind(this);

    this.handleScroll = this.handleScroll.bind(this); //per gestire la parte di header sticky
  }

  componentDidMount() {
    if(!this.props.history.location.state) {  
      this.props.history.push({pathname: 'stakeholders'});
      //this.props.history.push({pathname: this.props.location.state.from});
    } else {
      //console.log('componentDidMount', this.props.location.state.stakeholder);
      //console.log(document.getElementsByTagName("html")[0].childNodes[2]);

      //se si imposta heigth = 100%, la gestione javascript dello scroll non funziona, quindi in questo preciso contesto imposto auto, non sembra avere effetti negativi sul layout
      //document.getElementsByTagName("html")[0].childNodes[2].style.height = "auto";
      //console.log(document.getElementsByTagName("body")[0]);
      document.getElementsByTagName("body")[0].style.height = "auto";

      //aggiunge la gestione dell'evento scroll, per rendere sticky alcuni elementi
      window.addEventListener('scroll', this.handleScroll, true);      

      this.setState({
        accordionDetailsData : [{
          title: "Dati anagrafici",
          notes: this.props.location.state.stakeholder.employeeUserNotes,
          employerId: this.props.history.location.state.stakeholder.employerId,
          employeeId: this.props.history.location.state.stakeholder.id,
          element: null,          
        }],
        section: this.props.location.state.from, /* 'stakeholders' or 'report' */
        breadcrumbRootLabel: (this.props.location.state.from == 'stakeholders') ? 'Inserimento variazioni' : 'Report',
        breadcrumbRootUrl: '/' + this.props.location.state.from,
        headerStickyTop: document.getElementById("employee-header-sticky").offsetTop, /*mantiene la posizione originale del div per rendere sticky tabelle e dati anagrafici*/
      });
      //console.log("componentDidMount", this.props.history.location.state);
    }
  }

  componentWillUnmount() {
    //remove event listener and restore body height
    window.removeEventListener('scroll', this.handleScroll);
    document.getElementsByTagName("body")[0].style.height = "100%";
  }

  handleScroll(event) {
    if (!document.getElementById("employee-header-sticky"))
      return;

    //recuperiamo lo scrollTop in puro javascript, per sapere se sto scrollando e che posizione ho raggiunto
    var supportPageOffset = window.pageXOffset !== undefined;
    var isCSS1Compat = ((document.compatMode || "") === "CSS1Compat");
    //var scrollLeft = supportPageOffset ? window.pageXOffset : isCSS1Compat ? document.documentElement.scrollLeft : document.body.scrollLeft;
    var scrollTop = supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;
    
    //salviamo la larghezza del div da rendere sticky, altrimenti si perde cambiando la classe    
    var elementWidth = document.getElementById("employee-header-sticky").offsetWidth;
  
    //a seconda dello scroll, attiviamo o meno la modalit sticky con una classe
    var currentScroll = scrollTop;
    if (currentScroll >= this.state.headerStickyTop) {
      document.getElementById("employee-header-sticky").className = "employee-header-sticky-set";
      document.getElementById("employee-header-sticky").style.width = elementWidth + "px";
      document.getElementById("employee-header-fixed").style.height = document.getElementById("employee-header-sticky").offsetHeight + "px";
    } else {
      document.getElementById("employee-header-sticky").className = "employee-header-sticky-unset";
      document.getElementById("employee-header-sticky").style.width = "auto";
      document.getElementById("employee-header-fixed").style.height = "auto";
    }
  }

  getEmployeeHoursExtraTitleData(stakeholder) {
    var data = '';
    if (stakeholder) {
      if (stakeholder.employeeContractCategory) {
        if (data != '') data = data + ' - '
        data = data + stakeholder.employeeContractCategory;
      }        

      if (stakeholder.employeeLevel){
        if (data != '') data = data + ' - '
        data = data + stakeholder.employeeLevel;
      }
        
      if (stakeholder.employeeTask){
        if (data != '') data = data + ' - '
        data = data + stakeholder.employeeTask;
      }

      if (stakeholder.employeeContractType){
        if (data != '') data = data + ' - '
        data = data + stakeholder.employeeContractType;
      }

      if (stakeholder.employeeHalfDayOff){
        if (data != '') data = data + ' - '
        data = data + stakeholder.employeeHalfDayOff;
      }

      /*
      if (stakeholder.employeeNotes){
        if (data != '') data = data + ' - '
        data = data + stakeholder.employeeNotes;
      }
      */
    }

    return data;
  }

  getEmployeeHoursExtraTitleNotes(stakeholder) {
    var data = '';
    if (stakeholder) {
      if (stakeholder.employeeNotes){
        if (data != '') data = data + ' - '
        data = data + stakeholder.employeeNotes;
      }
    }

    return data;
  }

  accordionDetailsRenderItem(itemData) {
    return (
      <div style={{ display: "none" }} ref={(ref) => itemData.element = ref} >
        <EmployerDetails title="Datore di lavoro" employerId={itemData.employerId} />
        <EmployeeDetails title="Dipendente" employeeId={itemData.employeeId} />
      </div>
    );
  }

  accordionDetailsRenderTitle(data) {
    var title = "Titolo";
    var notes = "";
    
    if (data) {
      if (data.title)
        title = data.title;

      if (data.notes)
        notes = data.notes;
    }

    return (
        <div>
          <div className={'ac-accordion-title'}>
              <span className={'ac-accordion-title-left'}>{title}</span>
              <span className={'ac-accordion-title-right'}>{notes}</span>
              <span className={'ac-clear-both'}></span>
          </div>
        </div>
    );
  }

  onAccordionDetailsItemClick(item) {   
    //console.log(item) ;
    if(item && item.itemData && item.itemData.element) {      
      item.itemData.element.style = "display: block;";
    }
  }

  onAccordionDetailsItemRendered(item) {
    if(item && item.component ) {
      item.component.collapseItem(0);
    }    
  }

  renderToolbarYear() {
    return <div className={'employee-year'}>Anno: {this.state.year}</div>;
  }

  renderToolbarExportEmployeeToPDFButton() {
    if (this.state.section == 'report') {
      return <Button className="ac-button-default" width={125} text="Genera PDF" type="default" stylingMode="contained" onClick={this.onExportEmployeeToPDFButtonClick} />;
    }
    else {
      if (Global.IsLoggedUserAdmin())
        return <div><Button width={125} text="Elimina" type="default" stylingMode="outlined" onClick={this.onDeleteEmployeeButtonClick} /> <Button className="ac-button-default" width={125} text="Salva" type="default" stylingMode="contained" /></div>;
      else
      return <div><Button className="ac-button-default" width={125} text="Salva" type="default" stylingMode="contained" /></div>;
    }
  }

  renderSaveButton(){
    if (this.state.section == 'stakeholders')
    return <div><div className="ac-float-right"><Button className="ac-button-default" width={125} text="Salva" type="default" stylingMode="contained" /></div><div className="ac-clear-both"></div></div>
  }

  onExportEmployeeToPDFButtonClick() {
    this.showLoadPanel();

    //const url =  Global.WebApiRootUrl + "/Report/ExportEmployeeToPDF";
    const url =  Global.WebApiRootUrl + "/Report/ExportEmployeesToPDF";

    var formData = new FormData();

    const payload = {
      employeeId: this.props.history.location.state.stakeholder.id,
      year: this.state.year
    };      
    formData.append("values", JSON.stringify(payload));
        
    // POST request using fetch with error handling
    const requestOptions = {
      method: 'POST',
      body: formData,
      responseType: 'blob',
      withCredentials: true,
      headers: {
        Accept: "application/pdf",
        Authorization: `Bearer ${this.state.loggedUser.token}`,
      }
    };
    fetch(url, requestOptions)
      .then(response => {        
        if(response.ok) 
          return response.blob();
        
        Global.CheckResponseAuth(response);
      })
      .then(blob => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;

          //console.log(this.props.history.location.state.stakeholder);

          var slug = this.props.history.location.state.stakeholder.employerLastName + '-' + this.props.history.location.state.stakeholder.employerFirstName + '-' + this.props.history.location.state.stakeholder.employerFiscalCode;
          slug = slug.replace(/\s/g,'').toLowerCase();

          a.download = slug + ".pdf";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();    
          a.remove();  //afterwards we remove the element again 
          
          this.hideLoadPanel();
      })
      .catch(error => {
          //this.setState({ errorMessage: error.toString() });
          console.error('There was an error!', error);
      });
  }

  onDeleteEmployeeButtonClick() {
    //var result = Confirm("Are you sure?", "Confirm changes");  
    //console.log(result);
    let result = confirm("Confermi l'eliminazione dell'anagrafica del dipendente e tutti i dati associati?", "Confirma eliminazione");
      result.then((dialogResult) => {
        //alert(dialogResult ? "Confirmed" : "Canceled");
        if (dialogResult) {
          this.showLoadPanel();

          const url =  Global.WebApiRootUrl + "/Employees/Delete_XYZ/" + this.props.history.location.state.stakeholder.id;

          const requestOptions = {
            method: 'DELETE',
            withCredentials: true,
            headers: {
              Accept: "application/pdf",
              Authorization: `Bearer ${this.state.loggedUser.token}`,
            }
          };

          fetch(url, requestOptions)
            .then(async response => {
              if(!response.ok) 
                Global.CheckResponseAuth(response);

              //elimina e torna indietro
              //alert("ok");
              //this.props.history.goBack();

              if(this.props.history.location.state.stakeholder.employer){
                //se arrivo dalla scheda del datore torno alla scheda del datore
                var slug = this.props.history.location.state.stakeholder.employer.lastName + '-' + this.props.history.location.state.stakeholder.employer.firstName + '-' + this.props.history.location.state.stakeholder.employer.fiscalCode;
                slug = slug.replace(/\s/g,'').toLowerCase();                
                this.props.history.push({pathname: 'employer', search: slug, state: { stakeholder: this.props.history.location.state.stakeholder.employer }});
              } else{                
                //altrimenti dall'elenco degli stakeholders
                this.props.history.push({pathname: 'stakeholders'});
              }
            })
            .catch(error => {              
              //this.setState({ errorMessage: error.toString() });
              console.error('There was an error!', error);
            });

          this.hideLoadPanel();
        }
    });
  }

  showLoadPanel() {
    this.setState({
      loadPanelVisible: true
    });
  }

  hideLoadPanel() {
    this.setState({
      loadPanelVisible: false
    });
  }

  render() {
    //console.log('render', this.state);
    
    if(!this.props.history.location.state) {  
      //there is no data, tipically when user refresh
      return (<React.Fragment></React.Fragment>);
    } else {
      //console.log(this.props.history.location.state.stakeholder);
      //check if user arrive from employer end build breadcrumb 
      const renderEmployerBreadcrumb  = ()=>{
        if(this.props.history.location.state.stakeholder.employer){
          var slug = this.props.history.location.state.stakeholder.employer.lastName + '-' + this.props.history.location.state.stakeholder.employer.firstName + '-' + this.props.history.location.state.stakeholder.employer.fiscalCode;
          slug = slug.replace(/\s/g,'').toLowerCase();
          return  (<span> &gt; <Link to={{ pathname: 'employer', search: slug, state: { stakeholder: this.props.history.location.state.stakeholder.employer } }} >{this.props.history.location.state.stakeholder.employer.lastName} {this.props.history.location.state.stakeholder.employer.firstName}</Link></span>);
        } else{
          return  (<span></span>);
        }
      }

      //render full employee data
      return (
        <React.Fragment>
          <div>
            <LoadPanel
              shadingColor="rgba(0,0,0,0.4)"
              visible={this.state.loadPanelVisible}
              showIndicator={true}
              shading={true}
              showPane={true}
              closeOnOutsideClick={false}
            />

            <div className={'content-block ac-page-title'}><Link to={this.state.breadcrumbRootUrl}>{this.state.breadcrumbRootLabel}</Link>{renderEmployerBreadcrumb()} &gt; {this.props.history.location.state.stakeholder.lastName} {this.props.history.location.state.stakeholder.firstName}</div>
            <div className={'content-block'}>
              <div className={'dx-card responsive-paddings'}>  
                <div class="employee-toolbar"> 
                  <Toolbar>
                    <Item location="before" locateInMenu="never" render={this.renderToolbarYear} />
                    <Item location="after" locateInMenu="auto" render={this.renderToolbarExportEmployeeToPDFButton} />                
                  </Toolbar>
                </div>              
                
                <div id="employee-header-fixed">
                  <div id="employee-header-sticky">
                    <div class="employee-details-accordion">
                      <Accordion            
                        dataSource={this.state.accordionDetailsData}
                        collapsible={true}
                        itemTitleRender={this.accordionDetailsRenderTitle}
                        itemRender={this.accordionDetailsRenderItem}            
                        onItemRendered={this.onAccordionDetailsItemRendered}
                        onItemClick={this.onAccordionDetailsItemClick}
                      />
                    </div>
                    
                    <EmployeeHours title={'Situazione originale'} loggedUser={this.state.loggedUser} extraTitleText={this.getEmployeeHoursExtraTitleData(this.props.location.state.stakeholder)} extraTitleNotes={this.getEmployeeHoursExtraTitleNotes(this.props.location.state.stakeholder)} employeeId={this.props.history.location.state.stakeholder.id} year={this.state.year} original="1" />
                    <EmployeeHours title={'Situazione aggiornata'} loggedUser={this.state.loggedUser} extraTitleText={this.getEmployeeHoursExtraTitleData(this.props.location.state.stakeholder)} extraTitleNotes={this.getEmployeeHoursExtraTitleNotes(this.props.location.state.stakeholder)} employeeId={this.props.history.location.state.stakeholder.id} year={this.state.year} original="0" />
                  </div>
                </div>

                <EmployeeVariations loggedUser={this.state.loggedUser} employeeId={this.props.history.location.state.stakeholder.id} year={this.state.year} />

                {this.renderSaveButton()}
              </div>
            </div>

          </div>
        </React.Fragment>
      );
    }
  }
}

export default Employee;