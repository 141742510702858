import React from 'react';
import { withRouter } from 'react-router-dom'
import { DataGrid, Sorting, Column, Pager, Paging, FilterRow, Editing, HeaderFilter, MasterDetail, Selection} from 'devextreme-react/data-grid';
import Form, { Item, SimpleItem, Label, GroupItem } from 'devextreme-react/form';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { Button } from 'devextreme-react/button';
import { LoadPanel } from 'devextreme-react/load-panel';
import notify from "devextreme/ui/notify";

import * as Global from '../../global'

import ArchivedExpirationsDetailTemplate from './expirations-details-grid.js';

import './expirations-grid.scss';

export class ExpirationsGrid extends React.Component {
  constructor(props) {
    super(props);

    if (!this.props.authProvider)
      Global.Logout();

    var storedGridFilter = this.initGridFilter();

    this.year = new Date().getFullYear();;
    if (this.props.year)
      this.year = this.props.year;

    this.userCityId = "00000000-0000-0000-0000-000000000000";
    if (this.props.userCityId)
      this.userCityId = this.props.userCityId;

    this.state = {
      expirationsDataSource: this.createExpirationsDataSource(),
      gridFilter: storedGridFilter,      
      authProvider: this.props.authProvider,
      toastManager: this.createToastManager(),
    };

    this.onExportExpirationsToPDFButtonClick = this.onExportExpirationsToPDFButtonClick.bind(this); 
    this.onExpirationsTableSelectionChanged = this.onExpirationsTableSelectionChanged.bind(this);
    this.onSearchButtonClick = this.onSearchButtonClick.bind(this);  
    this.onResetButtonClick = this.onResetButtonClick.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);    
    this.dataGrid = null;
  }

  componentDidMount() {
    if (!this.props.authProvider)
      Global.Logout();
  }

  refreshDataGrid() {
    this.dataGrid.instance.refresh();
  }

  initGridFilter() {    
    var filter = this.createGridFilter();
    
    if (JSON.parse(localStorage.getItem(Global.LocalStorageKey.FilterExpirations))) {
      filter = JSON.parse(localStorage.getItem(Global.LocalStorageKey.FilterExpirations));
    }    

    return filter;
  }

  createGridFilter() {    
    var filter = {
      employerLastName: '',
      employerFiscalCode: '',
      employeeLastNameFirstLetterFrom: '',
      employeeLastNameFirstLetterTo: '',
      takingChargeDateFrom: '',
      takingChargeDateTo: '',
      expirationDateFrom: '',
      expirationDateTo: '',
      employeeExcludeDateEnd: 1,
      employeeWithExpirations: 0,
      employeeWithOpenedExpiration: 0,
      inpsDelegationOn: 0,
      inpsDelegationOff: 0,
      inpsCommunicationOn: 0,
      inpsCommunicationOff: 0,
      letterSentOn: 0,
      letterSentOff: 0,
      pagopaOn: 0,
      pagopaOff: 0,
      onlineVariationOn: 0,
      onlineVariationOff: 0,
      sendingDocumentsOn: 0,
      sendingDocumentsOff: 0,
      consensualResignationOn: 0,
      consensualResignationOff: 0,
      firingOn: 0,
      firingOff: 0
    };

    return filter;
  }

  createFilterPayLoad(cityId, year, gridFilter) {
    const payload = { 
      cityId: cityId,
      year: year,
      employerLastName: gridFilter.employerLastName,
      employerFiscalCode: gridFilter.employerFiscalCode,
      employeeLastNameFirstLetterFrom: gridFilter.employeeLastNameFirstLetterFrom,
      employeeLastNameFirstLetterTo: gridFilter.employeeLastNameFirstLetterTo,
      employeeExcludeDateEnd: gridFilter.employeeExcludeDateEnd, 
      employeeWithExpirations: gridFilter.employeeWithExpirations, 
      employeeWithOpenedExpiration: gridFilter.employeeWithOpenedExpiration,
      takingChargeDateFrom: gridFilter.takingChargeDateFrom,
      takingChargeDateTo: gridFilter.takingChargeDateTo,
      expirationDateFrom: gridFilter.expirationDateFrom,
      expirationDateTo: gridFilter.expirationDateTo,
      inpsDelegationOn: gridFilter.inpsDelegationOn,
      inpsDelegationOff: gridFilter.inpsDelegationOff,
      inpsCommunicationOn: gridFilter.inpsCommunicationOn,
      inpsCommunicationOff: gridFilter.inpsCommunicationOff,
      letterSentOn: gridFilter.letterSentOn,
      letterSentOff: gridFilter.letterSentOff,
      pagopaOn: gridFilter.pagopaOn,
      pagopaOff: gridFilter.pagopaOff,
      onlineVariationOn: gridFilter.onlineVariationOn,
      onlineVariationOff: gridFilter.onlineVariationOff,
      sendingDocumentsOn: gridFilter.sendingDocumentsOn,
      sendingDocumentsOff: gridFilter.sendingDocumentsOff,
      consensualResignationOn: gridFilter.consensualResignationOn,
      consensualResignationOff: gridFilter.consensualResignationOff,
      firingOn: gridFilter.firingOn,
      firingOff: gridFilter.firingOff
    };

    return payload;
  }

  createToastManager() {    
    var t = {
      isVisible: false,
      type: 'info',
      message: ''
    };

    return t;
  }

  createExpirationsDataSource() {   
    var store = createStore({
      key: 'id',
      loadUrl: Global.WebApiRootUrl + '/expirations/Find',
      onBeforeSend: (method, ajaxOptions) => {
        if (method == "load" ) {
            //ajaxOptions.method = "POST";
                        
            var payload = ajaxOptions.data;
            payload.values = JSON.stringify(this.createFilterPayLoad(this.userCityId, this.year, this.state.gridFilter));
            ajaxOptions.data = payload;
            
            //console.log(ajaxOptions.data);
        }        
        ajaxOptions.xhrFields = { withCredentials: true };
        ajaxOptions.headers = { Authorization: `Bearer ${this.state.authProvider.user.token}`}
      },
      onAjaxError: ({ xhr, error}) => {
        this.props.authProvider.checkAuth(xhr);
      }
    });

    return store;
  }

  onSearchButtonClick(e) {    
    this.setState({
      expirationsDataSource: this.createExpirationsDataSource(),
    });

    localStorage.setItem(Global.LocalStorageKey.FilterExpirations, JSON.stringify(this.state.gridFilter));

    this.refreshDataGrid();    
  }

  onResetButtonClick(e) {    
    var filter = this.createGridFilter();

    this.filterForm.instance.resetValues();

    this.setState({
      expirationsDataSource: this.createExpirationsDataSource(),
      gridFilter: filter
    });

    localStorage.removeItem(Global.LocalStorageKey.FilterExpirations);

    this.refreshDataGrid();
  }

  onFormSubmit(e) {
    if(e && e.onSearchButtonClick)
      e.onSearchButtonClick();
    else
      alert("Si è verificato un errore, non è possibile procedere.")    
  }

  onExpirationsTableSelectionChanged(e) {
    //console.log("selectionChanged", e.currentSelectedRowKeys[0]);
    //console.log("selectionChanged", e.selectedRowsData[0]);

    var employeeId = e.currentSelectedRowKeys[0];
    var expirationVersionId = e.selectedRowsData[0].lastClosedExpirationVersionId;
    //console.log("selectionChanged", employerId, expirationVersionId);

    //>>Gianni: 01/11/2021 la relazione 1:n fra impiegato e cessazione non è vera...
    //if (e.selectedRowsData[0].state.toLowerCase() == "aperta" || e.selectedRowsData[0].state.toLowerCase() == "") 
    //se aperta indefinita o sospesa o nuova
    if (e.selectedRowsData[0].state == 9 || e.selectedRowsData[0].state == 7 || e.selectedRowsData[0].state == 0) 
      this.props.history.push({pathname: 'expirations/' + e.currentSelectedRowKeys[0]});
    else //se chiusa o a tempo indeterminato: 3 o 5
      this.props.history.push({pathname: '/expirations/' + employeeId.toLowerCase() + /archived/ + expirationVersionId.toLowerCase(), key: Math.floor(Math.random() * 100001) });
    
    //>>Gianni: 01/11/2021
    //this.props.history.push({pathname: 'expirations/' + e.currentSelectedRowKeys[0]});
  }

  onExpirationsTableRowPrepared(e) {
    //console.log("onExpirationsTableRowPrepared", e);
    
    //remove arrow if there is not expirations
    if(e && e.rowType && e.rowType == "data" && e.data && e.data.closedExpirationsCount == 0) {      
      if(e.rowElement && e.rowElement.childNodes[0] && e.rowElement.childNodes[0].childNodes[0] && e.rowElement.childNodes[0].childNodes[0].style)
        e.rowElement.childNodes[0].childNodes[0].style.display = "none";      
    }    
  }

  onExportExpirationsToPDFButtonClick() {
    this.showLoadPanel();

    const url =  Global.WebApiRootUrl + "/expirations/exportExpirationsToPDF";

      var formData = new FormData();
  
      formData.append("values", JSON.stringify(this.createFilterPayLoad(this.userCityId, this.year, this.state.gridFilter)));
          
      // POST request using fetch with error handling
      const requestOptions = {
        method: 'POST',
        body: formData,
        responseType: 'blob',
        withCredentials: true,
        headers: {
          Accept: "application/pdf",
          Authorization: `Bearer ${this.state.authProvider.user.token}`,
        }
      };
      fetch(url, requestOptions)
        .then(response => {
          if(response && response.status == 404) {
            throw Error("Non sono stati trovati risultati che soddisfano la ricerca");            
          } else {
            if(response && response.ok) 
              return response.blob();
            
            Global.CheckResponseAuth(response);
          }
        })
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;

            var today = new Date(); 
            var filename = "cessazioni_" + today.getDate() + "-" + (today.getMonth() + 1) + "-" + today.getFullYear() + "_filtri.pdf";

            a.download = filename;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();  //afterwards we remove the element again       
            
            this.hideLoadPanel();
        })
        .catch(error => { this.hideLoadPanel(); notify(error.message, 'error', 5000); });
  }

  expirationsTableStateCellRender(rowInfo) {
    //console.log("expirationsTableStateCellRender", rowInfo)

    var stateClass = "";
    var stateVal = "";

    if (rowInfo.data.state == 9) {
      //if (rowInfo.data.state.toLowerCase() == "aperta")
      stateClass = "ac-grid-cell-opened-item";
      stateVal = "Aperta";
    } else if (rowInfo.data.state == 3) {
      //else if (rowInfo.data.state.toLowerCase() == "chiusa") 
      stateClass = "ac-grid-cell-closed-item";
      stateVal = "Chiusa";
    } else if (rowInfo.data.state == 7) {      
      stateClass = "ac-grid-cell-suspended-item";
      stateVal = "Sospesa";
    } else if (rowInfo.data.state == 5) {      
      stateClass = "ac-grid-cell-closedIndefinitely-item";
      stateVal = "Chiusa";
    } 

    return (
      <span class={stateClass}>{stateVal}</span>
      );
  }

  showLoadPanel() {
    this.setState({
      isLoadPanelVisible: true
    });
  }

  hideLoadPanel() {
    this.setState({
      isLoadPanelVisible: false
    });
  }

  render() {    
    return (
      <React.Fragment>
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          visible={this.state.isLoadPanelVisible}
          showIndicator={true}
          shading={true}
          showPane={true}
          closeOnOutsideClick={false}
        />

        <div className={'content-block'}>
          
          <div className={'stakeholders-filter-container dx-card wide-card'}>
            <div className="stakeholders-filter-form-container">
              <form action="#" className={'stakeholders-filter-form'} onSubmit={ e => {e.preventDefault(); this.onFormSubmit(this);}}>
                <Form colCount={5} id="form2" formData={this.state.gridFilter} ref={(ref) => this.filterForm = ref} >                  
                  <GroupItem colSpan={5} colCount={4} caption="Ricerca anagrafica per:" >
                    <SimpleItem dataField="employerLastName" editorType="dxTextBox" editorOptions={{maxLength: 25}}  >
                      <Label text="Cognome datore" />
                    </SimpleItem>
                    <SimpleItem dataField="employeeLastNameFirstLetterFrom" editorType="dxTextBox" editorOptions={{maxLength: 10}} >
                      <Label text="Iniziale cognome datore dal" />
                    </SimpleItem>
                    <SimpleItem dataField="employeeLastNameFirstLetterTo" editorType="dxTextBox" editorOptions={{maxLength: 10}} >
                      <Label text="Iniziale cognome datore al" />
                    </SimpleItem>
                    <SimpleItem dataField="employerFiscalCode" editorType="dxTextBox" editorOptions={{maxLength: 25}}  >
                      <Label text="Codice fiscale datore" />
                    </SimpleItem>
                  </GroupItem>
                  <GroupItem colSpan={5} colCount={6} >
                    <SimpleItem dataField="takingChargeDateFrom" editorType="dxDateBox" editorOptions={{maxLength: 25}} >
                      <Label text="Data presa in carico dal" />
                    </SimpleItem>
                    <SimpleItem dataField="takingChargeDateTo" editorType="dxDateBox" editorOptions={{maxLength: 25}} >
                      <Label text="Data presa in carico al" />
                    </SimpleItem>     
                    <SimpleItem dataField="expirationDateFrom" editorType="dxDateBox" editorOptions={{maxLength: 25}} >
                      <Label text="Data cessazione dal" />
                    </SimpleItem>
                    <SimpleItem dataField="expirationDateTo" editorType="dxDateBox" editorOptions={{maxLength: 25}} >
                      <Label text="Data cessazione al" />
                    </SimpleItem>             
                    <SimpleItem dataField="employeeWithExpirations" editorType="dxCheckBox" >
                      <Label text="Solo dip. con cessazioni" />
                    </SimpleItem>
                    <SimpleItem dataField="employeeExcludeDateEnd" editorType="dxCheckBox" >
                      <Label text="Escludi dip. con data cess. precedente all'anno selezionato" />
                    </SimpleItem>
                  </GroupItem>             
                  
                  <GroupItem colSpan={5} colCount={6} caption="Campi ON:" >         
                    <SimpleItem dataField="consensualResignationOn" editorType="dxCheckBox" >
                      <Label text="Dimissioni/consensuale" />
                    </SimpleItem>
                    <SimpleItem dataField="firingOn" editorType="dxCheckBox" >
                      <Label text="Licenziamento" />
                    </SimpleItem>
                    <SimpleItem dataField="letterSentOn" editorType="dxCheckBox" >
                      <Label text="Lettera ricevuta" />
                    </SimpleItem>
                    <SimpleItem dataField="onlineVariationOn" editorType="dxCheckBox" >
                      <Label text="Variazione online" />
                    </SimpleItem>
                    <SimpleItem dataField="inpsCommunicationOn" editorType="dxCheckBox" >
                      <Label text="Comunicaz. inps" />
                    </SimpleItem>
                    <SimpleItem dataField="pagopaOn" editorType="dxCheckBox" >
                      <Label text="Pagopa" />
                    </SimpleItem>
                    
                    {/*
                    <SimpleItem dataField="inpsDelegationOn" editorType="dxCheckBox" >
                      <Label text="Delega inps" />
                    </SimpleItem>                    
                    <SimpleItem dataField="sendingDocumentsOn" editorType="dxCheckBox" >
                      <Label text="Invio documenti" />
                    </SimpleItem>
                    */}
                  </GroupItem>

                  <GroupItem colSpan={5} colCount={6} caption="Campi OFF:" >   
                    <SimpleItem dataField="consensualResignationOff" editorType="dxCheckBox" >
                      <Label text="Dimissioni/consensuale" />
                    </SimpleItem>
                    <SimpleItem dataField="firingOff" editorType="dxCheckBox" >
                      <Label text="Licenziamento" />
                    </SimpleItem>               
                    <SimpleItem dataField="letterSentOff" editorType="dxCheckBox" >
                      <Label text="Lettera ricevuta" />
                    </SimpleItem>
                    <SimpleItem dataField="onlineVariationOff" editorType="dxCheckBox" >
                      <Label text="Variazione online" />
                    </SimpleItem>
                    <SimpleItem dataField="inpsCommunicationOff" editorType="dxCheckBox" >
                      <Label text="Comunicaz. inps" />
                    </SimpleItem>
                    <SimpleItem dataField="pagopaOff" editorType="dxCheckBox" >
                      <Label text="Pagopa" />
                    </SimpleItem>

                    {/*
                    <SimpleItem dataField="inpsDelegationOff" editorType="dxCheckBox" >
                      <Label text="Delega inps" />
                    </SimpleItem>
                    <SimpleItem dataField="sendingDocumentsOff" editorType="dxCheckBox" >
                      <Label text="Invio documenti" />
                    </SimpleItem>
                    */}
                  </GroupItem>
                </Form>

                <div>
                  <div className={'ac-float-letf'}>
                    <Button className="ac-button-default" width={120} text="Cerca" type="default" stylingMode="contained" useSubmitBehavior={true} />
                    <span> </span>
                    <Button width={155} text="Cancella filtri" type="default" stylingMode="outlined" onClick={this.onResetButtonClick} />
                  </div>     
                  <div className={'ac-float-right'}>
                    <Button className="ac-button-default" width={125} text="Genera PDF" type="default" stylingMode="contained" onClick={this.onExportExpirationsToPDFButtonClick} />
                  </div>           
                </div>
              </form>
            </div>
          </div>

          <div id="expirations-grid">
            <DataGrid 
              id="gridContainer"
              keyExpr="Id"
              ref={(ref) => this.dataGrid = ref}
              className={'dx-card wide-card'} 
              dataSource={this.state.expirationsDataSource} 
              remoteOperations={true}
              showBorders={true} 
              focusedRowEnabled={true} 
              defaultFocusedRowIndex={0} 
              columnAutoWidth={true}             
              allowColumnResizing={true} 
              columnHidingEnabled={false}
              onSelectionChanged={this.onExpirationsTableSelectionChanged}
              onRowPrepared={this.onExpirationsTableRowPrepared} 
            >
              <Selection mode="single" />
              <Paging defaultPageSize={15} />
              <Pager showPageSizeSelector={true} showInfo={true} />            
              <FilterRow visible={false} />
              <HeaderFilter visible={false} />
              <Editing mode="popup" allowUpdating={false} allowDeleting={false} allowAdding={false} />
              <Sorting mode="multiple" />
                        
              <Column dataField={'id'}  visible={false} />
              <Column dataField={'expirationId'}  visible={false} />
              <Column dataField={'employerId'}  visible={false} />
              <Column dataField={'employeeId'}  visible={false} />

              <Column dataField={'employerLastName'} caption={'Cognome'}  defaultSortIndex={2} defaultSortOrder="asc" />
              <Column dataField={'employerFirstName'} caption={'Nome'}  defaultSortIndex={3} defaultSortOrder="asc" />

              <Column dataField={'employeeRegistryNotes'} caption={'Codice socio'} width={70} alignment='left' />
              <Column dataField={'employerFiscalCode'} caption={'Codice fiscale'} />
              <Column dataField={'employeeFullName'} caption={'Dipendente'} />
              <Column dataField={'employeeInpsCode'} caption={'Codice inps'} />

              <Column dataField={'state'} caption={'Stato'} cellRender={this.expirationsTableStateCellRender} dataType={'string'} defaultSortIndex={0} defaultSortOrder="desc" width={80} />

              <Column dataField={'takingChargeDate'} caption={'Data presa in carico'} dataType={'date'} format={'dd/MM/yyyy'}  width={125} visible={false} />
              <Column dataField={'expirationDate'} caption={'Data cessazione'} dataType={'date'} format={'dd/MM/yyyy'} defaultSortIndex={1} defaultSortOrder="asc" width={95} />

              <Column dataField={'consensualResignation'} caption={'Dim/con'} width={63} />
              <Column dataField={'firing'} caption={'Licenziamento'} width={63} />
              <Column dataField={'letterSent'} caption={'Lettera ricevuta'} width={63} />
              <Column dataField={'onlineVariation'} caption={'Variazione online'} width={63} />
              <Column dataField={'inpsCommunication'} caption={'Comunicaz. inps'} width={63} />              
              <Column dataField={'pagopa'} caption={'Pagopa'} width={63} />

              {/*
              <Column dataField={'inpsDelegation'} caption={'Delega inps'} width={63} />
              <Column dataField={'sendingDocuments'} caption={'Invio documenti'} width={63} />
              */}

              <MasterDetail
                enabled={false}
                component={ArchivedExpirationsDetailTemplate}
              />
            </DataGrid>
          </div> 
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(ExpirationsGrid);